window.App ||= {}
class App.Tires extends App.Base

  index: =>
    window.scanContext = 'Tire'
    Utility.Datatables.tires()
    Utility.FormActions.prepareIndexTable()
    Utility.Sidebar.hideTree()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'Tire'
    Utility.Datatables.tireHistories()
    Utility.FormActions.prepareIndexTable()
    Utility.AccordionTab.prepare()
    $("html, body").scrollTop(0)
