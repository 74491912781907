window.App ||= {}
class App.Attachments extends App.Base

  pendingFileCount = 0

  index: =>
    Utility.Datatables.attachments()
    Utility.FormActions.prepareIndexTable()
    App.Attachments.fileUpload()

  new: =>
    Utility.Datatables.attachments()
    Utility.FormActions.prepareIndexTable()
    App.Attachments.fileUpload()
    Utility.SelectizeFields.prepare()

  edit: =>
    Utility.SelectizeFields.prepare()
    Utility.FlyInPanel.prepare()

  @updateFileUploadControls = ->
    FOOTER_BUTTON_THRESHOLD = 5

    # Update the label of the header buttons based on pending file count
    if pendingFileCount == 1
      $('span.save-all').text('Upload')
      $('span.cancel-all').text('Cancel')
    else
      $('span.save-all').text('Upload All')
      $('span.cancel-all').text('Cancel All')

    # Show/hide controls and adjust classes based on pending file count
    if pendingFileCount > 0
      $('button#start-all').show()
      $('button#cancel-all').show()
      $('div#new_attachments.full-width').addClass('tw-pb-2')
    else
      $('button#start-all').hide()
      $('button#cancel-all').hide()
      $('div.fileupload-buttons.footer').addClass('tw-hidden')
      $('div#new_attachments.full-width').removeClass('tw-pb-2')

    # Show/hide footer buttons based on pending file count & threshold
    if pendingFileCount >= FOOTER_BUTTON_THRESHOLD
      $('div.fileupload-buttons.footer').removeClass('tw-hidden')
    else
      $('div.fileupload-buttons.footer').addClass('tw-hidden')

  @incrementPendingFiles = ->
    pendingFileCount += 1
    App.Attachments.updateFileUploadControls()

  @decrementPendingFiles = ->
    pendingFileCount = Math.max(0, pendingFileCount - 1)
    App.Attachments.updateFileUploadControls()

  @resetPendingFiles = ->
    pendingFileCount = 0
    App.Attachments.updateFileUploadControls()

  @attachmentUpload = ->
    tmpl.cache["attachment-template-upload"] = null
    $('#file_upload').fileupload
      uploadTemplateId: 'attachment-template-upload',
      maxFileSize: $('#file_upload').data('mfsb')
      autoUpload: true
      minFileSize: 1
      acceptFileTypes:  /(\.|\/)(jpe?g|gif|jfif|png|pdf|stl|sldprt|slddrw|sldsam|step|dwg|tfb|dxf|xltx|xlsx?|dotx?|docx?|pptx?|csv|txt)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      add: (e, data) ->
        if e.isDefaultPrevented()
          return false
        $this = $(this)
        that = $this.data('blueimp-fileupload') or $this.data('fileupload')
        options = that.options
        data.context = that._renderUpload(data.files).data('data', data).addClass('processing')
        options.filesContainer[if options.prependFiles then 'prepend' else 'append'] data.context
        that._forceReflow data.context
        that._transition data.context
        data.process(->
          $this.fileupload 'process', data
        ).always(->
          data.context.each((index) ->
            $(this).find('.size').text that._formatFileSize(data.files[index].size)
            return
          ).removeClass 'processing'
          that._renderPreviews data
          return
        ).done(->
          data.context.find('.start').prop 'disabled', false
          if that._trigger('added', e, data) != false and (options.autoUpload or data.autoUpload) and data.autoUpload != false
            $.jGrowl('Uploading...', {position: 'center', theme: 'success'})
            data.submit()
          return
        ).fail ->
          if data.files.error
            data.context.each (index) ->
              error = data.files[index].error
              if error
                process = $('tr.template-upload:last')
                if process
                  process.remove();
                $.jGrowl(error + '. Please select a picture file and try again.', {position: 'center', theme: 'error'})
              return
          return
        return
      submit: (e, data) ->
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        Utility.Upload.sanitize_file_names(data.files)

        $.ajax
          dataType: 'json'
          url: "/documents/new?attachmentable_type=" + attachmentable_type
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $('#file_upload').fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        tags = data.context.find("input.tags").val()
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        attachmentable_id = data.context.find("input.attachmentable_id").val()
        attachmentable_key = data.context.find("input.attachmentable_key").val()
        data.context.attr('data-key', key)

        $.ajax
          url: "/attachments"
          type: 'POST'
          data: {
            attachment: {
              document_key: key,
              name: name,
              tags: tags,
              attachmentable_type: attachmentable_type,
              attachmentable_id: attachmentable_id,
              attachmentable_key: attachmentable_key
            }
          }
          cache: false
          dataType: 'script'

  @fileUpload = ->
    tmpl.cache["attachment-template-upload"] = null
    $('#file_upload').fileupload
      uploadTemplateId: 'attachment-template-upload',
      maxFileSize: $('#file_upload').data('mfsb'),
      minFileSize: 1,
      acceptFileTypes:  /(\.|\/)(jpe?g|gif|jfif|png|pdf|stl|sldprt|slddrw|sldsam|step|dwg|tfb|dxf|xltx|xlsx?|dotx?|docx?|pptx?|csv|txt)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      submit: (e, data) ->
        $('div.progress.progress-striped.active').removeClass('tw-hidden')
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        Utility.Upload.sanitize_file_names(data.files)
        $.ajax
          dataType: 'json'
          url: "/documents/new?attachmentable_type=" + attachmentable_type
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $('#file_upload').fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        tags = data.context.find("input.tags").val()
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        attachmentable_id = data.context.find("input.attachmentable_id").val()
        attachmentable_key = data.context.find("input.attachmentable_key").val()
        data.context.attr('data-key', key)

        params = {
          attachment: {
              document_key: key,
              name: name,
              tags: tags,
              attachmentable_type: attachmentable_type,
              attachmentable_id: attachmentable_id
            }
        }

        if attachmentable_key != undefined
          params.attachment.attachmentable_key = attachmentable_key

        $.ajax
          url: "/attachments"
          type: 'POST'
          data: params
          cache: false
          dataType: 'script'

    App.Attachments.resetPendingFiles()

    $("#file_upload").bind 'fileuploadadd', ->
      App.Attachments.incrementPendingFiles()
      setTimeout (->
        Utility.SelectizeFields.prepare()
        return
      ), 500

    $("#file_upload").bind 'fileuploaddone', ->
      App.Attachments.decrementPendingFiles()

    $("#file_upload").bind "keypress", (e) ->
      if e.keyCode == 13
        return false

    $("button[type='reset']").off('click.reset-pending-files').on('click.reset-pending-files', (e) ->
      App.Attachments.resetPendingFiles()
    )

    $(document).off('click', "button.cancel:not([type='reset'])").on 'click', "button.cancel:not([type='reset'])", (e) ->
      App.Attachments.decrementPendingFiles()

    $(document).off('ajax:beforeSend', '.delete-attachment').on 'ajax:beforeSend', '.delete-attachment', ->
      $(this).html('<i class="fa fa-spinner fa-spin danger"></i>')
      $(this).removeAttr('href') # Prevent additional clicks
