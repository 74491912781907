window.App ||= {}
class App.ScheduledReports extends App.Base

  index: =>
    Utility.Datatables.scheduledReports()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    this.toggleControl()
    this.prepareReportSelect()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    this.toggleControl()
    this.prepareReportSelect()
    Utility.FlyInPanel.prepare()

  toggleControl: =>
    $('.toggle-report-format').on 'toggle', (e, active) ->
      CSV_FORMAT_FIELD = '#scheduled_report_csv_file'

      if (active)
        $(CSV_FORMAT_FIELD).val('t');
      else
        $(CSV_FORMAT_FIELD).val('f');

  prepareReportSelect: =>
    $("#scheduled_report_name").change ->
      if $(@).val() == 'Equipment Need Daily Inspection'
        $(".input.scheduled_report_scheduled_hour").removeClass('hidden')
      else
        $(".input.scheduled_report_scheduled_hour").addClass('hidden')

      if $(@).val().length > 0
        $.ajax
          url: "/scheduled_reports/get_report_options?name="+ $(@).val()
          data: null
          dataType: 'script'

    $('#scheduled_report_schedule_interval').change ->
      if $(@).val() == 'Weekly'
        $(".input.scheduled_report_day_of_week").removeClass('hidden')
      else
        $(".input.scheduled_report_day_of_week").addClass('hidden')
