window.Utility ||= {}
class Utility.LogoutTimer
  idleTime = 0
  threshold = 0

  @start = ->
    return if $('body.signed-in').length == 0

    if parseInt(localStorage['fmp-timeout-threshold']) > 0
      console.log('Logout Timer minutes: ' + localStorage['fmp-timeout-threshold'])

      clearInterval($(document).idleInterval)
      $(document).idleInterval = setInterval (->
        Utility.LogoutTimer.timerIncrement()
      ), 60000
      $(document).mousemove ->
        idleTime = 0
      $(document).keypress ->
        idleTime = 0
    else
      console.log('Logout Timer inactive')

  @timerIncrement = ->
    idleTime = idleTime + 1
    console.log(idleTime)
    if idleTime > localStorage['fmp-timeout-threshold']
      Utility.Messaging.signOut()
