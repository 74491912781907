window.App ||= {}
class App.CustomFilters extends App.Base

  index: =>
    Utility.Datatables.customFilters()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    App.CustomFilters.prepareAttributeSelect()
    App.CustomFilters.prepareSaveToggle()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    App.CustomFilters.prepareAttributeSelect()
    App.CustomFilters.prepareSaveToggle()

  @prepareAttributeSelect = ->
    $(document).off('change', '[data-attribute-select]').on 'change', '[data-attribute-select]', ->
      if $(@).val().length > 0
        $.get '/custom_filters/select_attribute?custom_filter_type_name=' + $(@).data('custom-filter-type-name') + '&attribute=' + encodeURIComponent($(@).val()) + '&and_index=' + $(@).data('and-index') + '&or_index=' + $(@).data('or-index'), null, null, 'script'
    $(document).off('change', '[data-action-select]').on 'change', '[data-action-select]', ->
      if $(@).val().length > 0
        $.get '/custom_filters/select_action?custom_filter_type_name=' + $(@).data('custom-filter-type-name') + '&action_name=' + $(@).val() + '&action_index=' + $(@).data('action-index'), null, null, 'script'
    $(document).off('click', 'a.delete-condition').on 'click', 'a.delete-condition', ->
      if $(@).closest('tbody').find('tr.condition').length > 1
        $(@).closest('tr').remove()
      else
        $(@).closest('tbody').remove()
    $(document).off('click', 'a.delete-action').on 'click', 'a.delete-action', ->
      $(@).closest('tr').remove()
    $(document).off('click', 'a.add-condition').on 'click', 'a.add-condition', ->
      $.get '/custom_filters/add_condition?custom_filter_type_name=' + $(@).data('custom-filter-type-name') + '&and_index=' + $(@).data('and-index') + '&or_index=' + $(@).data('or-index'), null, null, 'script'
    $(document).off('click', 'a.add-condition-group').on 'click', 'a.add-condition-group', ->
      $.get '/custom_filters/add_condition_group?custom_filter_type_name=' + $(@).data('custom-filter-type-name') + '&and_index=' + Date.now(), null, null, 'script'

  @prepareSaveToggle = ->
    if $('.toggle-save-filter').data('toggles') && $('.toggle-save-filter').data('toggles').active
      $('#custom_filters_' + $('.toggle-save-filter').data('custom-filter-type-name') + '_name').show();
    $('.toggle-save-filter').on 'toggle', (e, active) ->
      if (active)
        $('#custom_filters_' + $(@).data('custom-filter-type-name') + '_save_filter').val('t');
        $('#custom_filters_' + $(@).data('custom-filter-type-name') + '_name').fadeIn();
      else
        $('#custom_filters_' + $(@).data('custom-filter-type-name') + '_save_filter').val('f');
        $('#custom_filters_' + $(@).data('custom-filter-type-name') + '_name').fadeOut();
