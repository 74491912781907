window.App ||= {}
class App.PurchaseOrders extends App.Base

  index: =>
    window.scanContext = 'PurchaseOrder'
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.purchaseOrders()
    Utility.FormActions.prepareIndexTable()
    Utility.HelpPanel.prepare()
    App.PurchaseOrders.prepareBulkOperations()
    $('.scan-lookup').click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanInternalBarcode()
      else
        ScanInterface.scanInternalBarcode()

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareVendorIdSelect()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    this.prepareVendorIdSelect()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'PurchaseOrder'
    Utility.Datepicker.prepare()
    Utility.NextPrevious.prepare('purchase_orders')
    this.preparePurchaseOrderForm()
    App.Attachments.fileUpload()
    Utility.Datatables.workOrderAttachments()
    Utility.FormActions.prepareIndexTable()
    Utility.FormActions.prepareCreditQuantityEditor();
    $("html, body").scrollTop(0)

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-print-purchase-orders").click ->
      purchase_order_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      window.open("/purchase_orders/bulk_print?purchase_order_ids=" + purchase_order_ids, '_blank')

  @scan = (scanKey) ->
    $.ajax
      url: "/purchase_orders/scan"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @scanPurchaseOrderInventory = (purchaseOrderId, scanKey) ->
    $.ajax
      url: "/purchase_orders/" + purchaseOrderId + "/purchase_orders_inventories/new"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @calculateTotalCost = ->
    unitCost = parseFloat($("input.unit-cost").val().replace(/[^0-9.]/g, ''))
    quantity = parseFloat($("input.quantity").val().replace(/[^0-9.]/g, ''))
    unitCost = 0 unless unitCost > 0
    quanity = 0 unless quantity > 0
    calculatedTotalCost = unitCost * quantity
    if calculatedTotalCost > 0
      $("#calculated-total-cost").html(calculatedTotalCost.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}))
    else
      $("#calculated-total-cost").html("")

  preparePurchaseOrderForm: =>
    $("#show-purchase-order-table").on 'click', "#cancel-add-inventory", ->
      $("#form-add-inventory").hide()
      $("#po-add-inventory").show()

    $(document).off('change', '#purchase_orders_inventory_inventory_id').on 'change', "#purchase_orders_inventory_inventory_id", ->
      id = $(@).val()
      purchase_order_id = $(@).attr('data-purchase-order-id')

      $.ajax
        url: "/purchase_orders/"+purchase_order_id+"/purchase_orders_inventories/get_inventory_unit_cost?inventory_id="+id
        data: null
        dataType: 'script'

    $(document).on 'click', '.purchase-order-item-tooltip', (event) ->
      event.stopPropagation()
      event.preventDefault()
      po_id = $(@).attr('data-po-id')
      poi_id = $(@).children('.tooltip').attr('data-poi-id')
      $.ajax
        url: "/purchase_orders/" + po_id + "/purchase_orders_inventories/get_inventory_hint?id=" + poi_id
        data: null
        dataType: 'script'

    $(document).off('click', '.scan-po-inventory-link').on 'click', '.scan-po-inventory-link', ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanPurchaseOrderInventory($(@).data('purchase-order-id'))

    $(document).off('keyup', ".quantity, .unit-cost").on 'keyup', ".quantity, .unit-cost", ->
      App.PurchaseOrders.calculateTotalCost()

  prepareVendorIdSelect: =>
    $("#purchase_order_vendor_id").change ->
      if parseInt($(@).val()) > 0
        $.ajax
          url: '/vendors/' + $(@).val() + '/get_payment_terms'
          data: null
          dataType: 'script'
