window.App ||= {}
class App.Reports extends App.Base

  show: =>
    window.scanContext = 'Equipment'
    if $("#report-table").length
      $("#sidebar").attr("data-filter-status", "0")
    else
      $("#sidebar").attr("data-filter-status", "1")
    $( window ).resize ->
      $()
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.reports()
    Utility.Drawer.toggleDrawers()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.FormActions.prepareIndexTable()
    this.prepareCharts()

  refresh_chart: =>
    this.prepareCharts()

  prepareCharts: =>
    $(".chart").each (index, element) ->
      defaults =
        credits: false,
        plotOptions:
          series:
            point:
              events:
                click: ->
                  if @.options.url
                    location.href = @.options.url
        title:
          style:
            fontSize: '16px'
      $(@).highcharts($.extend(true, {}, defaults, $(@).data('chart-data')))
