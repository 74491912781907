window.Utility ||= {}

class Utility.NextPrevious
  @prepare: (object) =>
    itemId = $('a#previous-item').data('itemId')
    ids = localStorage[object + '-index-table-ids']
    if ids == undefined
      $('a#previous-item').hide()
      $('a#next-item').hide()
    else
      idArray = JSON.parse(ids)
      index = idArray.indexOf(itemId)

      if idArray[index - 1] != undefined
        $('a#previous-item').show()
        $('a#previous-item').off('click').click ->
          hash = window.location.hash.substr(1)
          url = '/' + object + '/'
          url += idArray[index - 1]
          url += '?tab=' + hash if hash.length > 1
          $.getScript url
      else
        $('a#previous-item').hide()

      if idArray[index + 1] != undefined
        $('a#next-item').show()
        $('a#next-item').off('click').click ->
          hash = window.location.hash.substr(1)
          url = '/' + object + '/'
          url += idArray[index + 1]
          url += '?tab=' + hash if hash.length > 1
          $.getScript url
      else
        $('a#next-item').hide()
