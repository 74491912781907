window.App ||= {}
class App.Locations extends App.Base

  index: =>
    Utility.Datatables.locations()
    Utility.FormActions.prepareIndexTable()

  new: =>
    App.Locations.prepareRemoveCategory()
    App.Locations.toggleRemoveCategory()
    Utility.FlyInPanel.prepare()

  edit: =>
    App.Locations.prepareRemoveCategory()
    App.Locations.toggleRemoveCategory()
    Utility.FlyInPanel.prepare()

  @prepareRemoveCategory: =>
    $('#categories').on('cocoon:after-insert', (e, added_task) ->
      App.Locations.toggleRemoveCategory()
    ).on('cocoon:after-remove', ->
      App.Locations.toggleRemoveCategory()
    )

  @toggleRemoveCategory: =>
    if $('.nested-fields:visible').length > 1
      $('a.remove_fields').show()
    else
      $('a.remove_fields').hide()
