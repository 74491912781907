$ ->
  $("[data-offcanvas='trigger']").on 'click touchstart', (e) ->
    $("[data-offcanvas='content']").toggleClass 'is-visible'
    $("[data-offcanvas='screen']").toggleClass 'is-visible'
    e.preventDefault()

  $("[data-offcanvas='screen']").on 'click touchstart', (e) ->
    $("[data-offcanvas='content']").toggleClass 'is-visible'
    $("[data-offcanvas='screen']").toggleClass 'is-visible'
    e.preventDefault()
