window.Utility ||= {}

class Utility.HelpscoutBeacon
  @prepare = ->
    $('a[data-click="open-helpscout-beacon"]').on 'click', (event) ->
      event.preventDefault()
      if $(this).data('mode') == 'sales'
        Utility.HelpscoutBeacon.open_sales($(this).data('name'), $(this).data('email'))
      else
        Utility.HelpscoutBeacon.open_support($(this).data('name'), $(this).data('email'))

  @open_sales = (name, email) ->
    Utility.HelpscoutBeacon.open('90eabc90-fbdc-457c-9105-4345e6d9be22', name, email)

  @open_support = (name, email) ->
    Utility.HelpscoutBeacon.open('401ec919-53ec-41d4-9b9e-0db9b981f403', name, email)

  @open = (beacon_key, name, email) ->
    beacon = window.Beacon
    beacon('init', beacon_key)
    beacon('navigate', '/ask/message/')
    beacon('prefill', {
      name: name,
      email: email
    })
    beacon('open')
