window.App ||= {}
class App.Dashboard extends App.Base
  woStatusCountLabel = null
  poStatusCountLabel = null

  @prepareEmployeeRenewalOperations = ->
    $(".update-employee-renewal").off('click').click ->
      employee_renewal_ids = [$(@).data('user-renewal-id')]
      $.get "/employee_renewal_bulk_updaters/new?employee_renewal_ids=" + employee_renewal_ids, null, null, 'script'

  @prepareInventoryBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-issue-purchase-orders").off('click').click ->
      inventory_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get "/bulk_issue_purchase_orders/new?inventory_ids=" + inventory_ids, null, null, 'script'

  @prepareEmployeeRenewalBulkOperations = ->
    Utility.BulkOperations.prepare()

    $("#bulk-update-employee-renewals").off('click').click ->
      employee_renewal_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get "/employee_renewal_bulk_updaters/new?employee_renewal_ids=" + employee_renewal_ids, null, null, 'script'

  index: =>
    window.scanContext = 'Equipment'
    Utility.Datatables.equipmentDashboard()
    Utility.Datatables.workOrdersDashboard()
    Utility.Datatables.inventoriesDashboard()
    Utility.Datatables.purchaseOrdersDashboard()
    Utility.Datatables.usersDashboard()
    Utility.FormActions.prepareIndexTable()
    this.prepareCharts()
    $(window).off('resized').on 'resized', ->
      App.Dashboard.annotateCharts()

  filter: =>
    window.scanContext = 'Equipment'
    Utility.Datatables.equipmentDashboard()
    Utility.Datatables.workOrdersDashboard()
    Utility.Datatables.inventoriesDashboard()
    Utility.Datatables.purchaseOrdersDashboard()
    Utility.Datatables.usersDashboard()
    Utility.FormActions.prepareIndexTable()
    this.prepareCharts()

  prepareCharts: =>
    $(".chart").each (index, element) ->
      defaults =
        chart:
          backgroundColor: '#ffffff'
        credits: false,
        plotOptions:
          series:
            point:
              events:
                legendItemClick: (e) ->
                  e.preventDefault()
                  if e.target.url
                    $.get e.target.url, null, null, 'script'
                click: ->
                  if @.options.url
                    location.href = @.options.url
        title:
          style:
            fontSize: '16px'
      $(@).highcharts($.extend(true, {}, defaults, $(@).data('chart-data')))
    woStatusCountLabel = null
    poStatusCountLabel = null
    App.Dashboard.annotateCharts()
    $('#charts-work_order_status .highcharts-title, #charts-work_order_status rect.highcharts-background').off('click').on 'click', ->
      $.get '/work_orders', null, null, 'script'
    $('#charts-purchase_order_status .highcharts-title, #charts-purchase_order_status rect.highcharts-background').off('click').on 'click', ->
      $.get '/purchase_orders', null, null, 'script'

  @annotateCharts: =>
    return unless $("#charts-work_order_status").length == 1

    if woStatusCountLabel != null
      woStatusCountLabel.destroy()

    if poStatusCountLabel != null
      poStatusCountLabel.destroy()

    chart = $("#charts-work_order_status").highcharts()
    if chart
      pie = chart.series[0]
      left = chart.plotLeft + pie.center[0]
      top = chart.plotTop + pie.center[1] + 5
      sum = 0
      $.each chart.series[0].data, ->
        sum += parseFloat(this.y)
      if sum > 0
        woStatusCountLabel = chart.renderer.text(sum, left, top).attr({ 'text-anchor': 'middle', 'font-size': '20px'}).add()

    chart = $("#charts-purchase_order_status").highcharts()

    if chart
      pie = chart.series[0]
      left = chart.plotLeft + pie.center[0]
      top = chart.plotTop + pie.center[1] + 5
      sum = 0
      $.each chart.series[0].data, ->
        sum += parseFloat(this.y)
      if sum > 0
        poStatusCountLabel = chart.renderer.text(sum, left, top).attr({ 'text-anchor': 'middle', 'font-size': '20px'}).add()
