window.App ||= {}
class App.Vendors extends App.Base

  index: =>
    window.scanContext = 'Equipment'
    Utility.Datatables.vendors()
    Utility.HelpPanel.prepare()
    Utility.FormActions.prepareIndexTable(Utility.BetaFeatures.enabled())

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    this.toggleVendorActivationControls()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    this.toggleVendorActivationControls()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'Equipment'
    App.Attachments.fileUpload()
    Utility.Datatables.workOrderAttachments()
    Utility.FormActions.prepareIndexTable()
    $("html, body").scrollTop(0)

  toggleVendorActivationControls: =>
    TECH_FIELD = '#vendor_tech'

    $('.toggle-tech').on 'toggle', (e, active) ->
      if (active)
        $(TECH_FIELD).val('t');
      else
        $(TECH_FIELD).val('f');
