window.Utility ||= {}

import { productFruits } from 'product-fruits'

class Utility.ProductFruits
  messaging = null

  @prepare = ->
    config = $('#pf').data('config')
    if !window.productFruitsIsReady && config && config.pf_workspace_code && config.user
      console.log('Initializing productFruits')
      productFruits.init(config.pf_workspace_code, 'en', config.user)
