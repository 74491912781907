window.App ||= {}
class App.RepairTypes extends App.Base

  index: =>
    Utility.Datatables.repairTypes()
    Utility.FormActions.prepareIndexTable(Utility.BetaFeatures.enabled())
    App.RepairTypes.prepareBulkOperations()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-delete-repair-types").click ->
      repair_type_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.ajax
        url: "/repair_types/bulk_delete"
        type: 'POST'
        data: {
          _method: "DELETE"
          repair_type_ids: repair_type_ids
        }
        cache: false
        dataType: 'script'
