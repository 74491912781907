window.App ||= {}
class App.MeterUpdates extends App.Base

  index: =>
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.meterUpdates()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.FormActions.prepareIndexTable()
    App.Equipments.prepareUploads()

  new: =>
    Utility.Sidebar.refreshSideBar()
    Utility.Drawer.toggleDrawers()
    Utility.Datatables.newMeterUpdates()
    Utility.FormActions.prepareIndexTable()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    App.Equipments.prepareUploads()
    Utility.FormActions.newEditForm()
    $(window).off('resized').on 'resized', ->
      App.MeterUpdates.disableInputs()

  edit: =>
    App.Equipments.prepareUploads()
    Utility.FormActions.newEditForm()

  @disableInputs: =>
    $('td.hidden-lg').each () ->
      if $(window).width() >= 860
        $(@).find('input.meter-track').each () ->
          $(@).prop('disabled', true)
      else
        $(@).find('input.meter-track').each () ->
          $(@).prop('disabled', false)

    $('td.visible-lg-table').each () ->
      if $(window).width() < 860
        $(@).find('input.meter-track').each () ->
          $(@).prop('disabled', true)
      else
        $(@).find('input.meter-track').each () ->
          $(@).prop('disabled', false)
