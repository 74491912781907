window.App ||= {}
class App.Receipts extends App.Base

  show: =>
    Utility.FormActions.newEditForm()

  new: =>
    Utility.FlyInPanel.prepare()
    Utility.FormActions.newEditForm()

  edit: =>
    Utility.FlyInPanel.prepare()
    Utility.FormActions.newEditForm()
