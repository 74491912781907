window.App ||= {}
class App.RepairNames extends App.Base

  index: =>
    Utility.Datatables.repairNames()
    Utility.FormActions.prepareIndexTable()
    App.RepairNames.prepareBulkOperations()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-delete-repair-names").click ->
      repair_name_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.ajax
        url: "/repair_names/bulk_delete"
        type: 'POST'
        data: {
          _method: "DELETE"
          repair_name_ids: repair_name_ids
        }
        cache: false
        dataType: 'script'
    $("#bulk-edit-repair-names").off('click').click ->
      repair_name_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/bulk_edit_repair_names/new', { bulk_edit_repair_name: { repair_name_ids: repair_name_ids }}, null, 'script'
