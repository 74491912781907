/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
function requireAll(r) { r.keys().forEach(r); }

const images = require.context('../images', true)
requireAll(images)
const imagePath = (name) => images(name, true)

// CSS RESET
import 'normalize.css/normalize.css'

// NPM Modules
import 'expose-loader?exposes[]=$&exposes[]=jQuery!jquery'
import 'expose-loader?exposes=tmpl!blueimp-tmpl'
import 'expose-loader?exposes=Sortable!sortablejs'
import 'webpack-jquery-ui'
import 'webpack-jquery-ui/css'
import 'blueimp-canvas-to-blob'
import 'blueimp-load-image'
import 'blueimp-file-upload/js/jquery.fileupload'
import 'blueimp-file-upload/js/jquery.fileupload-audio'
import 'blueimp-file-upload/js/jquery.fileupload-image'
import 'blueimp-file-upload/js/jquery.fileupload-process'
import 'blueimp-file-upload/js/jquery.fileupload-validate'
import 'blueimp-file-upload/js/jquery.fileupload-video'
import 'blueimp-file-upload/js/jquery.iframe-transport'
import 'blueimp-file-upload/js/jquery.fileupload-ui'
import 'blueimp-file-upload/css/jquery.fileupload'
import 'blueimp-file-upload/css/jquery.fileupload-ui'
import 'expose-loader?exposes=moment!moment'
import 'expose-loader?exposes=FullCalendar!fullcalendar'
import 'fullcalendar/dist/fullcalendar.css'
import 'jgrowl/jquery.jgrowl.js'
import 'jgrowl/jquery.jgrowl.css'
import 'jquery-ujs'
import 'jquery.scrollto'
import 'parsleyjs'
import 'parsleyjs/src/parsley.css'
import 'spectrum-colorpicker'
import 'spectrum-colorpicker/spectrum.css'
import 'expose-loader?exposes=toastr!toastr'
import 'toastr/build/toastr.css'
import 'expose-loader?exposes=trix!trix'
import 'trix/dist/trix.css'
import 'timepicker'
import 'timepicker/jquery.timepicker.css'
import 'jquery.maskedinput/src/jquery.maskedinput.js'
import 'expose-loader?exposes=SignaturePad!signature_pad/dist/signature_pad.min.js'
import 'expose-loader?exposes=Highcharts!highcharts'
import 'highcharts/highcharts-more'
import 'highcharts/modules/no-data-to-display'
import 'highcharts/modules/exporting'
import '@nathanvda/cocoon'
import 'selectize'
import 'selectize/dist/css/selectize.default.css'
import 'jquery-toggles'
import 'jquery-toggles/css/toggles-full.css'
import 'featherlight'
import 'featherlight/src/featherlight.css'
import 'datatables.net'
import 'datatables.net-jqui'
import 'datatables.net-jqui/css/dataTables.jqueryui.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/light.css'

// Vendor Modules
import 'javascripts/jquery.dropdown'
import 'javascripts/jquery.curvedarrow'


// App CoffeeScript
import 'javascripts/base.js.coffee'

requireAll(require.context('javascripts/utilities', true, /\.coffee$/))
requireAll(require.context('javascripts/app', true, /\.coffee$/))
requireAll(require.context('javascripts/elements', true, /\.coffee$/))

import 'javascripts/data-tables-helper.js.coffee'
import 'javascripts/date_picker_input.js.coffee'
import 'javascripts/meter-select.js.coffee'
import 'javascripts/offcanvas.js.coffee'
import 'javascripts/fmp-trix.js.coffee'

// App Stylesheets
import 'stylesheets/bootstrap-progressbar.css'
import 'stylesheets/search.css'
import 'stylesheets/fontawesome'
import 'stylesheets/application.css.scss'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { LicenseManager } from 'ag-grid-charts-enterprise';

try {
  if (process.env.AG_GRID_LICENSE_KEY) {
    LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY);
  }
} catch {
  console.warn('AG Grid license key not found');
}

// Support component names relative to this directory:
var componentRequireContext = require.context("javascripts/react_components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
