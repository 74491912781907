window.Utility ||= {}

class Utility.Sidebar
  @prepare = ->
    set_tree_status = (value) ->
      $.ajax
          type: "POST"
          url: "/set_tree_status"
          data:
            tree_status: value
          dataType: "script"

    toggleSideBar = () ->
      if $('#sidebar').hasClass('filter-hide')
        $('#sidebar').removeClass('filter-hide')

        if $(window).width() < 1024
          $('.with-sidebar').animate(paddingLeft: '0em', 500)
        else
          $('.with-sidebar').animate(paddingLeft: '215px', 500)

        set_tree_status(1)

      else
        $('.with-sidebar').animate(paddingLeft: '0em', 500)
        $('#sidebar').addClass('filter-hide')

        set_tree_status(0)

    $(document).on 'click', '.link-location', ->
      $.get $("#location-" + $(this).attr('data-id')).attr('href'), null, null, 'script'

    $(document).on 'click', '#hide-side, #hide-open', ->
      toggleSideBar()

  @refreshSideBar = ->
    showSideBar = () ->
      if $('#sidebar').hasClass('filter-hide')
        $('#sidebar').removeClass('filter-hide')
        if $(window).width() < 1024
          $('.with-sidebar').attr("style", "padding-left:0em")
        else
          $('.with-sidebar').attr("style", "padding-left:215px")


    if $("#sidebar").attr("data-filter-status") == "1"
      showSideBar()

    if $('#sidebar').hasClass('filter-hide')
      $('.with-sidebar').animate(paddingLeft: '0em', 500)

  @hideTree = ->
    if !$('#sidebar').hasClass('filter-hide')
      $('.with-sidebar').animate(paddingLeft: '0em', 500)
      $('#sidebar').addClass('filter-hide')
