window.App ||= {}
class App.MeterUpdateImports extends App.Base

  index: =>
    Utility.Datatables.meterUpdateImports()
    Utility.FormActions.prepareIndexTable()
    App.Equipments.prepareUploads()

  show: =>
    App.MeterUpdateImports.refreshIfProcessing()
    App.Equipments.prepareUploads()

  @refreshIfProcessing: =>
    if $('i.import-pending').length
      setTimeout (->
        if $('i.import-pending').data('import-id') > 0
          $.get '/meter_update_imports/' + $('i.import-pending').data('import-id') + '/refresh', null, null, 'script'
        return
      ), 5000
