window.App ||= {}
class App.Expenses extends App.Base

  index: =>

  show: =>

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Datatables.expenseAttachmentsViewOnly()
    App.Attachments.attachmentUpload()
    Utility.Collapsable.prepare()
    this.prepareEquipmentIdSelect()
    Utility.FlyInPanel.prepare()
    this.prepareEdit()

  edit: =>
    Utility.FormActions.newEditForm()
    App.Attachments.attachmentUpload()
    Utility.Collapsable.prepare()
    Utility.Datatables.expenseAttachments()
    Utility.FlyInPanel.prepare()
    this.prepareEdit()

  prepareEquipmentIdSelect: =>
    $("#expense_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/expenses/new', {
          equipment_id: $(@).val()
          save_type: $('#save-and-button').attr('name')
        }, null, 'script'

    if $("#expense_equipment_id").length > 0 && parseInt($("#expense_equipment_id").val()) > 0
      Utility.EquipmentRequired.enable()
      $('#expense_description').focus();
    else if $("#expense_equipment_id").length > 0
      Utility.EquipmentRequired.disable()
      $('#expense_equipment_id')[0].selectize.focus();

  prepareEdit: =>
    $('#expense_specify_part_used').on 'change', ->
      if $('#expense_specify_part_used').prop('checked')
        if parseInt($("#hidden_expense_quantity").val()) > 0
          $('#expense_quantity').val($("#hidden_expense_quantity").val())
        else
          $('#expense_quantity').val("1")
      else
        $('#expense_quantity').val("0")
    $("#expense_quantity, #expense_charged_unit_cost").on 'keyup', ->
      App.Expenses.calculateTotalCost()

    $("#expense_inventory_id").on 'change', ->
      id = $(@).val()
      $.ajax
        url: "/expenses/get_part_unit_cost?part_id=" + id
        data: null
        dataType: 'script'

    if $("#expense_equipment_id").length > 0 && parseInt($("#expense_equipment_id").val()) > 0
      $('#expense_description').focus();
    else if $("#expense_equipment_id").length > 0
      $('#expense_equipment_id')[0].selectize.focus();

  @calculateTotalCost = ->
    unitCost = parseFloat($("#expense_charged_unit_cost").val().replace(/[^0-9.]/g, ''))
    quantity = parseFloat($("#expense_quantity").val().replace(/[^0-9.]/g, ''))
    unitCost = 0 unless unitCost > 0
    quanity = 0 unless quantity > 0
    calculatedTotalCost = unitCost * quantity
    if calculatedTotalCost > 0
      $("#expense_total_cost").val(parseFloat(Math.round(calculatedTotalCost * 100) / 100).toFixed(2))
