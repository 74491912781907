window.App ||= {}
class App.TaskImports extends App.Base

  new: =>
    Utility.SelectizeFields.prepare()
    this.prepareEquipmentSelect()

  prepareEquipmentSelect: =>
    $("#select_equipment").change ->
      if $(@).val() > 0
        $.ajax
          url: window.location.href,
          data: {select_equipment: $(@).val()},
          dataType: "script"
    $("input#select_all").change ->
      $("input[name='task_ids[]']").prop('checked', $("input#select_all").prop('checked'))

