window.App ||= {}
class App.TemplateEquipmentAdditions extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareTable()
    Utility.FlyInPanel.prepare()

  prepareTable: =>
    Utility.Datatables.templateEquipmentAdditions()
    Utility.FormActions.prepareIndexTable()
    $("#select-all-items").click ->
      $("input[name='template_equipment_addition[equipment_ids][]']").prop('checked', $(@).prop('checked'))
