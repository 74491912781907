$ = require 'jquery'
window.Utility ||= {}

###

  The Confirmation Dialog is meant to be a direct replacement for the default Rails confirmation
  triggered via the data-confirm attribute.

  USAGE:

  The Confirmation Dialog may be used in two ways:

  1. Add the data-confirm attribute to a link element. The text provided to the attribute will become
  the message displayed in the confirmation dialog. By default, the modal will appear with a light
  red background, a red border, a red circle exclamation icon, a "No" button with a white background,
  and a "Yes" button with a red background. All of these attributes may be customized by providing
  additional data-confirm- attributes (see below).

  2. Call Utility.ConfirmationDialog.show() from any JS code that requires user confirmation.
  The show function takes two required arguments: (a) A config object with display attributes
  for the dialog (see below), (b) A callback function that will be executed when the user confirms
  the action.

  CUSTOMIZATION:

  The appearance of the confirmation dialog can be customized if the default appearance is
  insufficient. The customization approach depends on which of the two methods is being used
  to display the confirmation.

  1. When using the data-confirm approach, append one of the additional data- attributes listed
  below.

  2. When using the direct JS function call, supply a config object with the keys matching the
 associated attribute.

 For each attribute listed below, the data-confirm attribute is listed first with the config
 object key shown in parenthesis.

 For example, data-confirm (mainText) means these are equivalent:
    <a href="#" data-confirm="Are you sure?">Link</a>
    Utility.ConfirmationDialog.show({ mainText: 'Are you sure?' }, callback)

 data-confirm (mainText): Primary text to display in the confirmation dialog.

 data-confirm-title (title): Optional title string to display above the main confirmation
 dialog text. Shows in a slightly larger, bolder font.

 data-confirm-show-icon (showIcon): Optional boolean to control whether or not an icon appears in
 the dialog. Defaults to true.

 data-confirm-icon-class (iconClass): Optional class string to override the icon's class and
 display whatever icon in whatever color is desired.

 data-confirm-dialog-class (dialogClass): Optional class string to override the class of the
 dialog container itself to control attributes like background color and border.

 data-confirm-commit-label (commitLabel): Optional label string to override the text displayed
 in the commit button. Defaults to "Yes".

 data-confirm-cancel-label (cancelLabel): Optional label string to override the text displayed
 in the cancel button. Defaults to "No".

 data-confirm-commit-class (commitClass): Optional class string to override the styling
 of the commit button. Use to control attributes like background and text color.

 data-confirm-cancel-class (cancelClass): Optional class string to override the styling
 of the cancel button.

###


class Utility.ConfirmationDialog
  @dialogContainer = null
  @dialogModal = null
  @cancelButtonElement = null

  @prepare = (config) ->
    { mainText, title, showIcon, iconClass, dialogClass, commitLabel, cancelLabel, commitClass, cancelClass } = config

    Utility.ConfirmationDialog.dialogContainer = document.getElementById('confirm-dialog-container')
    Utility.ConfirmationDialog.dialogModal = document.getElementById('confirm-dialog-modal')
    Utility.ConfirmationDialog.cancelButtonElement = document.getElementById('custom-confirm-cancel-button')

    mainTextElement = document.getElementById('custom-confirm-main-text')
    titleElement = document.getElementById('custom-confirm-title')
    iconContainerElement = document.getElementById('custom-confirm-icon-container')
    iconElement = document.getElementById('custom-confirm-icon')
    commitButtonElement = document.getElementById('custom-confirm-commit-button')

    iconDefaultClass = 'far fa-exclamation-circle tw-text-red-500 tw-text-4xl'
    dialogDefaultClass = 'im-clear tw-p-5 tw-mx-1 tw-rounded-md tw-w-full sm:tw-w-3/4 md:tw-w-1/2 tw-max-w-md tw-shadow-xl tw-border-solid tw-border-2'
    dialogColorClass = 'tw-bg-red-50 tw-border-red-400'
    commitButtonDefaultClass = 'im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-shadow-sm tw-outline-none focus:tw-ring-inset focus:tw-ring-[2px] focus:tw-ring-blue-600 sm:tw-w-auto sm:tw-mt-0 tw-px-5 tw-py-3 tw-text-sm tw-font-semibold'
    commitButtonColorClass = 'tw-bg-red-500 tw-text-white hover:tw-bg-red-600'
    cancelButtonDefaultClass = 'im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-outline-none focus:tw-ring-[2px] focus:tw-ring-blue-600 sm:tw-mt-0 sm:tw-w-auto sm:tw-mr-3 sm:tw-mb-0 tw-px-5 tw-py-3 tw-text-sm tw-font-semibold tw-ring-gray-300'
    cancelButtonColorClass = 'tw-bg-white tw-text-gray-900 hover:tw-bg-gray-50'

    mainText ?= 'Are you sure?'
    showIcon ?= true
    commitLabel ?= 'Yes'
    cancelLabel ?= 'No'

    mainTextElement.innerText = mainText
    commitButtonElement.innerText = commitLabel
    Utility.ConfirmationDialog.cancelButtonElement.innerText = cancelLabel

    if title
      titleElement.classList.remove('tw-hidden')
      titleElement.innerText = title
    else
      titleElement.classList.add('tw-hidden')
      titleElement.innerText = ''

    if showIcon
      iconContainerElement.classList.remove('tw-hidden')
    else
      iconContainerElement.classList.add('tw-hidden')

    if iconClass
      iconElement.classList = "tw-text-4xl #{iconClass}"
    else
      iconElement.classList = iconDefaultClass

    if dialogClass
      Utility.ConfirmationDialog.dialogModal.classList = "#{dialogDefaultClass} #{dialogClass}"
    else
      Utility.ConfirmationDialog.dialogModal.classList = "#{dialogDefaultClass} #{dialogColorClass}"

    if commitClass
      commitButtonElement.classList = "#{commitButtonDefaultClass} #{commitClass}"
    else
      commitButtonElement.classList = "#{commitButtonDefaultClass} #{commitButtonColorClass}"

    if cancelClass
      Utility.ConfirmationDialog.cancelButtonElement.classList = "#{cancelButtonDefaultClass} #{cancelClass}"
    else
      Utility.ConfirmationDialog.cancelButtonElement.classList = "#{cancelButtonDefaultClass} #{cancelButtonColorClass}"

  @_showContainer = () ->
    Utility.ConfirmationDialog.dialogContainer.style.animation = 'im-fade-in 0.25s forwards'
    Utility.ConfirmationDialog.dialogModal.style.animation = 'im-zoom-in 0.5s forwards'
    Utility.ConfirmationDialog.dialogContainer.classList.remove('tw-hidden')
    Utility.ConfirmationDialog.cancelButtonElement.focus()

  @_hideContainer = () ->
    $(document).off 'keydown.confirmDialog'
    Utility.ConfirmationDialog.dialogModal.style.animation = 'im-zoom-out 0.25s forwards'
    Utility.ConfirmationDialog.dialogContainer.style.animation = 'im-fade-out 0.5s forwards'

    setTimeout ->
      Utility.ConfirmationDialog.dialogContainer.classList.add('tw-hidden')
      Utility.ConfirmationDialog.dialogContainer.style.animation = ''
      Utility.ConfirmationDialog.dialogModal.style.animation = ''
    , 500

  @show = (config, callback) ->
    @prepare(config)
    @_showContainer()

    doCancel = () =>
      @_hideContainer()

    doConfirm = () =>
      callback()
      @_hideContainer()

    handleKeydown = (e) =>
      if e.keyCode == 27  # Esc
        doCancel()

    # Cancel button
    $('#confirm-dialog-modal #custom-confirm-cancel-button').off('click').on 'click', (e) ->
      e.preventDefault()
      e.stopPropagation()
      doCancel()

    # OK button
    $('#confirm-dialog-modal #custom-confirm-commit-button').off('click').on 'click', (e) ->
      e.preventDefault()
      e.stopPropagation()
      doConfirm()

    # Allow esc and enter key confirm and cancel
    $(document).on 'keydown.confirmDialog', handleKeydown

$.rails.allowAction = (element) ->
  mainText = element.data('confirm')

  if not mainText
    return true

  title = element.data('confirm-title')
  showIcon = element.data('confirm-show-icon')
  iconClass = element.data('confirm-icon-class')
  dialogClass = element.data('confirm-dialog-class')
  commitLabel = element.data('confirm-commit-label')
  cancelLabel = element.data('confirm-cancel-label')
  commitClass = element.data('confirm-commit-class')
  cancelClass = element.data('confirm-cancel-class')

  config = { mainText, title, showIcon, iconClass, dialogClass, commitLabel, cancelLabel, commitClass, cancelClass }

  if $.rails.fire(element, 'confirm')
    Utility.ConfirmationDialog.show(config, () ->
      $.rails.fire(element, 'confirm:complete')
      oldAllowAction = $.rails.allowAction
      $.rails.allowAction = -> true
      element.trigger 'click'
      $.rails.allowAction = oldAllowAction
    )

  false

