window.App ||= {}
class App.MaintenanceTemplates extends App.Base

  index: =>
    Utility.Datatables.maintenanceTemplates()
    Utility.FormActions.prepareIndexTable()
    App.MaintenanceTemplates.refreshIfProcessing()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    App.MaintenanceTemplates.prepareTable()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    App.MaintenanceTemplates.prepareTable()
    Utility.FlyInPanel.prepare()

  @prepareTable: =>
    Utility.Datatables.maintenanceTemplateTasks()
    Utility.FormActions.prepareIndexTable()
    $("#check_all_checkbox").click ->
      $("input[name='maintenance_template[equipment_ids][]']").prop('checked', $(@).prop('checked'))

  @refreshIfProcessing = ->
    if $('span.update-pending').length
      setTimeout (->
        $('#maintenance-templates-table').DataTable().ajax.reload()
        return
      ), 5000

  @refreshTaskIfDeleting: ->
    if $('span.delete-pending').length
      setTimeout (->
        $.ajax
          url: '/maintenance_templates/refresh_tasks/' + $(".edit_maintenance_template").data('template-id')
          data: null
          dataType: 'script'
        return
      ), 5000
