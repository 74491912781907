window.App ||= {}
class App.AdminOrganizations extends App.Base

  index: =>
    Utility.Datatables.adminOrganizations()
    Utility.FormActions.prepareIndexTable()

  edit: =>
    Utility.AccordionTab.prepare()
    Utility.SelectizeFields.prepare()
    Utility.Collapsable.prepare()
    Utility.Toggle.prepare()
    App.Organizations.toggleUserActivationControls()

  show: =>
    Utility.AccordionTab.prepare()
    setTimeout (->
      $("html, body").scrollTop(0)
      return
    ), 250
