window.App ||= {}
class App.Pictures extends App.Base

  index: =>
    App.Pictures.fileUpload()

  @fileUpload = ->
    $("#file_upload[data-picture='t']").fileupload
      uploadTemplateId: 'picture-template-upload',
      autoUpload: true,
      maxFileSize: 10000000,
      minFileSize: 1,
      acceptFileTypes:  /(\.|\/)(jpe?g|png|jfif)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      submit: (e, data) ->
        Utility.Upload.sanitize_file_names(data.files)
        $.ajax
          dataType: 'json'
          url: "/" + $(@).data('object-type') + "/" +  $(@).data('object-id') + "/pictures/new/"
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $("#file_upload[data-picture='t']").fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        data.context.attr('data-key', key)
        $.ajax
          url: "/" + $(@).data('object-type') + "/" +  $(@).data('object-id') + "/pictures"
          type: 'POST'
          data: {picture_key: key}
          cache: false
          dataType: 'script'

  @fileUploadInspection = ->
    $("#file_upload.inspection-step-picture-uploader").fileupload
      uploadTemplateId: 'picture-template-upload',
      autoUpload: true,
      maxFileSize: 10000000,
      minFileSize: 1,
      acceptFileTypes:  /(\.|\/)(jpe?g|png|jfif)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      submit: (e, data) ->
        Utility.Upload.sanitize_file_names(data.files)
        $.ajax
          dataType: 'json'
          url: "/" + $(@).data('object-type') + "/" +  $(@).data('object-id') + "/pictures/new/"
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $("#file_upload").fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        data.context.attr('data-key', key)
        stepId = $(@).data('object-id')
        $.ajax
          url: "/" + $(@).data('object-type') + "/" +  $(@).data('object-id') + "/pictures"
          type: 'POST'
          data: {picture_key: key}
          cache: false
          dataType: 'json'
          success: (result) ->
            Utility.ReactEvents.trigger({ stepId, picture: result }, 'PictureUpload')
