window.App ||= {}
class App.CustomFields extends App.Base

  new: =>
    Utility.FormActions.newEditForm()

  edit: =>
    Utility.FormActions.newEditForm()

  @prepareSelectizeChange = (type) ->
    $("select##{type}_custom_fields_addnew_label").change ->
      value = $(@).val()
      label = $('label[for="' + "#{type}" + '_custom_fields_' + value.replace(/ /g,"_") + '" i]')
      duplicate = (value.length > 0) && (label.length > 0)
      if duplicate
        $(@)[0].selectize.setValue(null)
        $(@)[0].selectize.removeOption(value)
      if duplicate || (value.length == 0)
        $("input[data-custom-id=" + $(@)[0].selectize.$input.data('custom-id') + "]").attr('name', $(@)[0].selectize.$input.data('object-name') + "[custom_fields][addnew]")

  @showOrHideAddLink: =>
    if $('input.custom-input').length >= $("#custom-fields").data("custom-field-limit")
      $('#new-custom-field-link').hide()
    else
      $('#new-custom-field-link').show()

  @prepareSortable = ->
    if $("#custom_fields-table > tbody").length > 0
      el = $("#custom_fields-table > tbody")[0]
      sortable = Sortable.create(el,
        {
          handle: ".handle"
          scroll: true
          onEnd: (e) ->
            if e.oldIndex != e.newIndex
              console.log e
              $.ajax
                url: "/organizations/" + $("#custom_fields-table").data("organization-id") + "/reorder_custom_field?custom_field_type=" + $("#custom_fields-table").data("filter")
                type: 'put'
                data: {type: $(e.item).data('type'), old_index: e.oldIndex, new_index: e.newIndex}
                cache: false
                dataType: 'script'
        }
      )
