window.App ||= {}
class App.RepairImports extends App.Base

  show: =>
    App.RepairImports.refreshIfProcessing()

  @refreshIfProcessing: =>
    if $('i.import-pending').length
      setTimeout (->
        if $('i.import-pending').data('import-id') > 0
          $.get '/repair_imports/' + $('i.import-pending').data('import-id') + '/refresh', null, null, 'script'
        return
      ), 5000
