window.Utility ||= {}

class Utility.EquipmentRequired
  NON_SELECTIZE_FIELDS = '.disable-fields-without-equipment input, .disable-fields-without-equipment select, .disable-fields-without-equipment textarea'
  OTHER_STUFF = '.disable-fields-without-equipment .field-input span, .disable-fields-without-equipment .field-label label, .disable-fields-without-equipment a, .disable-fields-without-equipment h2.form-section'
  SELECTIZE_FIELDS = '.disable-fields-without-equipment select.selectized'
  ALL_FIELDS = "#{NON_SELECTIZE_FIELDS}, #{SELECTIZE_FIELDS}"

  @enable = ->
    $(NON_SELECTIZE_FIELDS).prop('disabled', false)
    $(OTHER_STUFF).removeClass('disabled')
    $(SELECTIZE_FIELDS).each ->
      if @selectize
        @selectize.enable()
    $(ALL_FIELDS).each ->
      label = $("label[for='#{@id}']")
      if label
        label.removeClass('disabled')
  @disable = ->
    $(NON_SELECTIZE_FIELDS).prop('disabled', true)
    $(OTHER_STUFF).addClass('disabled')
    $(SELECTIZE_FIELDS).each ->
      if @selectize
        @selectize.disable()
    $(ALL_FIELDS).each ->
      label = $("label[for='#{@id}']")
      if label
        label.addClass('disabled')
