window.App ||= {}
class App.Inventories extends App.Base

  index: =>
    window.scanContext = 'Inventory'
    this.prepareIndex()
    Utility.Drawer.toggleDrawers()
    Utility.Datatables.inventories()
    Utility.AccordionTab.prepare()
    Utility.FormActions.prepareIndexTable()
    Utility.HelpPanel.prepare()
    $("html, body").scrollTop(0)

  show: =>
    window.scanContext = 'Inventory'
    App.Attachments.fileUpload()
    Utility.FormActions.newEditForm()
    this.prepareScan()
    Utility.Datatables.receipts()
    Utility.Datatables.issuances()
    Utility.Datatables.inventoryUsage()
    Utility.Datatables.inventoryAttachments()
    Utility.FormActions.prepareIndexTable()
    Utility.AccordionTab.prepare()
    $("html, body").scrollTop(0)

  prepareScan: =>
    $(".scan-key").click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanInventoryKey($(@).data('id'))
      else
        ScanInterface.scanInventoryKey($(@).data('id'))

  prepareIndex: =>
    $(".location-link").click ->
      id=$(@).attr("data-id")
      $.ajax
        url: "/warehouses/"+id+"/edit"
        data: null
        dataType: "script"

    $(".scan-lookup").click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanInventory()
      else
        ScanInterface.scanInventory()

    anchor = window.location.hash.substr(1)
    if anchor == '' || anchor == 'parts'
      Utility.Sidebar.refreshSideBar()

  @scan = (scanKey) ->
    $.ajax
      url: "/inventories/scan"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @scanKey = (id, scanKey) ->
    $.ajax
      url: "/inventory_masters/" + id + "/edit"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'
