window.App ||= {}
class App.MaintenanceTemplateTasks extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Collapsable.prepare()
    App.RecurringTasks.prepareCollapsables()
    App.MaintenanceTemplateTasks.prepareLinkedTasks()
    App.Attachments.fileUpload()
    Utility.Datatables.workOrderAttachments()
    Utility.Toggle.prepare()
    this.toggleDueEveryDueOn()
    $('#due-on-date-fields').on 'cocoon:after-insert', (e, insertedItem) ->
      Utility.Datepicker.prepare()


  edit: =>
    Utility.FormActions.newEditForm()
    Utility.Collapsable.prepare()
    App.RecurringTasks.prepareCollapsables()
    App.MaintenanceTemplateTasks.prepareLinkedTasks()
    App.Attachments.fileUpload()
    Utility.Datatables.workOrderAttachments()
    Utility.Toggle.prepare()
    this.toggleDueEveryDueOn()
    $('#due-on-date-fields').on 'cocoon:after-insert', (e, insertedItem) ->
      Utility.Datepicker.prepare()
    Utility.FlyInPanel.prepare()

  @prepareLinkedTasks: =>
    $('#maintenance_template_task_primary_meter_value').change ->
      $.getJSON '/maintenance_templates/' + $('.linked-task-form').data('parent-id') + '/linked_tasks.json?primary_meter_value=' + $(@).val(), (data) ->
        $('#maintenance_template_task_linked_task_id')[0].selectize.clearOptions()
        $('#maintenance_template_task_linked_task_id')[0].selectize.addOption(data)

  toggleDueEveryDueOn: =>
    DUE_EVERY_DATE_FIELDS = '#due-every-date-fields'
    DUE_ON_DATE_FIELDS = '#due-on-date-fields'
    DUE_DATE_MODE_FIELD = '#maintenance_template_task_due_date_mode'
    DATE_ENABLED = '#maintenance_template_task_date_tracking_enabled'

    DUE_EVERY_STATUS = 'every-status'
    DUE_ON_STATUS = 'on-status'
    DISABLED_STATUS = 'disabled-status'

    $('#due_on_hint').hide()
    if $('#every-status').hasClass('status-selected')
      $(DUE_ON_DATE_FIELDS).hide()
    else if $('#on-status').hasClass('status-selected')
      $(DUE_EVERY_DATE_FIELDS).hide()
      $('#due_on_hint').show()

    $('span.date-status').on 'click', ->
      span = $(@).attr('id')

      $('span.date-status').removeClass('status-selected')
      $('span.date-status').addClass('status-unselected')
      $("##{span}").addClass('status-selected')

      if (span == DUE_EVERY_STATUS)
        $(DUE_ON_DATE_FIELDS).hide()
        $(DUE_EVERY_DATE_FIELDS).fadeIn()
        $(DUE_DATE_MODE_FIELD).val('due_every')
        $('#date-tracking').show()
        $('#due_on_hint').hide()
      else if (span == DUE_ON_STATUS)
        $(DUE_EVERY_DATE_FIELDS).hide()
        $(DUE_ON_DATE_FIELDS).fadeIn()
        $(DUE_DATE_MODE_FIELD).val('due_on')
        $('#date-tracking').show()
        $('#due_on_hint').show()
        if $('input.due-on-field').length == 0
          $("a.add_fields[data-association='due_on_date']").trigger('click')
      else
        $('#date-tracking').hide()
      $(DATE_ENABLED).val(span != DISABLED_STATUS)


    DUE_EVERY_METER_FIELDS = '.due-every-meter-fields'
    DUE_AT_METER_FIELDS = '.due-at-meter-fields'

    if $('#primary-every-status').hasClass('status-selected')
      $(DUE_AT_METER_FIELDS + "[data-meter=primary]").hide()
    else if $('#primary-on-status').hasClass('status-selected')
      $(DUE_EVERY_METER_FIELDS + "[data-meter=primary]").hide()

    if $('#secondary-every-status').hasClass('status-selected')
      $(DUE_AT_METER_FIELDS + "[data-meter=secondary]").hide()
    else if $('#secondary-on-status').hasClass('status-selected')
      $(DUE_EVERY_METER_FIELDS + "[data-meter=secondary]").hide()

    $('span.meter-status').on 'click', ->
      span = $(@).attr('id')

      $("span.meter-status[data-meter=" + $(@).data('meter') + "]").removeClass('status-selected')
      $("span.meter-status[data-meter=" + $(@).data('meter') + "]").addClass('status-unselected')
      $("##{span}").addClass('status-selected')
      if (span.indexOf("-#{DUE_EVERY_STATUS}") >= 0)
        $(DUE_AT_METER_FIELDS + "[data-meter=" + $(@).data('meter') + "]").hide()
        $(DUE_EVERY_METER_FIELDS + "[data-meter=" + $(@).data('meter') + "]").fadeIn()
        $("#maintenance_template_task_due_" + $(@).data('meter') + "_meter_mode").val('due_every')
        $("##{$(@).data('meter')}-meter-tracking").show()
      else if (span.indexOf("-#{DUE_ON_STATUS}") >= 0)
        $(DUE_EVERY_METER_FIELDS + "[data-meter=" + $(@).data('meter') + "]").hide()
        $(DUE_AT_METER_FIELDS + "[data-meter=" + $(@).data('meter') + "]").fadeIn()
        $("#maintenance_template_task_due_" + $(@).data('meter') + "_meter_mode").val('due_at')
        if $("[data-meter=" + $(@).data('meter') + "] input.due-at-field").length == 0
          $("a.add_fields[data-association='due_at_" + $(@).data('meter') + "_meter']").trigger('click')
        $("##{$(@).data('meter')}-meter-tracking").show()
      else
        $("##{$(@).data('meter')}-meter-tracking").hide()
      $("#maintenance_template_task_" + $(@).data('meter') + "_meter_tracking_enabled").val(span.indexOf("-#{DISABLED_STATUS}") < 0)
