window.App ||= {}
class App.ReportCustomizations extends App.Base
  @prepareSortable = ->
    el = $("#customization-list")[0]
    sortable = Sortable.create(el,
      {
        handle: ".handle"
        scroll: true
        onEnd: (e) ->
          if e.oldIndex != e.newIndex
            console.log e
            $.ajax
              url: "/report_customizations/" + $("#customization-list").data("customization-id") + "/reorder"
              type: 'put'
              data: {customization:{ordered_field_array: $.map($("#customization-list li"), (e) -> $(e).data("name"))}}
              cache: false
              dataType: 'script'
      }
    )
