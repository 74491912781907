window.App ||= {}
class App.BulkTaskImports extends App.Base

  index: =>
    Utility.Datatables.bulkTaskImports()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareEquipmentSelect()

  show: =>
    App.BulkTaskImports.refreshIfProcessing()

  prepareEquipmentSelect: =>
    $("#bulk_task_import_selected_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.ajax
          url: window.location.href,
          data: {select_equipment: $(@).val()},
          dataType: "script"
    $("input#select_all_equipment").change ->
      $("input[name='[bulk_task_import]target_equipment_ids[]']").prop('checked', $("input#select_all_equipment").prop('checked'))
      App.BulkTaskImports.checkSelectedEquipment()

    $("input#select_all_tasks").change ->
      $("input[name='[bulk_task_import]selected_task_ids[]']").prop('checked', $("input#select_all_tasks").prop('checked'))
      App.BulkTaskImports.checkSelectedEquipment()

    $("input[name='[bulk_task_import]target_equipment_ids[]']").change ->
      App.BulkTaskImports.checkSelectedEquipment()

  @checkSelectedEquipment: =>
    selected = $("input[name='[bulk_task_import]target_equipment_ids[]']:checked").length
    if selected > 50
      $.jGrowl(selected + $("#max-equipment-exceeded").text(), {position: 'center', theme: 'error'})

  @refreshIfProcessing: =>
    if $('i.import-pending').length
      setTimeout (->
        if $('i.import-pending').data('import-id') > 0
          $.get '/bulk_task_imports/' + $('i.import-pending').data('import-id'), null, null, 'script'
        return
      ), 5000
