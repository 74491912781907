window.App ||= {}
class App.PublicRepairSubmissions extends App.Base

  new: =>
    Utility.SelectizeFields.prepare()
    Utility.Datepicker.prepare()
    App.PublicRepairSubmissions.prepareUploads()
    Utility.Datatables.repairAttachments()
    this.prepareEquipmentIdSelect()

  prepareEquipmentIdSelect: =>
    $("#repair_equipment_id").change ->
      if parseInt($(@).val()) > 0
        url = $('#new_repair').attr('action') + '\\new'
        $.get url, {
          equipment_id: $(@).val()
          save_type: $('#save-and-button').attr('name')
        }, null, 'script'
    if parseInt($("#repair_equipment_id").val()) > 0
      $('#repair_name')[0].selectize.focus()
    else
      $('#repair_equipment_id')[0].selectize.focus()

  @prepareUploads = ->
    tmpl.cache["attachment-template-upload"] = null
    $('#file_upload').fileupload
      uploadTemplateId: 'attachment-template-upload',
      maxFileSize: 10000000
      autoUpload: true
      minFileSize: 1
      acceptFileTypes:  /(\.|\/)(jpe?g|gif|png)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      add: (e, data) ->
        if e.isDefaultPrevented()
          return false
        $this = $(this)
        that = $this.data('blueimp-fileupload') or $this.data('fileupload')
        options = that.options
        data.context = that._renderUpload(data.files).data('data', data).addClass('processing')
        options.filesContainer[if options.prependFiles then 'prepend' else 'append'] data.context
        that._forceReflow data.context
        that._transition data.context
        data.process(->
          $this.fileupload 'process', data
        ).always(->
          data.context.each((index) ->
            $(this).find('.size').text that._formatFileSize(data.files[index].size)
            return
          ).removeClass 'processing'
          that._renderPreviews data
          return
        ).done(->
          data.context.find('.start').prop 'disabled', false
          if that._trigger('added', e, data) != false and (options.autoUpload or data.autoUpload) and data.autoUpload != false
            $.jGrowl('Uploading...', {position: 'center', theme: 'success'})
            data.submit()
          return
        ).fail ->
          if data.files.error
            data.context.each (index) ->
              error = data.files[index].error
              if error
                process = $('tr.template-upload:last')
                if process
                  process.remove();
                $.jGrowl(error + '. Please select a picture file and try again.', {position: 'center', theme: 'error'})
              return
          return
        return
      submit: (e, data) ->
        Utility.Upload.sanitize_file_names(data.files)

        $.ajax
          dataType: 'json'
          url: "/public_documents/new"
          data: {
            key: $('#repair_submission_key').val()
          }
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $('#file_upload').fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        tags = data.context.find("input.tags").val()
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        attachmentable_id = data.context.find("input.attachmentable_id").val()
        attachmentable_key = data.context.find("input.attachmentable_key").val()
        data.context.attr('data-key', key)

        $.ajax
          url: "/public_attachments"
          type: 'POST'
          data: {
            attachment: {
              document_key: key,
              name: name,
              tags: tags,
              attachmentable_type: attachmentable_type,
              attachmentable_id: attachmentable_id,
              attachmentable_key: attachmentable_key
            }
            key: $('#repair_submission_key').val()
          }
          cache: false
          dataType: 'script'
