window.App ||= {}
class App.EquipmentAssignments extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    App.CustomFields.showOrHideAddLink()
    if ($('#equipment_assignment_job_site_id').length > 0) && $('#equipment_assignment_job_site_id').not(':hidden')
      $('#equipment_assignment_job_site_id')[0].selectize.focus();
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()
