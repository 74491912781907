window.App ||= {}
class App.Warehouses extends App.Base

  index: =>
    Utility.Datatables.warehouses()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()
