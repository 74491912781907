import debounce from 'lodash/debounce'
window.Utility ||= {}

class Utility.FlyInPanel
  @flyInActive = false
  @flyInShouldClose = false
  @reloadAfterClose = false
  @isNested = false
  @parentPanelId = null
  @originUrl = null
  @actionFromFlyIn = false

  @show = (origin = null) ->
    return if @flyInActive

    # Initialize panel with loading spinner
    @flyInActive = true
    @originUrl = origin ? $('#fly-in-panel').data('origin-url')
    spinnerHtml = """
      <div class="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
        <i class="fa fa-spinner fa-spin tw-text-2xl"></i>
      </div>
    """
    $('#fly-in-panel').html(spinnerHtml)

    # Display the panel and prevent body scrolling
    $('#fly-in-panel').addClass('active')
    $('body').addClass('prevent-scrolling')

    # Set a full-page overlay to prevent underlying element interactions
    unless $('.full-page-overlay').length
      $('<div class="full-page-overlay tw-fixed tw-bg-black tw-top-0 tw-left-0 tw-w-full tw-h-full tw-opacity-0 tw-transition-opacity tw-duration-500"></div>').appendTo('body')
    # Fade in the overlay after panel has enough time to start its animation
    setTimeout ->
      $('.full-page-overlay').removeClass('tw-opacity-0').addClass('tw-opacity-30')
    , 100

  @hide = (skipNavigation = false, replaceState = false, skipNestedBack = false) ->
    if @isNested
      history.back() unless skipNestedBack
      return

    @flyInActive = false
    @parentPanelId = null
    @isNested = false
    @flyInShouldClose = false
    @reloadAfterClose = false

    # Hide the panel and re-enable body scrolling
    $('#fly-in-panel').removeClass('active')
    $('body').removeClass('prevent-scrolling')

    # Fade out the full-page-overlay
    $('.full-page-overlay').removeClass('tw-opacity-30').addClass('tw-opacity-0')

    # Remove any accordion tab event handlers
    $('.fly-in-form-content > .tabs').off("click.accordion-tab", "> li > a")

    # Check for the origin URL and redirect if it exists
    unless skipNavigation
      if @originUrl
        urlParts = @originUrl.split('#')
        baseUrl = urlParts[0]
        currentHash = if urlParts.length > 1 then urlParts[1] else ''
        ajaxUrl = if currentHash then baseUrl + "?tab=" + encodeURIComponent(currentHash) else baseUrl

        $.ajax
          url: ajaxUrl
          type: 'GET'
          dataType: 'script'

    if replaceState && @originUrl
      history.replaceState({fmp: true}, "", @originUrl)

    # Remove event handlers
    $('#fly-in-panel').off('scroll.fly-in-fixed-bar')
    $(window).off('resize.fly-in-fixed-bar')

    # Once there's been time enough for the panel to start flying out,
    # empty its contents and remove the full-page overlay
    setTimeout ->
      @originUrl = null
      ReactRailsUJS.unmountComponents(document.getElementById('fly-in-panel'))
      $('#fly-in-panel').empty()
      if $('.full-page-overlay').length
        $('.full-page-overlay').remove()
    , 250

  @dynamicRender = (html, fly_in_enabled, do_show = false) ->
    if @flyInActive && fly_in_enabled
      # In rare cases, it's possible to have the same form already rendered in the
      # primary div as being rendered in the fly-in panel. If that happens, clear
      # the HTML of the primary div to avoid JQuery event conflicts from duplicate forms.
      topLevelId = $(html).first().attr('id')
      if $('#primary').children().first().attr('id') == topLevelId
        $('#primary').empty()

      # Store the id of the panel that initially launched the fly-in to determine nesting
      regex = /<div class='panel-container.*?' id='(.*?)'>/
      match = html.match(regex)
      panelId = if match? then match[1] else 'default-panel'
      @parentPanelId ||= panelId
      @isNested = panelId != @parentPanelId
      @actionFromFlyIn = true

      flyInPanel = $('#fly-in-panel')
      flyInPanel.html(html)
      # Force a nudge down to trigger event and render bar properly
      flyInPanel.scrollTop(1)

      #flyInPanel.off('scroll.fly-in-fixed-bar').on('scroll.fly-in-fixed-bar', handleScroll)
      #$(window).off('resize.fly-in-fixed-bar').on('resize.fly-in-fixed-bar', () => positionButtonBar(true))

      # Force scroll to ensure the bottom bar always appears initially
      $("#fly-in-panel").animate({ scrollTop: 1});
      $("#fly-in-panel").animate({ scrollTop: 0});
      ReactRailsUJS.mountComponents()
    else
      @hide(true) if @flyInActive # Make sure we never keep fly-in open if action doesn't support it
      primary = $('#primary')
      primary.html(html)
      @isNested = false
      @parentPanelId = null
      @actionFromFlyIn = false
      primary.show() if do_show

  @prepare = ->
    if @flyInActive
      $('.panel-body').removeClass('tw-opacity-0').addClass('tw-opacity-100')

  @resetNesting = ->
    if @flyInActive
      @isNested = false

  @setShouldClose = (shouldClose, withReload = false) ->
    @flyInShouldClose = shouldClose
    @reloadAfterClose = withReload

    # Make sure we don't cause form submissions to cease
    true

  @shouldClose = (response_header) ->
    # Marked ok to close and no form errors in the panel exist
    response_header != 'error' && @flyInShouldClose

  @refreshButtonBar = ->
    positionButtonBar();

  endOfScroll = false

  handleScroll = =>
    if endOfScroll
      # Prevent overload of scrolling/positioning attempts once we've reached the bottom
      debouncedPositionButtonBar()
    else
      positionButtonBar()

  positionButtonBar = ->
    # Keep the button bar positioned at the bottom of the fly-in panel when scrolling
    # or resizing. Necessary because position: sticky won't allow out-of-order elements
    # to appear above the bar if they're not positioned that way in HTML structure.
    # We also can't rely on position: fixed bottom: 0 for button bar because the fly-in
    # panel itself is fixed, so the button bar scrolls and obscures content.
    requestAnimationFrame ->
      # Browser doesn't reliably report button bar height, especially at differing zoom levels.
      # Therefore, use one of two fixed heights depending on whether not the hint is displayed.
      HEIGHT_WITH_HINT = 110
      HEIGHT_WITHOUT_HINT = 70

      flyInPanel = $('#fly-in-panel')
      formHintPresent = $('.form-hint', flyInPanel).length > 0
      buttonBarHeight = if formHintPresent then HEIGHT_WITH_HINT else HEIGHT_WITHOUT_HINT

      offsetHeight = flyInPanel[0].offsetHeight
      scrollHeight = flyInPanel[0].scrollHeight
      scrollTop = flyInPanel.scrollTop()
      flyInPanelHeight = flyInPanel.innerHeight()
      buttonBar = $('.fly-in-fixed-bar')
      heightDiff = flyInPanelHeight - buttonBarHeight
      scrolledOffset = scrollTop + offsetHeight
      newButtonBarTop = heightDiff + scrollTop

      if (scrolledOffset >= scrollHeight)
        endOfScroll = true
      else
        endOfScroll = false

      buttonBar.css
        'position': 'fixed'
        'bottom': 'auto'
        'top': "#{newButtonBarTop}px"

  debouncedPositionButtonBar = debounce(positionButtonBar, 100)
