window.App ||= {}
class App.Messages extends App.Base

  index: =>
    Utility.AccordionTabLink.prepare()
    Utility.Datatables.messages();
    Utility.FormActions.prepareIndexTable();
    Utility.HelpPanel.prepare();
    App.Messages.prepareControls();

  @prepareControls: =>
    Utility.Parsley.prepare()
    Utility.SelectizeFields.prepare()
    $("form#new_message").find(".controls").hide()
    $("textarea#message_body").focusin ->
      $("form#new_message").find(".controls").fadeIn()
    $('[data-toggle="tooltip"]').tooltip()
    $('#cancel-message').click ->
      $("form#new_message").find(".controls").fadeOut()
    $('#message_to_user_id').change ->
      if $(@).val()
        $.get '/messages?conversations=true&to_user_id=' + $(@).val(), null, null, 'script'
      else
        $.get '/messages?conversations=true', null, null, 'script'
