window.App ||= {}
class App.BulkEditEmployees extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    this.toggleUserActivationControls()

  toggleUserActivationControls: =>
    $("select.delete").on 'change', ->
      if $(@).val() == 'Yes'
        $("select[data-object='" + $(@).data('object') + "']").val('Yes')

    $("select.create, select.edit").on 'change', ->
      if $(@).val() != 'Yes'
        $("select." + $(@).data('object') + ".complete").val('No')
        $("select." + $(@).data('object') + ".delete").val('No')
      else
        $("select." + $(@).data('object') + ".complete").val('Yes')
        $("select." + $(@).data('object') + ".view").val('Yes')

    $("select.view").on 'change', ->
      if $(@).val() != 'Yes'
        $("select." + $(@).data('object')).val('No')

    $("a#read-only-profile-link").on 'click', ->
      $("select.view, select.complete, select.create, select.edit, select.delete").val('No')
      $("select.equipment.view").val('Yes')

    $("a#superuser-profile-link").on 'click', ->
      $("select.view, select.complete, select.create, select.edit, select.delete").val('Yes')

    $("a#operator-profile-link").on 'click', ->
      $("select.view, select.complete, select.create, select.edit, select.delete").val('No')
      $("select.equipment.view").val('Yes')
      $("select.attachment.view").val('Yes')
      $("select.accident.create, select.accident.edit").val('Yes')
      $("select.expense.create, select.expense.edit").val('Yes')
      $("select.repair.create, select.repair.edit").val('Yes')
      $("select.meter_update.create, select.meter_update.edit").val('Yes')
      $("select.fuel_entry.create, select.fuel_entry.edit").val('Yes')
      $("select.equipment_attachment.view, select.equipment_attachment.create, select.equipment_attachment.edit").val('Yes')
      $("select.inspection.view, select.inspection.create, select.inspection.edit").val('Yes')
      $("select.equipment_assignment.create, select.equipment_assignment.edit").val('Yes')

    $("a#restore-default-link").on 'click', ->
      $("select.view, select.complete").val('Yes')
      $("select.maintenance_template").val('No')
      $("select.repair_name").val('No')
      $("select.report").val('No')
      $("select.user").val('No')
      $("select.work_order_type").val('No')
      $("select.create, select.edit, select.delete").val('No')
      $("select.equipment_assignment.create, select.equipment_assignment.edit").val('Yes')
      $("select.expense.create, select.expense.edit").val('Yes')
      $("select.repair.create, select.repair.edit").val('Yes')
      $("select.meter_update.create, select.meter_update.edit").val('Yes')
      $("select.fuel_entry.create, select.fuel_entry.edit").val('Yes')
      $("select.inspection.create, select.inspection.edit").val('Yes')
      $("select.job_site.create, select.job_site.edit").val('Yes')


    $('#select-all-locations').change ->
      if $(@).prop("checked")
        $('select.location').val('Yes')
      else
        $('select.location').val('')

    $('#select-all-warehouses').change ->
      if $(@).prop("checked")
        $('select.warehouse').val('Yes')
      else
        $('select.warehouse').val('')
