window.App ||= {}
class App.TireHistories extends App.Base

  index: =>
    window.scanContext = 'Tire History'
    Utility.Datatables.tire_histories()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    App.TireHistories.prepareUploads()
    Utility.Datatables.tireHistoryAttachments()
    Utility.FormActions.prepareIndexTable()
    this.prepareTireHistorySelections()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    App.TireHistories.prepareUploads()
    Utility.Datatables.tireHistoryAttachments()
    Utility.FormActions.prepareIndexTable()
    this.prepareTireHistorySelections()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'Tire History'
    Utility.FormActions.prepareIndexTable()
    Utility.AccordionTab.prepare()
    $("html, body").scrollTop(0)

  prepareTireHistorySelections: =>
    toggleHistoryInputs = ->
      $("#tire_history_axle")[0].selectize.disable()
      $("#tire_history_current_position")[0].selectize.disable()
      if $("#tire_history_service").val() == 'Mount' || $("#tire_history_service").val() == 'Dismount'
        $("#tire-history-equipment").show()
        if $("#tire_history_service").val() == 'Mount' || !$("#tire_history_equipment_id")[0].selectize.isDisabled
          $("#tire_history_axle")[0].selectize.enable()
          $("#tire_history_current_position")[0].selectize.enable()
      else if ($("#tire_history_equipment_id").val().length == 0) || !$("#tire_history_equipment_id")[0].selectize.isDisabled
        $("#tire-history-equipment").hide()
        if !$("#tire_history_equipment_id")[0].selectize.isDisabled
          $("#tire_history_equipment_id")[0].selectize.setValue('')

    $("#tire_history_service").change ->
      toggleHistoryInputs()
      if $("#tire_history_service").val() == 'Scrap'
        $("#reason-select").removeClass('hidden')
        $("#tire_history_reason")[0].selectize.setValue('')
      else
        $("#reason-select").addClass('hidden')

    $("#tire_history_equipment_id").change ->
      completedAt = $('#tire_history_completed_at_date').val() + ' ' + $('#tire_history_completed_at_time').val()
      id = $(@).attr('data-id')
      equipmentId = $("#tire_history_equipment_id").val()
      tireId = $("#tire_history_tire_id").val()
      App.TireHistories.getMeters(id, equipmentId, tireId, completedAt)

    $('#tire_history_completed_at_time').on 'change', ->
      completedAt = $('#tire_history_completed_at_date').val() + ' ' + $(@).val()
      id = $(@).attr('data-id')
      equipmentId = $("#tire_history_equipment_id").val()
      tireId = $("#tire_history_tire_id").val()
      App.TireHistories.getMeters(id, equipmentId, tireId, completedAt)

    $('#tire_history_completed_at_date').on 'change', ->
      completedAt = $(@).val() + ' ' + $('#tire_history_completed_at_time').val()
      id = $(@).attr('data-id')
      equipmentId = $("#tire_history_equipment_id").val()
      tireId = $("#tire_history_tire_id").val()
      App.TireHistories.getMeters(id, equipmentId, tireId, completedAt)

    Utility.MeterValidation.preparePrimary()

    if $("#tire_history_equipment_id").data('mounted') || ($("#tire_history_equipment_id").val().length > 0 && $("#tire_history_equipment_id").data('id') > 0)
      $("#tire_history_equipment_id")[0].selectize.disable()
    toggleHistoryInputs()

  @getMeters = (id, equipmentId, tireId, completedAt) ->
    if id == undefined
      historyId = ''
    else
      historyId = id
    if parseInt(equipmentId) > 0
      $.ajax
        url: "/equipment/"+equipmentId+"/tire/" + tireId + "/tire_histories/get_primary_meter?id="+ historyId + "&completed_at=" + completedAt + '&change=' + !$("#tire_history_equipment_id")[0].selectize.isDisabled
        data: null
        dataType: 'script'

  @prepareUploads = ->
    tmpl.cache["attachment-template-upload"] = null
    $('#file_upload').fileupload
      uploadTemplateId: 'attachment-template-upload',
      maxFileSize: 10000000
      autoUpload: true
      minFileSize: 1
      acceptFileTypes:  /(\.|\/)(jpe?g|gif|png|pdf|xlsx?|docx?|pptx?|csv)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      add: (e, data) ->
        if e.isDefaultPrevented()
          return false
        $this = $(this)
        that = $this.data('blueimp-fileupload') or $this.data('fileupload')
        options = that.options
        data.context = that._renderUpload(data.files).data('data', data).addClass('processing')
        options.filesContainer[if options.prependFiles then 'prepend' else 'append'] data.context
        that._forceReflow data.context
        that._transition data.context
        data.process(->
          $this.fileupload 'process', data
        ).always(->
          data.context.each((index) ->
            $(this).find('.size').text that._formatFileSize(data.files[index].size)
            return
          ).removeClass 'processing'
          that._renderPreviews data
          return
        ).done(->
          data.context.find('.start').prop 'disabled', false
          if that._trigger('added', e, data) != false and (options.autoUpload or data.autoUpload) and data.autoUpload != false
            $.jGrowl('Uploading...', {position: 'center', theme: 'success'})
            data.submit()
          return
        ).fail ->
          if data.files.error
            data.context.each (index) ->
              error = data.files[index].error
              if error
                $.jGrowl(error + '. Please select a picture file and try again.', {position: 'center', theme: 'error'})
              return
          return
        return
      submit: (e, data) ->
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        Utility.Upload.sanitize_file_names(data.files)

        $.ajax
          dataType: 'json'
          url: "/documents/new?attachmentable_type=" + attachmentable_type
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $('#file_upload').fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        tags = data.context.find("input.tags").val()
        attachmentable_type = data.context.find("input.attachmentable_type").val()
        attachmentable_id = data.context.find("input.attachmentable_id").val()
        attachmentable_key = data.context.find("input.attachmentable_key").val()
        data.context.attr('data-key', key)
        $.ajax
          url: "/attachments"
          type: 'POST'
          data: {
            attachment: {
              document_key: key,
              name: name,
              tags: tags,
              attachmentable_type: attachmentable_type,
              attachmentable_id: attachmentable_id,
              attachmentable_key: attachmentable_key
            }
          }
          cache: false
          dataType: 'script'
