window.wrapWithDataTable = (table, hideForm, sort = [[1,'asc']]) ->
  table = $(table).DataTable(
    iDisplayLength: 25
    lengthChange: false
    order: sort
    jQueryUI: true
  )

  if hideForm
    $('.fg-toolbar').addClass('hide-form-search')

  table
