window.Utility ||= {}

class Utility.Datatables
  @checkOrder = (order, size, defaultOrder) ->
    newOrder = order
    if order && (order.length > 0)
      count = 0
      while count < order.length
        if (order[count][0] > (size - 1))
          newOrder = defaultOrder;
          break;
        count += 1
    return newOrder

  @activityLogs = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['activity_logs-index-table-order']), $('#activity_logs-index-table').data('columns').length, [[0, 'desc']])
    catch e
      order = [[0, 'desc']]

    $("#activity_logs-index-table").DataTable(
      displayStart: parseInt(localStorage['activity_logs-index-table-display-start'] || 0)
      ajax: $('#activity_logs-index-table').data('source')
      order: order
      columns: $('#activity_logs-index-table').data('columns')
      serverSide: $('#activity_logs-index-table-orders-table').data('serverSide')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminDelayedJobs = ->
    $("#delayed-jobs-table").DataTable(
      order: [[0, 'desc']]
      ajax: $('#delayed-jobs-table').data('source')
      columns: [
        { title: 'ID', data: 'id', sortable: true, searchable: true }
        { title: 'Queue', data: 'queue', sortable: true, searchable: true }
        { title: 'Priority', data: 'priority', sortable: true, searchable: true }
        { title: 'Attempts', data: 'attempts', sortable: true, searchable: true }
        { title: 'Handler', data: 'handler', sortable: true, searchable: true }
        { title: 'Last Error', data: 'last_error', sortable: true, searchable: true }
        { title: 'Run At', data: 'run_at', sortable: true, searchable: true }
        { title: 'Locked At', data: 'locked_at', sortable: true, searchable: true }
        { title: 'Failed At', data: 'failed_at', sortable: true, searchable: true }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminDownloads = ->
    $("#downloads-table").DataTable(
      ajax: $('#downloads-table').data('source')
      serverSide: $('#downloads-table').data('serverSide')
      order: [[4, 'desc'], [0, 'asc']]
      columns: $('#downloads-table').data('columns')
      drawCallback: -> App.AdminDownloads.refreshIfProcessing()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminHints = ->
    $("#hints-table").DataTable(
      order: [[0, 'asc'], [1, 'asc']]
      ajax: $('#hints-table').data('source')
      columns: [
        { title: 'Screens', data: 'screens', sortable: true, searchable: true }
        { title: 'Priority', data: 'priority', sortable: true, searchable: true }
        { title: 'Target', data: 'target', sortable: true, searchable: true }
        { title: 'Title', data: 'title', sortable: true, searchable: true }
        { title: 'Body', data: 'body', sortable: true, searchable: true }
        { title: 'Status', data: 'status', sortable: true, searchable: true }
        { title: 'Highlight', data: 'highlight', sortable: true, searchable: true }
        { title: 'Max Width', data: 'highlight_max_width', sortable: true, searchable: true }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminOrganizationPruners = ->
    $("#organization-pruners-table").DataTable(
      order: [[0, 'desc']]
      ajax: $('#organization-pruners-table').data('source')
      columns: [
        { title: 'ID', data: 'id', sortable: true, searchable: true, className: "link" }
        { title: 'Organization', data: 'organization_name', sortable: true, searchable: true, className: "link" }
        { title: 'Started', data: 'started_at', sortable: true, searchable: true, className: "link" }
        { title: 'Current Operation', data: 'current_operation', sortable: true, searchable: false, className: "link hidden-sm-md" }
        { title: 'Completed', data: 'completed_at', sortable: true, searchable: false, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminOrganizations = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['organizations-table-order']), 12, [[0, 'desc']])
    catch e
      order = [[0, 'desc']]

    $("#organizations-table").DataTable(
      order: order
      ajax: $('#organizations-table').data('source')
      displayStart: parseInt(localStorage['organizations-table-display-start'] || 0)
      columns: $('#organizations-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminSubscriptions = ->
    $("#subscriptions-table").DataTable(
      ajax: $('#subscriptions-table').data('source')
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Price', data: 'price', sortable: true, searchable: true, className: "link", className: "text-right" }
        { title: 'Trial', data: 'trial', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminUserLicenseRequests = ->
    $("#user-license-requests-table").DataTable(
      ajax: $('#user-license-requests-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { title: 'Submitted', data: 'created_at', sortable: true, searchable: true }
        { title: 'Organization', data: 'organization_name', sortable: true, searchable: false }
        { title: 'Quantity', data: 'quantity', sortable: true, searchable: false }
        { title: 'User', data: 'user_name', sortable: true, searchable: false }
        { title: 'Processed At', data: 'processed_at', sortable: true, searchable: false }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminUserRequests = ->
    $("#user-requests-table").DataTable(
      ajax: $('#user-requests-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { title: 'Date', data: 'date', sortable: true, searchable: true }
        { title: 'Requests', data: 'requests', sortable: true, searchable: false }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @adminUsers = ->
    $("#users-table").DataTable(
      ajax: $('#users-table').data('source')
      serverSide: $('#users-table').data('serverSide')
      columns: [
        { title: 'ID', data: 'id', sortable: true, searchable: true, className: "link" }
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Email', data: 'filtered_email', sortable: true, searchable: true, className: "link" }
        { title: 'Organization', data: 'organization_name', sortable: true, searchable: true, className: "link" }
        { title: 'Status', data: 'status', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'Type', data: 'user_type', sortable: true, searchable: true, className: "link" }
        { data: {
            _: 'last_active_at'
            sort: 'last_active_at'
          }, title: 'Last Activity', sortable: true, searchable: false, className: "link hidden-sm" }
        { title: 'Requests', data: 'request_count', sortable: true, searchable: false }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @attachments = ->
    $("#attachments-table").DataTable(
      ajax: $('#attachments-table').data('source')
      order: [[0, 'desc']]
      columns: $('#attachments-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @bulkTaskImports = ->
    $("#imports-table").DataTable(
      ajax: $('#imports-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { title: 'ID', data: 'id', sortable: true, searchable: true }
        { title: 'Status', data: 'status', sortable: true, searchable: true }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @customFields = ->
    $("#custom_fields-table").DataTable(
      ajax: $('#custom_fields-table').data('source')
      order: [[0, 'asc'], [1, 'asc']]
      paging: false
      columns: [
        { data: 'display_order', visible: false, className: "link" }
        { title: 'Name', data: 'name', sortable: false, searchable: false, className: "link" }
        { title: 'Default', data: 'default', sortable: false, searchable: false, className: "link" }
        { title: 'Sort', data: 'sort_handle', className: "link" , sortable: false, searchable: false }
      ]
      infoCallback: (settings, start, end, max, total, pre) ->
        return $('#custom_fields-table').data('limit-message')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @customFilters = ->
    $("#custom-filters-table").DataTable(
      ajax: $('#custom-filters-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Description', data: 'sql_array', sortable: true, searchable: true, className: "link" }
        { title: '', data: 'apply', sortable: true, searchable: true, className: "link" }
        { title: '', data: 'delete', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @customMeterUnits = ->
    $("#custom_meter_units-table").DataTable(
      ajax: $('#custom_meter_units-table').data('source')
      order: [[0, 'asc']]
      paging: false
      columns: [
        { title: 'Name', data: 'name', sortable: false, searchable: false, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @customWorkOrderStatuses = ->
    $("#custom_work_order_statuses-table").DataTable(
      ajax: $('#custom_work_order_statuses-table').data('source')
      order: [[0, 'asc'], [1, 'asc']]
      paging: false
      columns: [
        { data: 'display_order', visible: false, className: "link" }
        { title: 'Name', data: 'name', sortable: false, searchable: false, className: "link" }
        { title: 'Read Only', data: 'locked', sortable: false, searchable: false, className: "link" }
        { title: 'Color', data: 'color', sortable: false, searchable: false, className: "link" }
        { title: 'Sort', data: 'sort_handle', className: "link", sortable: false, searchable: false }
      ]
      infoCallback: (settings, start, end, max, total, pre) ->
        return $('#custom_work_order_statuses-table').data('limit-message')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @equipmentDashboard = ->
    $("#equipment-dashboard-table").DataTable(
      ajax: $('#equipment-dashboard-table').data('source')
      order: [[1, 'asc'], [2, 'asc']]
      columns: $('#equipment-dashboard-table').data('columns')
      headerCallback: ( thead, data, start, end, display ) ->
        $(thead).find('th').slice(2).remove()
        $(thead).find('th').eq(1).attr('colspan', 6).html( $('#equipment-dashboard-filter-and-counts').show() )
        App.Equipments.prepareBulkOperations()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @equipments = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['equipment-index-table-order']), $('#equipment-index-table').data('columns').length, order = [[2, 'asc'], [3, 'asc']])
    catch e
      order = [[2, 'asc'], [3, 'asc']]

    $("#equipment-index-table").DataTable(
      displayStart: parseInt(localStorage['equipment-index-table-display-start'] || 0)
      ajax: $('#equipment-index-table').data('source')
      order: order
      columns: $('#equipment-index-table').data('columns')
      serverSide: $('#equipment-index-table').data('serverSide')
      drawCallback: ->
        localStorage['equipment-index-table-ids'] = JSON.stringify($('#equipment-index-table').DataTable().column(0, {search: 'applied'}).data().toArray())
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @gpsIntegrations = ->
    $("#gps-integrations-table").DataTable(
      ajax: $('#gps-integrations-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Provider', data: 'provider', sortable: true, searchable: true, className: "link" }
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Username', data: 'username', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'Password', data: 'secret', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'Meter Updates', data: 'meter_updates', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'Location Updates', data: 'location_updates', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'DTC Imports', data: 'dtc_imports', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'Last Attempted', data: 'last_attempted_at', sortable: true, searchable: true, className: "link hidden-sm" }
        { title: 'Last Refreshed', data: 'last_refreshed_at', sortable: true, searchable: true, className: "link hidden-sm" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @imports = ->
    $("#imports-table").DataTable(
      ajax: $('#imports-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { title: 'Submitted', data: {
            _: 'submitted'
            sort: 'submitted_sort'
          }, sortable: true, searchable: true }
        { title: 'Type', data: 'type', sortable: true, searchable: true }
        { title: 'ID', data: 'id', sortable: true, searchable: true }
        { title: 'Name', data: 'name', sortable: true, searchable: true }
        { title: 'Status', data: 'status', sortable: true, searchable: true }
      ]
      drawCallback: -> Utility.Imports.refreshIfDeleting()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inspections = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['inspections-table-order']), $('#inspections-table').data('columns').length, [[2, 'desc'], [3, 'asc']])
    catch e
      order = [[2, 'desc'], [3, 'asc']]

    $("#inspections-table").DataTable(
      ajax:
        url: $('#inspections-table').data('source')
        type:'POST'
      displayStart: parseInt(localStorage['inspections-table-display-start'] || 0)
      order: order
      columns: $('#inspections-table').data('columns')
      serverSide: $('#inspections-table').data('serverSide')
      drawCallback: ->
        localStorage['inspections-index-table-ids'] = JSON.stringify($('#inspections-table').DataTable().column(0, {search: 'applied'}).data().toArray())
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inspectionTemplates = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['inspection-templates-table-order']), 4, [[0, 'asc']])
    catch e
      order = [[0, 'asc']]

    $("#inspection-templates-table").DataTable(
      ajax: $('#inspection-templates-table').data('source')
      displayStart: parseInt(localStorage['inspection-templates-table-display-start'] || 0)
      order: order
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: 'link' }
        { title: 'Items', data: 'steps', sortable: true, searchable: true, className: 'link' }
        { title: 'Equipment', data: 'equipment_count', sortable: true, searchable: false, className: 'link' }
        { title: 'Actions', data: 'actions', sortable: false, searchable: false, className: "center-row-actions" }
      ]
      drawCallback: ->
        App.InspectionTemplates.refreshIfProcessing()
        Utility.Collapsable.prepare()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inspectionTemplateEquipment = ->
    $("#assigned-equipment-table").DataTable(
      order: [[1, 'asc']]
      columns: [
        { sortable: false, searchable: false }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
      ]
      scrollY: if Utility.FlyInPanel.flyInActive then false else '300px'
      scrollCollapse: true
      paging: false
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inventories = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['inventory-table-order']), $('#inventory-table').data('columns').length, [[2, 'asc']])
    catch e
      order = [[2, 'asc']]

    $("#inventory-table").DataTable(
      ajax: $('#inventory-table').data('source')
      order: order
      columns: $('#inventory-table').data('columns')
      displayStart: parseInt(localStorage['inventory-table-display-start'] || 0)
      drawCallback: ->
        App.Dashboard.prepareInventoryBulkOperations()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inventoriesDashboard = ->
    $("#inventory-dashboard-table").DataTable(
      ajax: $('#inventory-dashboard-table').data('source')
      order: [[2, 'asc']]
      columns: $('#inventory-dashboard-table').data('columns')
      drawCallback: ->
        App.Dashboard.prepareInventoryBulkOperations()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inventoryAttachments = ->
    initializeAttachmentDatatable()

  @issuances = ->
    $("#issuances-table").DataTable(
      ajax: $('#issuances-table').data('source')
      order: [[1, 'desc'], [0, 'desc']]
      columns: [
        { data: 'id', visible: false }
        { title: 'Date', data: {_: 'created_on', sort: 'created_on_sort'}, sortable: true, searchable: true }
        { title: 'Description', data: 'description', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'Quantity', data: 'quantity', sortable: true, searchable: true, className: "text-right" }
        { title: 'Unit Cost', data: 'unit_cost', sortable: true, searchable: true, className: "hidden-sm text-right" }
        { title: 'Equipment', data: 'equipment_name', sortable: true, searchable: true, className: "hidden-sm" }
        { title: 'WO', data: { _: 'work_order_prefix_title', sort: 'work_order_sequential_id', server_side_sort: 'work_order_sequential_id' }, sortable: true, searchable: true, className: "hidden-sm-md" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inventoryUsage = ->
    $("#inventory-usage-table").DataTable(
      ajax: $('#inventory-usage-table').data('source')
      order: [[1, 'desc'], [0, 'desc']]
      columns: [
        { data: 'id', visible: false }
        { title: 'Date', data: {_: 'issued_on', sort: 'issued_on_sort'}, sortable: true, searchable: true }
        { title: 'Description', data: 'description', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'Quantity', data: 'quantity', sortable: true, searchable: true, className: "text-right" }
        { title: 'Unit Cost', data: 'unit_cost', sortable: true, searchable: true, className: "hidden-sm text-right" }
        { title: 'Equipment', data: 'equipment_name', sortable: true, searchable: true, className: "hidden-sm text-right" }
        { title: 'WO', data: { _: 'work_order_prefix_title', sort: 'work_order_sequential_id', server_side_sort: 'work_order_sequential_id' }, sortable: true, searchable: true, className: "hidden-sm-md" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @jobSiteEquipments = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['job_site_equipments-table-order']), $('#job_site_equipments-table').data('columns').length, [[0, 'asc'], [1, 'desc']])
    catch e
      order = [[0, 'asc'], [1, 'desc']]

    $("#job_site_equipments-table").DataTable(
      ajax: $('#job_site_equipments-table').data('source')
      order: order
      columns: $('#job_site_equipments-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @jobSiteExpenses = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['job_site_expenses-table-order']), $('#job_site_expenses-table').data('columns').length, [[0, 'asc'], [1, 'desc']])
    catch e
      order = [[0, 'desc'], [1, 'asc']]

    $("#job_site_expenses-table").DataTable(
      ajax: $('#job_site_expenses-table').data('source')
      order: order
      columns: $('#job_site_expenses-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @locations = ->
    $("#locations-table").DataTable(
      ajax: $('#locations-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Tax Rate 1', data: 'default_tax_rate_one', sortable: true, searchable: true, className: "link" }
        { title: 'Tax Rate 2', data: 'default_tax_rate_two', sortable: true, searchable: true, className: "link" }
        { title: 'Labor Tax', data: 'tax_on_labor', sortable: true, searchable: true, className: "link" }
        { title: 'Actions', data: 'actions', sortable: false, searchable: false, className: "center-row-actions" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @maintenanceTemplateTasks = ->
    $("#maintenance-template-tasks-table").DataTable(
      order: [[1, 'asc']]
      columns: [
        { sortable: false, searchable: false }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
      ]
      drawCallback: ->
        App.MaintenanceTemplates.refreshTaskIfDeleting()
      paging: false
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @maintenanceTemplates = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['maintenance-templates-table-order']), 4, [[0, 'asc']])
    catch e
      order = [[0, 'asc']]

    $("#maintenance-templates-table").DataTable(
      ajax: $('#maintenance-templates-table').data('source')
      displayStart: parseInt(localStorage['maintenance-templates-table-display-start'] || 0)
      order: order
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Tasks', data: 'task_count', sortable: true, searchable: true, className: "link" }
        { title: 'Equipment', data: 'equipment_count', sortable: true, searchable: false, className: "link" }
        { title: 'Actions', data: 'actions', sortable: false, searchable: false, className: "center-row-actions" }
      ]
      drawCallback: -> App.MaintenanceTemplates.refreshIfProcessing()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @messages = ->
    $("#messages-table").DataTable(
      order: [[0, 'desc']]
      ajax: $('#messages-table').data('source')
      language:
        emptyTable: "No Messages yet"
      stripeClasses: [ 'message' ]
      fnDrawCallback: -> $("#messages-table thead").remove()
      paging: false
      columns: [
        { data: 'id', sortable: true, visible: false, searchable: false}
        { data: 'direction', sortable: false, searchable: true, visible: false }
        { data: 'body', sortable: false, searchable: true, className: 'body link' }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')


  @meterReplacements = ->
    $("#meter-replacements-index-table").DataTable(
      order: [[1, 'asc'], [2, 'desc']]
      pageLength: 100
      ajax: $('#meter-replacements-index-table').data('source')
      columns: $('#meter-replacements-index-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @meterUpdates = ->
    $("#meter-updates-index-table").DataTable(
      order: [[1, 'asc'], [2, 'desc']]
      pageLength: 100
      ajax: $('#meter-updates-index-table').data('source')
      columns: $('#meter-updates-index-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @reconciliation = ->
    $("#reconciliation-table").DataTable(
      order: [[0, 'asc']]
      pageLength: 25
      ajax: $('#reconciliation-table').data('source')
      columns: $('#reconciliation-table').data('columns')
      drawCallback: ->
        Utility.FormActions.newEditForm()
        $(@.api().cell(":eq(2)", {page:'current'}).node()).find("input").first().focus().select()
        $('#reconciliation-table td input').on 'change', ->
          $(@).addClass('changed')
        $('#reconciliation-table_paginate a').on 'click', ->
          if $('#reconciliation-table td input.changed').length > 0
            $("form#reconciliation-form").submit()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @newMeterUpdates = ->
    $("#new-meter-update-table").DataTable(
      order: [[0, 'asc']]
      pageLength: 25
      ajax: $('#new-meter-update-table').data('source')
      columns: $('#new-meter-update-table').data('columns')
      drawCallback: ->
        Utility.FormActions.newEditForm()
        App.MeterUpdates.disableInputs()
        $(@.api().cell(":eq(2)", {page:'current'}).node()).find("input").first().focus().select()
        $('#new-meter-update-table td input').on 'change', ->
          $(@).addClass('changed')
        $('#new-meter-update-table_paginate a').on 'click', ->
          if $('#new-meter-update-table td input.changed').length > 0
            $("form#meter-update-form").submit()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @bulkCommonPartAssignments = ->
    $("#bulk-common-part-assignments-table").DataTable(
      order: [[0, 'asc']]
      pageLength: 25
      ajax: $('#bulk-common-part-assignments-table').data('source')
      columns: $('#bulk-common-part-assignments-table').data('columns')
      drawCallback: ->
        Utility.FormActions.newEditForm()
        $(@.api().cell(":eq(2)", {page:'current'}).node()).find("input").first().focus().select()
        $('#bulk-common-part-assignments-table td input').on 'change', ->
          $(@).addClass('changed')
        $('#bulk-common-part-assignments-table_paginate a').on 'click', ->
          if $('#bulk-common-part-assignments-table td input.changed').length > 0
            $("form#bulk-common-part-assignment-form").submit()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @invoices = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['invoices-table-order']), $('#invoices-table').data('columns').length, [[2, 'asc']])
    catch e
      order = [[2, 'asc']]

    $("#invoices-table").DataTable(
      ajax: $('#invoices-table').data('source')
      order: order
      columns: $('#invoices-table').data('columns')
      displayStart: parseInt(localStorage['invoices-table-display-start'] || 0)
      drawCallback: ->
        localStorage['invoices-index-table-ids'] = JSON.stringify($('#invoices-table').DataTable().column(0, {search: 'applied'}).data().toArray())
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @partKitInventories = ->
    $("#part-kit-inventories-table").DataTable(
      order: [[0, 'asc']]
      columns: [
        { sortable: false, searchable: false }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
      ]
      paging: false
    )
    $('.fg-toolbar').addClass('hide-form-search')


  @partKits = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['part-kits-table-order']), 4, [[0, 'asc']])
    catch e
      order = [[0, 'asc']]

    $("#part-kits-table").DataTable(
      ajax: $('#part-kits-table').data('source')
      displayStart: parseInt(localStorage['part-kits-table-display-start'] || 0)
      order: order
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Parts', data: 'inventory_count', sortable: true, searchable: true, className: "link" }
        { title: 'Equipment', data: 'equipment_count', sortable: true, searchable: false, className: "link" }
        { title: 'Actions', data: 'actions', sortable: false, searchable: false, className: "center-row-actions" }
      ]
      drawCallback: -> App.PartKits.refreshIfProcessing()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @purchaseOrders = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['purchase-orders-table-order']), $('#purchase-orders-table').data('columns').length, [[0, 'asc']])
    catch e
      order = [[2, 'asc']]

    $("#purchase-orders-table").DataTable(
      ajax: $('#purchase-orders-table').data('source')
      order: order
      columns: $('#purchase-orders-table').data('columns')
      displayStart: parseInt(localStorage['purchase-orders-table-display-start'] || 0)
      drawCallback: ->
        localStorage['purchase_orders-index-table-ids'] = JSON.stringify($('#purchase-orders-table').DataTable().column(0, {search: 'applied'}).data().toArray())
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @purchaseOrdersDashboard = ->
    $("#purchase-orders-table").DataTable(
      ajax: $('#purchase-orders-table').data('source')
      order: [[0, 'desc']]
      columns: $('#purchase-orders-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @recalls = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['recall-index-table-order']), $('#recall-index-table').data('columns').length, order = [[2, 'asc'], [3, 'asc']])
    catch e
      order = [[2, 'asc'], [3, 'desc']]

    $("#recall-index-table").DataTable(
      displayStart: parseInt(localStorage['recall-index-table-display-start'] || 0)
      ajax: $('#recall-index-table').data('source')
      order: order
      columns: $('#recall-index-table').data('columns')
      serverSide: $('#recall-index-table').data('serverSide')
      drawCallback: ->
        localStorage['recall-index-table-ids'] = JSON.stringify($('#recall-index-table').DataTable().column(0, {search: 'applied'}).data().toArray())
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @receipts = ->
    $("#receipts-table").DataTable(
      ajax: $('#receipts-table').data('source')
      order: [[1, 'desc'], [0, 'desc']]
      columns: [
        { data: 'id', sortable: false, searchable: false, visible: false }
        { title: 'Date', data: { _: 'created_on', sort: 'created_on_sort' }, sortable: true, searchable: true }
        { title: 'Description', data: 'description', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'Received', data: 'quantity_received', sortable: true, searchable: true, className: "text-right" }
        { title: 'Remaining', data: 'quantity_remaining', sortable: true, searchable: true, className: "text-right" }
        { title: 'Unit Cost', data: 'unit_cost', sortable: true, searchable: true, className: "hidden-sm text-right" }
        { title: 'Vendors', data: { _: 'vendor_link', server_side_sort: 'vendor_name' }, sortable: true, searchable: true, className: 'link hidden-sm-md' },
        { title: 'PO', data: { _: 'purchase_order_prefix_title', sort: 'purchase_order_sequential_id', server_side_sort: 'purchase_order_sequential_id' }, sortable: true, searchable: true, className: "hidden-sm-md" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @laborLogs = ->
    $("#labor-logs-table").DataTable(
      jQueryUI: false
      filter: false
      paging: false
      ajax: $('#labor-logs-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { title: 'Started', data: 'started_at', sortable: true, searchable: true },
        { title: 'Completed', data: 'completed_at', sortable: true, searchable: true },
        { title: 'Hours', data: 'total_hours', sortable: true, searchable: true, className: 'hidden-sm' }
        { title: 'Actions', data: 'actions', sortable: false, searchable: false, className: "text-right" }
      ]
    )

  @repairAttachments = ->
    $("#repair-attachments-table").DataTable(
      jQueryUI: false
      filter: false
      paging: false
      ajax: $('#repair-attachments-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { data: 'updated_at', sortable: true, searchable: false, className: "hidden-sm" }
        { data: 'image_or_file', sortable: false, searchable: false }
      ]
    )

  @expenseAttachmentsViewOnly = ->
    initializeAttachmentDatatable(true, false, 'expense-attachments-table')

  @expenseAttachments = ->
    initializeAttachmentDatatable(false, true, 'expense-attachments-table')

  @fuelAttachmentsViewOnly = ->
    initializeAttachmentDatatable(true, false, 'fuel-attachments-table')

  @fuelAttachments = ->
    initializeAttachmentDatatable(false, true, 'fuel-attachments-table')

  @taskAttachments = ->
    $("#task-attachments-table").DataTable(
      jQueryUI: false
      filter: false
      paging: false
      ajax: $('#task-attachments-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: false }
        { title: 'Image', data: 'image_or_file', sortable: false, searchable: false, className: "hidden-sm" }
      ]
    )

  @tireHistoryAttachments = ->
    $("#tire-history-attachments-table").DataTable(
      jQueryUI: false
      filter: false
      paging: false
      ajax: $('#tire-history-attachments-table').data('source')
      order: [[0, 'desc']]
      columns: [
        { data: 'updated_at', sortable: true, searchable: false, className: "hidden-sm" }
        { data: 'image_or_file', sortable: false, searchable: false }
      ]
    )

  @repairNames = ->
    $("#repair-names-table").DataTable(
      ajax: $('#repair-names-table').data('source')
      order: [[1, 'asc']]
      columns: [
        { title: "<input id='select-all-items' type='checkbox'>", data: 'select', sortable: false, searchable: false, className: 'hidden-sm-md details-controls checkbox-control', required: true, print: false },
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Type', data: 'type', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @repairTypesColumns = ->
    [
      { data: 'id', searchable: false, visible: false },
      { title: "<input id='select-all-items' type='checkbox'>", data: 'select', sortable: false, searchable: false, className: 'hidden-sm-md details-controls checkbox-control', required: true, print: false },
      { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
    ]

  @repairTypes = ->
    $("#repair-types-table").DataTable(
      ajax: $('#repair-types-table').data('source')
      order: [[1, 'asc']]
      columns: @repairTypesColumns()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @inventoryCategories = ->
    $("#inventory-categories-table").DataTable(
      ajax: $('#inventory-categories-table').data('source')
      order: [[1, 'asc']]
      columns: [
        { title: "<input id='select-all-items' type='checkbox'>", data: 'select', sortable: false, searchable: false, className: 'hidden-sm-md details-controls checkbox-control', required: true, print: false },
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @reports = ->
    $("#report-table").DataTable(
      autoWidth: false
      ajax: $('#report-table').data('source')
      order: $('#report-table').data('order')
      scrollX: true
      scrollY: false
      columns: $('#report-table').data('columns')
      serverSide: $('#report-table').data('serverSide')
      drawCallback: (settings) ->
        if $('#report-table').data('group') and $(".text_search[data-table-id='report-table']").val() == ""
          api = @.api()
          rows = api.rows( {page:'current'} ).nodes()
          last = null

          if api.column('.group-by', {page:'current'} ).nodes() != undefined
            api.column('.group-by', {page:'current'} ).nodes().each (groupNode, i) ->
              group = $(groupNode).text()
              if last != group
                row = '<tr class="group">'
                group_set = false
                api.columns().iterator 'column', (context, index) ->
                  column = api.column(index)
                  if column.visible()
                    if $(column.header()).hasClass('subtotal')
                      row += '<td class="text-right">' + api.rows({page: 'current'}).data()[i][ api.settings()[0].aoColumns[index].data + '_subtotal' ] + '</td>'
                    else if !group_set
                      group_set = true
                      row += '<td>' + group + '</td>'
                    else
                      row += '<td></td>'
                row += "</tr>"
                $(rows).eq(i).before(row)
                last = group
                $('.dataTables_scrollFoot').show();
                $('#report-table').closest(".dataTables_scrollBody").height('inherit');
        else
          $('.dataTables_scrollFoot').hide();

      footerCallback: (row, data, start, end, display) ->
        api = @.api()
        api.columns('.total').iterator 'column', (context, index) ->
          column = api.column(index)
          if api.row(0).data() != undefined
            $( column.footer() ).html( api.row(0).data()[ api.table().settings()[0].aoColumns[index].data + '_total' ] )
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @jobSites = ->
    $("#job_sites-table").DataTable(
      ajax: $('#job_sites-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Type', data: 'job_site_type', sortable: true, searchable: true, className: "link" }
        { title: 'Begins', data: 'begins_on', sortable: true, searchable: true, className: "link" }
        { title: 'Ends', data: 'ends_on', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @scheduledReports = ->
    $("#scheduled-reports-table").DataTable(
      ajax: $('#scheduled-reports-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true }
        { title: 'Interval', data: 'report_interval', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'Schedule', data: 'schedule_interval', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'Due On', data: 'scheduled_at', sortable: true, searchable: false, className: "hidden-sm-md" }
        { title: 'CSV File?', data: 'csv_file', sortable: false, searchable: false }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @taskPartDefaultEquipment = ->
    $("#available-equipment-table").DataTable(
      order: [[1, 'asc']]
      columns: [
        { sortable: false, searchable: false }
        { sortable: true, searchable: true }
      ]
      paging: false
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @templateEquipment = ->
    $("#assigned-equipment-table").DataTable(
      order: [[1, 'asc']]
      columns: [
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
      ]
      scrollY: if Utility.FlyInPanel.flyInActive then false else '300px'
      scrollCollapse: true
      paging: false
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @templateEquipmentAdditions = ->
    $("#available-equipment-table").DataTable(
      ajax: $('#available-equipment-table').data('source')
      order: [[2, 'asc']]
      columns: $('#available-equipment-table').data('columns')
      serverSide: $('#available-equipment-table').data('serverSide')
      displayStart: 0
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @templateEquipmentRemovals = ->
    $("#remove-equipment-table").DataTable(
      order: [[1, 'asc']]
      columns: [
        { sortable: false, searchable: false }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
        { sortable: true, searchable: true }
      ]
      scrollY: if Utility.FlyInPanel.flyInActive then false else '300px'
      scrollCollapse: true
      paging: false
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @tires = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['tire-table-order']), $('#tire-table').data('columns').length, [[1, 'asc']])
    catch e
      order = [[1, 'asc']]

    $("#tire-table").DataTable(
      ajax: $('#tire-table').data('source')
      order: order
      columns: $('#tire-table').data('columns')
      displayStart: parseInt(localStorage['tire-table-display-start'] || 0)
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @tireHistories = ->
    $("#tire-histories-table").DataTable(
      ajax: $('#tire-histories-table').data('source')
      order: [[1, 'desc'], [0, 'desc']]
      columns: [
        { data: 'id', visible: false }
        { title: 'Completed', data: {_: 'completed_at', sort: 'completed_at_sort'}, sortable: true, searchable: true }
        { title: 'Service', data: 'service', sortable: true, searchable: true}
        { title: 'Equipment', data: 'equipment_name', sortable: true, searchable: true }
        { title: 'Meter', data: 'equipment_meter', sortable: true, searchable: true, className: "hidden-sm text-right" }
        { title: 'Axle', data: 'axle', sortable: true, searchable: true, className: "hidden-sm text-right" }
        { title: 'Position', data: 'position', sortable: true, searchable: true, className: "hidden-sm" }
        { title: 'Pressure', data: 'pressure', sortable: true, searchable: true, className: "hidden-sm" }
        { title: 'Depth', data: 'depth', sortable: true, searchable: true, className: "hidden-sm" }
        { title: 'Notes', data: 'notes', sortable: true, searchable: true, className: "hidden-sm" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @users = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['users-table-order']), $('#users-table').data('columns').length, [[0, 'asc']])
    catch e
      order = [[0, 'asc']]

    $("#users-table").DataTable(
      ajax: $('#users-table').data('source')
      order: order
      columns: $('#users-table').data('columns')
      displayStart: parseInt(localStorage['users-table-display-start'] || 0)
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @usersDashboard = ->
    $("#employee_renewals-table").DataTable(
      ajax: $('#employee_renewals-table').data('source')
      order: [[1, 'asc'],[4, 'asc']]
      columns: $('#employee_renewals-table').data('columns')
      drawCallback: ->
        App.Dashboard.prepareEmployeeRenewalOperations()
        App.Dashboard.prepareEmployeeRenewalBulkOperations()
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @userAttachments = ->
    initializeAttachmentDatatable()

  @jobsiteAttachments = ->
    initializeAttachmentDatatable()

  @customers = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['customer-table-order']), $('#customer-table').data('columns').length, [[1, 'asc']])
    catch e
      order = [[1, 'asc']]

    $("#customer-table").DataTable(
      ajax: $('#customer-table').data('source')
      order: order
      columns: $('#customer-table').data('columns')
      serverSide: $('#customer-table').data('serverSide')
      displayStart: parseInt(localStorage['customer-table-display-start'] || 0)
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @vendors = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['vendor-table-order']), $('#vendor-table').data('columns').length, [[1, 'asc']])
    catch e
      order = [[1, 'asc']]

    $("#vendor-table").DataTable(
      ajax: $('#vendor-table').data('source')
      order: order
      columns: $('#vendor-table').data('columns')
      serverSide: $('#vendor-table').data('serverSide')
      displayStart: parseInt(localStorage['vendor-table-display-start'] || 0)
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @warehouses = ->
    $("#warehouses-table").DataTable(
      ajax: $('#warehouses-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true }
        { title: 'Address', data: 'address', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'City', data: 'city', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'State/Prov', data: 'state_prov', sortable: true, searchable: true, className: "hidden-sm-md" }
        { title: 'Tax Rate 1', data: 'default_tax_rate_one', sortable: true, searchable: true }
        { title: 'Tax Rate 2', data: 'default_tax_rate_two', sortable: true, searchable: true }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @workflows = ->
    $("#workflows-table").DataTable(
      ajax: $('#workflows-table').data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
        { title: 'Type', data: 'type', sortable: true, searchable: true, className: "link" }
        { title: 'Active', data: 'active', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @workOrders = ->
    try
      order = Utility.Datatables.checkOrder(JSON.parse(localStorage['work-orders-table-order']), $('#work-orders-table').data('columns').length, [[2, 'desc']])
    catch e
      order = [[2, 'desc']]

    $("#work-orders-table").DataTable(
      ajax:
        url: $('#work-orders-table').data('source')
        type:'POST'
      displayStart: parseInt(localStorage['work-orders-table-display-start'] || 0)
      order: order
      columns: $('#work-orders-table').data('columns')
      serverSide: $('#work-orders-table').data('serverSide')
      drawCallback: ->
        localStorage['work_orders-index-table-ids'] = JSON.stringify($('#work-orders-table').DataTable().column(0, {search: 'applied'}).data().toArray())
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @workOrdersDashboard = ->
    $("#work-orders-dashboard-table").DataTable(
      ajax: $('#work-orders-dashboard-table').data('source')
      order: [[5, 'asc'], [0, 'desc']]
      columns: $('#work-orders-dashboard-table').data('columns')
    )
    $('.fg-toolbar').addClass('hide-form-search')

  @workOrderAttachments = ->
    initializeAttachmentDatatable()

  @viewOnlyAttachments = ->
    initializeAttachmentDatatable(true)

  @workOrderTypes = ->
    $("#work-order-types-table").DataTable(
      ajax: $('#work-order-types-table').data('source')
      order: [[1, 'asc']]
      columns: [
        { title: "<input id='select-all-items' type='checkbox'>", data: 'select', sortable: false, searchable: false, className: 'hidden-sm-md details-controls checkbox-control', required: true, print: false },
        { title: 'Name', data: 'name', sortable: true, searchable: true, className: "link" }
      ]
    )
    $('.fg-toolbar').addClass('hide-form-search')

  initializeAttachmentDatatable = (viewOnly = false, keyWords = true, tableName = 'attachments-table') ->
    if $.fn.DataTable.isDataTable("#" + tableName)
      $("#" + tableName).DataTable().destroy(true)

    $("#" + tableName).DataTable(
      jQueryUI: false
      filter: false
      paging: false
      ajax: $('#' + tableName).data('source')
      order: [[0, 'asc']]
      columns: [
        { title: 'Attachment', data: 'name', sortable: true, searchable: true }
        { title: 'Keywords', data: 'tags', sortable: true, searchable: true, visible: keyWords, className: "link hidden-sm" }
        { data: 'image_or_file', sortable: false, searchable: false, visible: !keyWords }
        { title: 'Actions', data: 'actions', sortable: false, searchable: false, visible: !viewOnly, className: "text-right link" }
      ]
    )
