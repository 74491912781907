window.App ||= {}
class App.BulkCommonPartAssignments extends App.Base

  new: =>
    Utility.Sidebar.refreshSideBar()
    Utility.Drawer.toggleDrawers()
    this.prepareTable()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.FormActions.newEditForm()


  prepareTable: =>
    Utility.Datatables.bulkCommonPartAssignments()
    Utility.FormActions.prepareIndexTable()
    $("#select-all-items").click ->
      $("input[name='equipments[equipment_ids][]']").prop('checked', $(@).prop('checked'))
