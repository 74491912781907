window.App ||= {}
class App.PartKitSelector extends App.Base

  @preparePartKitIdSelect = ->
    $("#part_kit_selector_part_kit_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/part_kits/' + $(@).val() + '/inventory_status', null, null, 'script'
      else
        $("#part-kit-inventory-status").html("")

