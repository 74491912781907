window.App ||= {}
class App.SubscriptionOptions extends App.Base

  index: =>
    createSession = (qty, product) ->
      'reset': true
      'products': [ {
        'path': product
        'quantity': qty
      } ]
      'paymentContact':
        'email': Utility.RailsVars.email
        'firstName': Utility.RailsVars.first_name
        'lastName': Utility.RailsVars.last_name
        'company': Utility.RailsVars.company
        'phone': Utility.RailsVars.phone
      'checkout': true
      'tags':
        'organization_id': Utility.RailsVars.organization_id

    $('a.subscription').click ->
      qty = $('input#user_licenses').val()
      product = $(@).data('product')

      if product != undefined
        fastspring.builder.push(createSession(qty, product))

    $('form.subscription').submit ->
      qty = $('input#user_licenses').val()
      product = $(@).data('product')

      if product != undefined
        fastspring.builder.push(createSession(qty, product))

  @orderCompleted = (orderId) ->
    $.post('/api/v2/fast_spring/orders', { order_id: orderId }, null, 'script')
