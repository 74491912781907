window.Utility ||= {}

class Utility.AccordionTab
  TABS_SELECTOR = '.tabs'
  FOOTER_SELECTOR = '.panel-footer.tab-switch'
  TABS_FLY_IN_SELECTOR = '.fly-in-form-content > .tabs'
  IS_OPEN = 'is-open'
  IS_ACTIVE = 'is-active'
  IS_ACTIVE_ON_FLY_IN = 'is-active-on-fly-in'

  @prepare = ->
    isActiveClass = ->
      if Utility.FlyInPanel.flyInActive then IS_ACTIVE_ON_FLY_IN else IS_ACTIVE

    showFirstTab = ->
      selector = if isActiveClass() == IS_ACTIVE_ON_FLY_IN then TABS_FLY_IN_SELECTOR else TABS_SELECTOR

      $(selector).each (index) ->
        firstTab = $(@).children("li").first()
        firstTabName = firstTab.data("tab-name")
        submitName = firstTab.data("submit-name")
        firstTab.children("a").addClass(isActiveClass())

        if $(selector).hasClass('nested-form')
          form = $(selector).children('form').first()
          tabContent = form.children(".tab-content[data-tab-name='#{firstTabName}']").first()
          tabContent.addClass(IS_OPEN).show()
        else if $(selector).hasClass('wrapper-content')
          tabContent = $(selector).siblings('.tab-content-wrapper').first().children(".tab-content[data-tab-name='#{firstTabName}']").first()
          tabContent.addClass(IS_OPEN).show()
        else
          tabContent = $(selector).children(".tab-content[data-tab-name='#{firstTabName}']").first()
          tabContent.addClass(IS_OPEN).show()

        if submitName
          $(FOOTER_SELECTOR).hide()
          $('#' + submitName + '-footer').show()

    showTab = (tab) ->
      selector = if isActiveClass() == IS_ACTIVE_ON_FLY_IN then TABS_FLY_IN_SELECTOR else TABS_SELECTOR
      accordionTabs = $(tab).closest(selector)
      if accordionTabs.hasClass('wrapper-content')
        accordionTabs.siblings('.tab-content-wrapper').first().find(".#{IS_OPEN}").removeClass(IS_OPEN).hide()
      else
        accordionTabs.find(".#{IS_OPEN}").removeClass(IS_OPEN).hide()
      tabName = $(tab).parent().data('tab-name')
      submitName = $(tab).parent().data("submit-name")

      if tabName
        window.location.hash = tabName
        history.replaceState({fmp: true}, "", window.location)
      else
        window.location.hash = ''

      if accordionTabs.hasClass('nested-form')
        form = accordionTabs.children('form').first()
        tabContent = form.children(".tab-content[data-tab-name='#{tabName}']").first()
      else if accordionTabs.hasClass('wrapper-content')
        tabContent = accordionTabs.siblings('.tab-content-wrapper').first().children(".tab-content[data-tab-name='#{tabName}']").first()
      else
        tabContent = $(selector).children(".tab-content[data-tab-name='#{tabName}']").first()

      tabContent.toggleClass(IS_OPEN).toggle()
      accordionTabs.find(".#{isActiveClass()}").removeClass(isActiveClass())
      $(tab).addClass(isActiveClass())

      if submitName
        $(FOOTER_SELECTOR).hide()
        $('#' + submitName + '-footer').show()

    setTimeout (->
      selector = if isActiveClass() == IS_ACTIVE_ON_FLY_IN then TABS_FLY_IN_SELECTOR else TABS_SELECTOR

      if window.location.hash.length > 1
        tabName = window.location.hash.substr(1)

        matchingTab = $(".tabs").find("[data-tab-name='#{tabName}']")
        if matchingTab.length > 0
          if $(matchingTab).find("a.tab-link").data("get") != undefined && $(matchingTab).find("a.tab-link").data("get").length > 0
            $.getScript($(matchingTab).find("a.tab-link").data("get"))
          showTab(matchingTab.find("a.tab-link"))
        else
          showFirstTab()
      else if $(selector).find(".#{isActiveClass()}").length > 0
        showTab($(selector).find(".#{isActiveClass()}"))
      else
        showFirstTab()
    ), 400

    accordion_selector = if (Utility.FlyInPanel.flyInActive && !Utility.FlyInPanel.flyInShouldClose) then TABS_FLY_IN_SELECTOR else TABS_SELECTOR

    $(accordion_selector).on "click.accordion-tab", "> li > a", (event) ->
      event.preventDefault()
      if $(this).data("submit") != undefined && $(this).data("submit").length > 0
        $($(@).data("submit")).submit()
      else if $(this).data("get") != undefined && $(this).data("get").length > 0
        $.getScript($(this).data("get"))
        showTab(this)
      else if !$(this).hasClass("is-active")
        showTab(this)
      #Utility.FlyInPanel.refreshButtonBar() if Utility.FlyInPanel.flyInActive
