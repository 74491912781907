window.App ||= {}
class App.PartKits extends App.Base

  index: =>
    Utility.Datatables.partKits()
    Utility.FormActions.prepareIndexTable()
    App.PartKits.refreshIfProcessing()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    this.prepareTable()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    this.prepareTable()
    Utility.FlyInPanel.prepare()

  prepareTable: =>
    Utility.Datatables.partKitInventories()
    Utility.FormActions.prepareIndexTable()

  @refreshIfProcessing = ->
    if $('span.update-pending').length
      setTimeout (->
        $('#part-kits-table').DataTable().ajax.reload()
        return
      ), 5000
