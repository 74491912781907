window.App ||= {}
class App.Accidents extends App.Base

  index: =>

  show: =>

  new: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    Utility.SelectizeFields.prepare()
    Utility.Datepicker.prepare()
    Utility.MeterValidation.preparePrimary()
    this.prepareEquipmentIdSelect()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    Utility.SelectizeFields.prepare()
    Utility.Datepicker.prepare()
    Utility.Datatables.workOrderAttachments()
    Utility.MeterValidation.preparePrimary()
    App.Attachments.fileUpload()
    Utility.FlyInPanel.prepare()

  prepareEquipmentIdSelect: =>
    $("#accident_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/accidents/new', {
          equipment_id: $(@).val()
          save_type: $('#save-and-button').attr('name')
        }, null, 'script'
    if parseInt($("#accident_equipment_id").val()) > 0
      Utility.EquipmentRequired.enable()
      $('#accident_accident_at').focus();
    else
      Utility.EquipmentRequired.disable()
      $('#accident_equipment_id')[0].selectize.focus();

  prepareEdit: =>
    if parseInt($("#accident_equipment_id").val()) > 0
      $('#accident_accident_at')[0].selectize.focus();
    else
      $('#accident_equipment_id')[0].selectize.focus();
