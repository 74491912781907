window.App ||= {}
class App.AdminHints extends App.Base

  index: =>
    Utility.Datatables.adminHints()
    Utility.FormActions.prepareIndexTable()

  edit: =>
    Utility.SelectizeFields.prepare()

  new: =>
    Utility.SelectizeFields.prepare()
    Utility.FlyInPanel.prepare()
