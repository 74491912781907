window.App ||= {}
class App.AdminMonthlySummaries extends App.Base

  index: =>
    this.prepareCharts()

  prepareCharts: =>
    $(".chart").each (index, element) ->
      defaults =
        credits: false,
        plotOptions:
          series:
            point:
              events:
                click: ->
                  if @.options.url
                    location.href = @.options.url
        title:
          style:
            fontSize: '16px'
      $(@).highcharts($.extend(true, {}, defaults, $(@).data('chart-data')))
