window.App ||= {}
class App.Users extends App.Base
  EMAIL_REGEX = /^[^\s@#!]+@[^\s@.#!]+\.[^\s@]+$/

  index: =>
    window.scanContext = 'Equipment'
    Utility.Datatables.users()
    Utility.FormActions.prepareIndexTable(Utility.BetaFeatures.enabled())
    Utility.HelpPanel.prepare()
    App.Users.prepareBulkOperations()

  show: =>
    wrapWithDataTable('#user-renewals-show-table', true, [[0,'desc']]);
    App.Attachments.fileUpload()
    Utility.Datatables.userAttachments()
    Utility.FormActions.prepareIndexTable()
    Utility.AccordionTab.prepare()
    $("html, body").scrollTop(0)

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    this.toggleNotificationControls()
    this.toggleUserActivationControls()
    this.selectAllToggles()
    App.CustomFields.showOrHideAddLink()
    Utility.AccordionTab.prepare()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.Toggle.prepare()
    this.toggleNotificationControls()
    this.toggleUserActivationControls()
    this.selectAllToggles()
    App.CustomFields.showOrHideAddLink()
    Utility.AccordionTab.prepare()
    Utility.FlyInPanel.prepare()

  toggleNotificationControls: =>
    if $('#user_notifications_notify_maintenance_due_by_location').prop("checked")
      $('#user_notifications_notify_maintenance_due_by_equipment_assignee').attr("disabled", true)

    if $('#user_notifications_notify_work_orders_by_location').prop("checked")
      $('#user_notifications_notify_work_orders_by_equipment_assignee').attr("disabled", true)
      $('#user_notifications_notify_work_orders_by_work_order_assignee').attr("disabled", true)

    $('#user_notifications_notify_maintenance_due_by_location').on 'change', (e, active) ->
      if $(@).prop("checked")
        $('#user_notifications_notify_maintenance_due_by_equipment_assignee').prop("checked", true).attr("disabled", true)
      else
        $('#user_notifications_notify_maintenance_due_by_equipment_assignee').attr("disabled", false)

    $('#user_notifications_notify_work_orders_by_location').on 'change', (e, active) ->
      if $(@).prop("checked")
        $('#user_notifications_notify_work_orders_by_equipment_assignee').prop("checked", true).attr("disabled", true)
        $('#user_notifications_notify_work_orders_by_work_order_assignee').prop("checked", true).attr("disabled", true)
      else
        $('#user_notifications_notify_work_orders_by_equipment_assignee').attr("disabled", false)
        $('#user_notifications_notify_work_orders_by_work_order_assignee').attr("disabled", false)


  @emailLabel = ->
    $('.user_email_or_username').find('label').html("<abbr title='required'>*</abbr> Email")
    $(".input.user_new_password").hide()
    $("#user_new_password").prop("readonly", true)

  @usernameLabel = ->
    $('.user_email_or_username').find('label').html("<abbr title='required'>*</abbr> Email or Username")
    App.Users.passwordInputDisplay()

  @passwordInputDisplay = ->
    string = $("input#user_email_or_username").val()
    if EMAIL_REGEX.test(string)
      $(".input.user_new_password").hide()
      $("#user_new_password").prop("readonly", true)
    else
      $(".input.user_new_password").show()
      $("#user_new_password").prop("readonly", false)


  @standardUserNotifications = ->
    $('#email-notification-toggle').show()
    $('.user_notifications_notify_maintenance_due_by_location').show()
    $('.user_notifications_notify_renewals_by_location').show()
    $('.user_notifications_notify_work_orders_by_location').show()
    $('.user_notifications_notify_work_orders_by_work_order_assignee').show()
    $('.user_notifications_notify_over_budget_by_location').show()
    $('#other-notifications').show()

  @limitedUserNotifications = ->
    $('#email-notification-toggle').hide()
    $('.user_notifications_notify_maintenance_due_by_location').hide()
    $('.user_notifications_notify_renewals_by_location').hide()
    $('.user_notifications_notify_work_orders_by_location').hide()
    $('.user_notifications_notify_work_orders_by_work_order_assignee').hide()
    $('.user_notifications_notify_over_budget_by_location').hide()
    $('#other-notifications').hide()

  selectAllToggles: =>
    $('#select-all-locations').prop("checked", $('input[name="user[location_ids][]"]:not(:checked)').length == 1)
    $('#select-all-warehouses').prop("checked", $('input[name="user[warehouse_ids][]"]:not(:checked)').length == 1)

    $('#select-all-locations').change ->
      $('input[name="user[location_ids][]"]').prop("checked", $(@).prop("checked"))
    $('#select-all-warehouses').change ->
      $('input[name="user[warehouse_ids][]"]').prop("checked", $(@).prop("checked"))

  @updateLoginFields = (user_type) ->
    if user_type == 'Admin'
      $('.limit-section').fadeOut()
      $('#user-permissions').fadeOut()
      $('#view-work-orders').fadeOut()
      $('#view-equipment').fadeOut()
      $('#view-inspections').fadeOut()
      $('#warehouse-selection').fadeIn()
      App.Users.standardUserNotifications()
      App.Users.emailLabel()
    else if user_type == 'Mobile'
      $('.standard-user').fadeOut()
      $('.limit-section').fadeIn()
      $('#user-permissions').fadeIn()
      $('#warehouse-selection').fadeIn()
      $('#view-work-orders').fadeOut()
      $('#view-equipment').fadeIn()
      $('#view-inspections').fadeIn()
      App.Users.limitedUserNotifications()
      App.Users.usernameLabel()
    else if user_type == ''
      $('.standard-user').fadeOut()
      $('.limit-section').fadeOut()
      $('#user-permissions').fadeOut()
      $('#warehouse-selection').fadeIn()
      $('#view-work-orders').fadeOut()
      $('#view-equipment').fadeOut()
      $('#view-inspections').fadeOut()
      App.Users.limitedUserNotifications()
      App.Users.usernameLabel()
    else
      $('.standard-user').fadeIn()
      $('.limit-section').fadeIn()
      $('#user-permissions').fadeIn()
      $('#warehouse-selection').fadeIn()
      $('#view-work-orders').fadeIn()
      $('#view-equipment').fadeIn()
      $('#view-inspections').fadeOut()
      App.Users.standardUserNotifications()
      App.Users.emailLabel()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()

    $("#bulk-edit-employees").off('click').click ->
      employee_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/bulk_edit_employees/new', { bulk_edit_employee: { employee_ids: employee_ids }}, null, 'script'

  toggleUserActivationControls: =>
    ACTIVE_FIELDS = '.active-user-fields'
    ALLOW_LOGIN_FIELD = '#user_allow_login'
    EMAIL_NOTIFICATION_FIELD = '#user_email_notifications'
    PUSH_NOTIFICATION_FIELD = '#user_push_notifications'
    ASSIGNED_WORK_ORDERS_ONLY_FIELD = '#user_assigned_work_orders_only'
    ASSIGNED_INSPECTIONS_ONLY_FIELD = '#user_assigned_inspections_only'
    ASSIGNED_EQUIPMENT_ONLY_FIELD = '#user_assigned_equipment_only'
    TECH_FIELD = '#user_tech'

    if $('.toggle-allow-login').data('toggles') && !$('.toggle-allow-login').data('toggles').active
      $(ACTIVE_FIELDS).hide()
      $(EMAIL_NOTIFICATION_FIELD).prop("readonly", true)
    else
      $(EMAIL_NOTIFICATION_FIELD).prop("readonly", false)

    $('.toggle-allow-login').on 'toggle', (e, active) ->
      if (active)
        $(ALLOW_LOGIN_FIELD).val('t')
        $(ACTIVE_FIELDS).fadeIn()
        $(EMAIL_NOTIFICATION_FIELD).prop("readonly", false)
      else
        $(ALLOW_LOGIN_FIELD).val('f')
        $(ACTIVE_FIELDS).fadeOut()
        $(EMAIL_NOTIFICATION_FIELD).prop("readonly", true)
      App.Users.updateLoginFields($('#user_type').val())

    $('.toggle-email-notifications').on 'toggle', (e, active) ->
      if (active)
        $(EMAIL_NOTIFICATION_FIELD).val('t');
      else
        $(EMAIL_NOTIFICATION_FIELD).val('f');

    $('.toggle-push-notifications').on 'toggle', (e, active) ->
      if (active)
        $(PUSH_NOTIFICATION_FIELD).val('t');
      else
        $(PUSH_NOTIFICATION_FIELD).val('f');

    $('.toggle-assigned-work-orders-only').on 'toggle', (e, active) ->
      if (active)
        $(ASSIGNED_WORK_ORDERS_ONLY_FIELD).val('t');
        $('#no-assigned-work-orders').fadeIn();
      else
        $(ASSIGNED_WORK_ORDERS_ONLY_FIELD).val('f');
        $('#no-assigned-work-orders').fadeOut();


    $('.toggle-assigned-inspections-only').on 'toggle', (e, active) ->
      if (active)
        $(ASSIGNED_INSPECTIONS_ONLY_FIELD).val('t');
        $('#no-assigned-inspections').fadeIn();
      else
        $(ASSIGNED_INSPECTIONS_ONLY_FIELD).val('f');
        $('#no-assigned-inspections').fadeOut();

    $('.toggle-assigned-equipment-only').on 'toggle', (e, active) ->
      if (active)
        $(ASSIGNED_EQUIPMENT_ONLY_FIELD).val('t');
        $('#no-assigned-equipment').fadeIn();
      else
        $(ASSIGNED_EQUIPMENT_ONLY_FIELD).val('f');
        $('#no-assigned-equipment').fadeOut();

    $('.toggle-tech').on 'toggle', (e, active) ->
      if (active)
        $(TECH_FIELD).val('t');
      else
        $(TECH_FIELD).val('f');

    App.Users.updateLoginFields($('#user_type').val());

    $("#user_type").on 'change', ->
      if $(@).val() == 'Admin'
        $('.limit-section').fadeOut()
        $('#user-permissions').fadeOut()
        $('#view-work-orders').fadeOut()
        $('#view-equipment').fadeOut()
        $('#view-inspections').fadeOut()
        $('#warehouse-selection').fadeIn()
        App.Users.standardUserNotifications()
        App.Users.emailLabel()
      else if $(@).val() == 'Mobile'
        $('.standard-user').fadeOut()
        $('.limit-section').fadeIn()
        $('#user-permissions').fadeIn()
        $('#warehouse-selection').fadeOut()
        $('#view-work-orders').fadeOut()
        $('#view-equipment').fadeIn()
        $('#view-inspections').fadeIn()
        App.Users.limitedUserNotifications()
        App.Users.usernameLabel()
      else if $(@).val() == ''
        $('.standard-user').fadeOut()
        $('.limit-section').fadeOut()
        $('#user-permissions').fadeOut()
        $('#warehouse-selection').fadeOut()
        $('#view-work-orders').fadeOut()
        $('#view-equipment').fadeOut()
        $('#view-inspections').fadeOut()
        App.Users.limitedUserNotifications()
        App.Users.usernameLabel()
      else
        $('.standard-user').fadeIn()
        $('.limit-section').fadeIn()
        $('#user-permissions').fadeIn()
        $('#warehouse-selection').fadeIn()
        $('#view-work-orders').fadeIn()
        $('#view-equipment').fadeIn()
        $('#view-inspections').fadeOut()
        App.Users.standardUserNotifications()
        App.Users.emailLabel()

    $("[data-toggle-class]").on 'click', ->
      cb = $("input." + $(@).data('toggle-class'))
      cb.prop("checked", !cb.prop("checked"))
      if $(@).data('toggle-class') == 'delete' && cb.prop("checked")
        $("input.other").prop("checked", true)
        $("input.create").prop("checked", true)
        $("input.edit").prop("checked", true)
      else if $(@).data('toggle-class') != 'other' && $(@).data('toggle-class') != 'delete' && !cb.prop("checked")
        $("input.delete").prop("checked", false)
      else if $(@).data('toggle-class') != 'other' && cb.prop("checked")
        $("input.other").prop("checked", true)
      else if $(@).data('toggle-class') == 'other' && !cb.prop("checked")
        $("input.create").prop("checked", false)
        $("input.edit").prop("checked", false)
        $("input.delete").prop("checked", false)

    $("input.delete").on 'change', ->
      if $(@).prop("checked")
        $("input[data-object='" + $(@).data('object') + "']").prop("checked", $(@).prop("checked"))

    $("input.create, input.edit").on 'change', ->
      if !$(@).prop("checked")
        $("input." + $(@).data('object') + ".complete").prop("checked", false)
        $("input." + $(@).data('object') + ".delete").prop("checked", false)
      else
        $("input." + $(@).data('object') + ".complete").prop("checked", true)
        $("input." + $(@).data('object') + ".other").prop("checked", true)

    if $("input#user_email_or_username").length > 0 && $("input#user_email_or_username").val().length > 0
      if $("#user_type").val() == 'Mobile'
        App.Users.passwordInputDisplay()

    $("input#user_email_or_username").on 'keyup', ->
      if $("#user_type").val() == 'Mobile'
        App.Users.passwordInputDisplay()

    $("a#read-only-profile-link").on 'click', ->
      $("input.view, input.complete, input.create, input.edit, input.delete").prop("checked", false)
      $("input.equipment.view").prop("checked", true)

    $("a#superuser-profile-link").on 'click', ->
      $("input.view, input.complete, input.create, input.edit, input.delete").prop("checked", true)

    $("a#operator-profile-link").on 'click', ->
      $("input.view, input.complete, input.create, input.edit, input.delete").prop("checked", false)
      $("input.equipment.view").prop("checked", true)
      $("input.attachment.view").prop("checked", true)
      $("input.accident.create, input.accident.edit").prop("checked", true)
      $("input.repair.create, input.repair.edit").prop("checked", true)
      $("input.meter_update.create, input.meter_update.edit").prop("checked", true)
      $("input.fuel_entry.create, input.fuel_entry.edit").prop("checked", true)
      $("input.fluid_consumption.create, input.fluid_consumption.edit").prop("checked", true)
      $("input.expense.create, input.expense.edit").prop("checked", true)
      $("input.inspection.view, input.inspection.create, input.inspection.edit").prop("checked", true)
      $("input.equipment_attachment.view, input.equipment_attachment.create, input.equipment_attachment.edit").prop("checked", true)
      $("input.equipment_assignment.create, input.equipment_assignment.edit").prop("checked", true)

    $("a#restore-default-link").on 'click', ->
      $("input.view, input.complete").prop("checked", true)
      $("input.maintenance_template").prop("checked", false)
      $("input.repair_name").prop("checked", false)
      $("input.report").prop("checked", false)
      $("input.user").prop("checked", false)
      $("input.work_order_type").prop("checked", false)
      $("input.create, input.edit, input.delete").prop("checked", false)
      $("input.equipment_assignment.create, input.equipment_assignment.edit").prop("checked", true)
      $("input.expense.create, input.expense.edit").prop("checked", true)
      $("input.repair.create, input.repair.edit").prop("checked", true)
      $("input.meter_update.create, input.meter_update.edit").prop("checked", true)
      $("input.fuel_entry.create, input.fuel_entry.edit").prop("checked", true)
      $("input.inspection.create, input.inspection.edit").prop("checked", true)
      $("input.job_site.create, input.job_site.edit").prop("checked", true)

    $(document).off('cocoon:after-insert').on 'cocoon:after-insert', (e, insertedItem) ->
      Utility.Datepicker.prepare()
      Utility.SelectizeFields.prepare()
      $("#user-renewals-table thead").show()
      $("#user-renewals-table tbody tr:last-child td:first-child input").focus()

    $(document).off('cocoon:after-remove').on 'cocoon:after-remove', (e, insertedItem) ->
      if $("#user-renewals-table tbody tr").length == 0
        $("#user-renewals-table thead").hide()
