window.Utility ||= {}

class Utility.BulkOperations
  @prepare = ->
    initializeHeader = ->
      $('.index-table').addClass('multi-select')
      thead = $('.index-table').DataTable().table().header()
      columns = $(thead).find('th').length

      $(thead).find('th').slice(1).hide()
      $(thead).find('tr').append("<th class='multi-select'></th>")

      colspan = $(thead).find('th').eq(1).attr('colspan')
      if colspan > 1
        $(thead).find('th').eq(columns).attr('colspan', colspan)
      else
        $(thead).find('th').eq(columns).attr('colspan', columns - 1)

      $(thead).find('th').eq(columns).html( $('#multi-select-actions').clone(true, true).show() )

    $('#multi-select-actions').hide()

    $("#select-all-items").click ->
      $("input[name='select_item[]']").prop('checked', $(@).prop('checked'))
      if $(@).prop('checked') && $("input[name='select_item[]']").length > 0
        initializeHeader()
        $('.bulk-delete-all.hidden').removeClass('hidden')
      else
        Utility.BulkOperations.revertHeader()

    $(".index-table").on 'click', "input[name='select_item[]']", ->
      if $("input[name='select_item[]']").not(':checked').length > 0
        $('.bulk-delete-all').addClass('hidden')
      else if $("input[name='select_item[]']").not(':checked').length == 0
        $('.bulk-delete-all.hidden').removeClass('hidden')
      if $("input[name='select_item[]']:checked").length > 0
        initializeHeader()
      else
        Utility.BulkOperations.revertHeader()

    $("#hide-multi-select-actions").click ->
      Utility.BulkOperations.cancel()

  @cancel = ->
    Utility.BulkOperations.revertHeader()
    $("#select-all-items").prop('checked', false)
    $("input[name='select_item[]']").prop('checked', false)

  @revertHeader = ->
    $('.index-table').removeClass('multi-select')
    $('.bulk-delete-all').addClass('hidden')
    thead = $('.index-table').DataTable().table().header()
    $(thead).find('th.multi-select').remove()
    $(thead).find('th').slice(1).show()
