window.App ||= {}
class App.AdminDownloads extends App.Base

  index: =>
    Utility.Datatables.adminDownloads()
    Utility.FormActions.prepareIndexTable()
    App.Downloads.refreshIfProcessing()

  @refreshIfProcessing = ->
    if $('span.update-pending').length
      setTimeout (->
        $('#downloads-table').DataTable().ajax.reload()
        return
      ), 5000
