window.Utility ||= {}

class Utility.Dropup
  @prepare = ->
    $(document).on 'click', '.dropup .show-hidden', ->
      $(@).children('.sub-menu').toggleClass('link-is-open')
      window.current_tap = 1

    $(document).on 'click', 'ul.dropup .sub-menu a', ->
      $('.dropup .current-list .button').val($(@).text())
      $('.current-list input').attr('name', 'submit_' + $(@).attr('data-method').toLowerCase())
      $('.current-list input').attr('id', $(@).attr('data-method').toLowerCase())
      $( ":submit" ).click();

    $(document).on 'click', 'html', ->
      if window.current_tap > 1
        window.current_tap = 0
        $('.dropup .sub-menu.link-is-open').removeClass('link-is-open')
      else if window.current_tap == 1
        window.current_tap = window.current_tap + 1

    $(document).keyup (e) ->
      if e.keyCode == 27
        $('.dropup .sub-menu.link-is-open').removeClass('link-is-open')
