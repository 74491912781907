window.App ||= {}
class App.InventoryCategories extends App.Base

  index: =>
    Utility.Datatables.inventoryCategories()
    Utility.FormActions.prepareIndexTable()
    App.InventoryCategories.prepareBulkOperations()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-delete-inventory-categories").click ->
      inventory_category_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.ajax
        url: "/inventory_categories/bulk_delete"
        type: 'POST'
        data: {
          _method: "DELETE"
          inventory_category_ids: inventory_category_ids
        }
        cache: false
        dataType: 'script'
