window.App ||= {}
class App.Inspections extends App.Base

  index: =>
    window.scanContext = 'Inspection'
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.inspections()
    Utility.FormActions.prepareIndexTable()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.HelpPanel.prepare()
    App.Inspections.prepareBulkOperations()
    $('.scan-lookup').click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanInspection()
      else
        ScanInterface.scanInspection()

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareEquipmentIdSelect()
    this.prepareTemplateIdSelect()
    this.prepareMeterCheck()
    Utility.Signatures.prepare()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.Datatables.viewOnlyAttachments()
    Utility.Collapsable.prepare()
    this.prepareMeterCheck()
    this.hideSignatureBox()
    this.prepareToggleButtons()
    App.Pictures.fileUploadInspection()
    Utility.Signatures.prepare()
    this.prepareSubmit()

  show: =>
    this.prepareToggleButtons()
    Utility.Signatures.prepare()
    $("#popup").hide();

  edit_pending: =>
    Utility.FormActions.newEditForm()
    this.prepareMeterCheck()
    this.hideSignatureBox()
    Utility.Signatures.prepare()
    $("html, body").scrollTop(0)

  prepareMeterCheck: =>
    Utility.MeterValidation.preparePrimary()
    Utility.MeterValidation.prepareSecondary()

  prepareEquipmentIdSelect: =>
    $("#inspection_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/inspections/new', {
          equipment_id: $(@).val()
        }, null, 'script'
    if parseInt($("#inspection_equipment_id").val()) > 0
      Utility.EquipmentRequired.enable()
      if $('#inspection_user_ids').length > 0
        $('#inspection_user_ids')[0].selectize.focus();
      else
        $('#inspection_current_primary_meter').focus();
    else
      Utility.EquipmentRequired.disable()
      $('#inspection_equipment_id')[0].selectize.focus();

  prepareTemplateIdSelect: =>
    $("#inspection_inspection_template_id").change ->
      if parseInt($(@).val()) > 0 && $("#inspection_equipment_id").val() > 0
        $.get '/inspections/new', {
          equipment_id: $("#inspection_equipment_id").val(),
          inspection_template_id: $(@).val()
        }, null, 'script'

  prepareToggleButtons: =>
    $("button.toggle-group").click ->
      if $(@).hasClass('btn-blue-primary')
        $(@).removeClass('btn-blue-primary').addClass('secondary-action')
        $('#' + $(@).data('control')).prop('checked', false)
      else
        $("button.toggle-group").removeClass('btn-blue-primary').addClass('secondary-action').each ->
          $('#' + $(@).data('control')).prop('checked', false)
        $(@).removeClass('secondary-action').addClass('btn-blue-primary')
        $('#' + $(@).data('control')).prop('checked', true)
    $("button.toggle-group").each ->
      if $('#' + $(@).data('control')).prop('checked')
        $(@).removeClass('secondary-action').addClass('btn-blue-primary')

  hideSignatureBox: =>
    $('.signature.hidden').hide()

  prepareSubmit: =>
    $('form.edit-inspection-form.parsley').parsley().subscribe 'parsley:form:error', ->
      if $(".parsley-required").length
        $.scrollTo(".parsley-required:first-of-type", 100, { offset: -300 })
      else
        $("html, body").scrollTop(0)

  @updateToggles = ->
    if $('input.inspection-step-result[value="1"]').length == $('input.inspection-step-result').length
      $("#condition-satisfactory").prop('disabled', false).click()
      $("#defects-corrected").prop('disabled', true)
      $("#correction-not-needed").prop('disabled', true)
      $("#inspection_defects_corrected").prop('checked', false)
      $("#inspection_defects_need_not_be_corrected").prop('checked', false)
    else
      $("#condition-satisfactory").removeClass('btn-blue-primary').addClass('secondary-action').prop('disabled', true)
      $("#inspection_condition_satisfactory").prop('checked', false)
      $("#defects-corrected").prop('disabled', $("#defects-corrected").hasClass('user-limited'))
      $("#correction-not-needed").prop('disabled', $("#defects-corrected").hasClass('user-limited'))

  @scanKey = (scanKey) ->
    $.ajax
      url: "/inspections/new"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey),
        show_fly_in: true
      }
      cache: false
      dataType: 'script'

  @checkMeters = (equipmentId, completedOn, primaryMeter, secondaryMeter) ->
    if (parseInt(equipmentId) > 0) && (App.Inspections.validMeter(primaryMeter) || App.Inspections.validMeter(secondaryMeter)) && (completedOn != '')
      url = "/equipment/"+equipmentId+"/inspections/check_meter?completed_on=" + completedOn
      if App.Inspections.validMeter(primaryMeter)
        url += '&primary_meter=' + primaryMeter
      if App.Inspections.validMeter(secondaryMeter)
        url += '&secondary_meter=' + secondaryMeter

      $.ajax
        url: url
        data: null
        dataType: 'script'

  @validMeter = (meter) ->
    return (meter != undefined) && (meter != '')

  @selectedIds = ->
    $("input[name='select_item[]']:checked").map ->
        return this.value
      .get()

  @submitForm = (form_name, submenu_id) ->
    inspection_ids = App.Inspections.selectedIds()
    form = $(form_name + "[data-submenu-id='" + submenu_id + "']")
    form.find("#draw").val(0)
    form.find("#ids").val(inspection_ids)
    form.submit()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-print-inspections").click ->
      inspection_ids = App.Inspections.selectedIds()
      window.open("/inspections/bulk_print?inspection_ids=" + inspection_ids, '_blank')

    $("#bulk-print-table-inspections").click ->
      App.Inspections.submitForm('form#download-pdf', $(@).attr('data-submenu-id'))

    $("#bulk-export-inspections").click ->
      App.Inspections.submitForm('form#download-csv', $(@).attr('data-submenu-id'))
