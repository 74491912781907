# https://github.com/szimek/signature_pad

window.Utility ||= {}

class Utility.Signatures
  signatureCanvases = {}
  signaturePads = {}

  @prepare = ->
    $(".signature-pad").each (index) ->
      id = $(@).data('signature-id')
      signatureCanvases[id] = $(@).find('canvas')[0]

      if signatureCanvases[id] != undefined
        signaturePads[id] = new SignaturePad(
          signatureCanvases[id],
          backgroundColor: 'rgb(255, 255, 255)'
        )

        resizeCanvas = (id) ->
          data = signaturePads[id].toData()
          # When zoomed out to less than 100%, for some very strange reason,
          # some browsers report devicePixelRatio as less than 1
          # and only part of the canvas is cleared then.
          ratio = Math.max(window.devicePixelRatio or 1, 1)
          # This part causes the canvas to be cleared
          signatureCanvases[id].width = signatureCanvases[id].offsetWidth * ratio
          signatureCanvases[id].height = signatureCanvases[id].offsetHeight * ratio
          signatureCanvases[id].getContext('2d').scale ratio, ratio
          # This library does not listen for canvas changes, so after the canvas is automatically
          # cleared by the browser, SignaturePad#isEmpty might still return false, even though the
          # canvas looks empty, because the internal data of this library wasn't cleared. To make sure
          # that the state of this library is consistent with visual state of the canvas, you
          # have to clear it manually.
          signaturePads[id].clear()
          signaturePads[id].fromData(data)
          return

        loadSignature = (id) ->
          unless signaturePads[id].isEmpty()
            $("[data-signature-target][data-signature-id='" + id + "']").val(signaturePads[id].toDataURL("image/jpeg"))

        window.onresize = resizeCanvas(id)
        $('.signature-form').submit ->
          loadSignature(id)
        $('a.clear-signature').on 'click', ->
          signaturePads[$(@).data('signature-id')].clear()
        resizeCanvas(id)
