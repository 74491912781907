window.App ||= {}
class App.Equipments extends App.Base

  index: =>
    window.scanContext = 'Equipment'
    Utility.Sidebar.refreshSideBar()
    Utility.Drawer.toggleDrawers()
    Utility.Datatables.equipments()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.FormActions.prepareIndexTable(Utility.BetaFeatures.enabled())
    Utility.HelpPanel.prepare()
    App.Equipments.prepareBulkOperations()
    $("html, body").scrollTop(0)
    $('.scan-lookup').click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanEquipment()
      else
        ScanInterface.scanEquipment()

  show: =>
    window.scanContext = 'Equipment'
    Utility.AccordionTab.prepare()
    Utility.Collapsable.prepare()
    Utility.Datepicker.prepare()
    App.Equipments.prepareDepreciation()
    this.prepareScan()
    this.prepareCharts()
    this.prepareProfileTab()
    $("html, body").scrollTop(0)

  new: =>
    Utility.AccordionTab.prepare()
    Utility.Collapsable.prepare()
    Utility.FormActions.newEditForm()
    this.prepareMaintenanceTemplateIdSelect()
    this.prepareGpsIntegrationSelect()
    this.prepareEditSelects()
    this.prepareCheckSerialNumber()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  edit: =>
    $("#popup").hide();
    Utility.AccordionTab.prepare()
    Utility.Collapsable.prepare()
    Utility.FormActions.newEditForm()
    this.prepareMaintenanceTemplateIdSelect()
    this.prepareGpsIntegrationSelect()
    this.prepareScan()
    this.prepareEditSelects()
    this.prepareCheckSerialNumber()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()

    $("#bulk-issue-work-orders").off('click').click ->
      equipment_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/bulk_issue_work_orders/new?equipment_ids=' + equipment_ids, null, null, 'script'

    $("#bulk-edit-equipment").off('click').click ->
      equipment_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/bulk_edit_equipment/new', { bulk_edit_equipment: { equipment_ids: equipment_ids }}, null, 'script'

    $("#bulk-sign-out-equipment").off('click').click ->
      equipment_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/bulk_sign_out_equipment/new', { bulk_sign_out_equipment: { equipment_ids: equipment_ids }}, null, 'script'

    $("#bulk-sign-in-equipment").off('click').click ->
      equipment_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/bulk_sign_in_equipment/new', { bulk_sign_in_equipment: { equipment_ids: equipment_ids }}, null, 'script'

  @refreshIfProcessing: =>
    if $('.progress-indicator').length
      setTimeout (->
        if $('.progress-indicator').data('equipment-id') > 0
          $.get '/equipment/' + $('.progress-indicator').data('equipment-id') + '/tasks', null, null, 'script'
        return
      ), 5000

  prepareProfileTab: =>
    $('#details ol.equipment-details').each (index, element) ->
      if $.trim($(@).children('li.meta-list').html()) == ''
        $(@).prev('h2').hide()

  prepareCharts: =>
    $(".chart").each (index, element) ->
      defaults =
        chart:
          backgroundColor: '#f3f5f9'
        credits: false,
        plotOptions:
          series:
            point:
              events:
                click: ->
                  if @.options.url
                    location.href = @.options.url
        title:
          style:
            fontSize: '16px'
      $(@).highcharts($.extend(true, {}, defaults, $(@).data('chart-data')))

  prepareGpsIntegrationSelect: =>
    $("#equipment_gps_key").keyup ->
      if $(@).val().length > 0 && $("#equipment_gps_integration_id").val().length == 0
        for first of $("#equipment_gps_integration_id")[0].selectize.options
          break
        $("#equipment_gps_integration_id")[0].selectize.addItem(first)
      else if $(@).val().length == 0 && $("#equipment_gps_integration_id").val().length > 0
        $("#equipment_gps_integration_id")[0].selectize.clear()

  prepareMaintenanceTemplateIdSelect: =>
    $("#equipment_maintenance_template_id").change ->
      if parseInt($(@).val()) > 0 && $("#equipment_primary_meter_units_with_none.disabled").length == 0
        $.getJSON '/maintenance_templates/' + $(@).val() + '.json', (data) ->
          $("#display-meters").prop("checked", true)
          if $(".display-meters-collapsable").is(':hidden')
            $(".display-meters-collapsable").slideDown()
          if data['primary_meter_units'] != null
            $("#equipment_primary_meter_units_with_none")[0].selectize.setValue(data['primary_meter_units'])
          else
            $("#equipment_primary_meter_units_with_none")[0].selectize.setValue('None')
          if data['secondary_meter_units'] != null
            $("#equipment_secondary_meter_units_with_none")[0].selectize.setValue(data['secondary_meter_units'])
          else
            $("#equipment_secondary_meter_units_with_none")[0].selectize.setValue('None')
          $("#equipment_primary_meter_units_with_none")[0].selectize.disable()
          $("#equipment_secondary_meter_units_with_none")[0].selectize.disable()
          $("select#equipment_primary_meter_units_with_none").prop('disabled', false)
          $("select#equipment_secondary_meter_units_with_none").prop('disabled', false)
          $(".display-meters").addClass('disabled')
      else if $("#equipment_primary_meter_units_with_none.disabled").length == 0
        $("#equipment_primary_meter_units_with_none")[0].selectize.enable()
        $("#equipment_secondary_meter_units_with_none")[0].selectize.enable()
        $(".display-meters").removeClass('disabled')

  prepareScan: =>
    $('.scan-key').click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanEquipmentKey($(@).data('id'))
      else
        ScanInterface.scanEquipmentKey($(@).data('id'))

  prepareCheckSerialNumber: =>
    $('#equipment_serial_number').change ->
      $.ajax
        url: "/equipment/get_existing_serial_number?serial_number=" + $(@).val() + "&id=" + $(@).data('equipment-id')
        data: null
        dataType: 'script'

  prepareEditSelects: =>
    $('#equipment_status').change ->
      if $(@).val() == 'Sold'
        $(".sold-details").slideDown()
        if $('#equipment_primary_meter').val().length > 0 && $('#equipment_sold_primary_meter').val().length == 0
          $('#equipment_sold_primary_meter').val($('#equipment_primary_meter').val())
        if $('#equipment_secondary_meter').val().length > 0 && $('#equipment_sold_secondary_meter').val().length == 0
          $('#equipment_sold_secondary_meter').val($('#equipment_secondary_meter').val())
      else
        $(".sold-details").slideUp()

    $('#equipment_gps_integration_id').change ->
      if $('#equipment_gps_integration_id').length > 0 && $('#equipment_gps_integration_id').val().length > 0
        $('#hide-gps-integration').show()
      else
        $('#hide-gps-integration').hide()

    $("#equipment_primary_meter_units_with_none").change ->
      App.Equipments.showHideWarrantyMeters($(@).val(), $('#equipment_secondary_meter_units_with_none').val())

    $("#equipment_secondary_meter_units_with_none").change ->
      App.Equipments.showHideWarrantyMeters($('#equipment_primary_meter_units_with_none').val(), $(@).val())

    if !$('#equipment_category_id').hasClass('hidden')
      $("#equipment_category_id")[0].selectize.on 'blur', (event) ->
        if $("#equipment_category_id")[0].selectize.getValue() == ''
          $("#equipment_category_id")[0].selectize.setValue($('#category-id-input').data('category-id'))

  @prepareDepreciation: =>
    $('#equipment-depreciation-monthly').hide()
    $('#monthly-depreciation-schedule').on 'click', ->
      $('#equipment-depreciation-annual').hide()
      $('#equipment-depreciation-monthly').fadeIn()
    $('#annual-depreciation-schedule').on 'click', ->
      $('#equipment-depreciation-monthly').hide()
      $('#equipment-depreciation-annual').fadeIn()

  @showHideWarrantyMeters = (primary_meter_units, secondary_meter_units) ->
    if ((primary_meter_units.toLowerCase() == 'none') || (primary_meter_units.toLowerCase() == '')) && ((secondary_meter_units.toLowerCase() == 'none') || (secondary_meter_units.toLowerCase() == ''))
      $('#primary_meter_warranty').addClass('hidden')
      $('#secondary_meter_warranty').addClass('hidden')
    else
      $('#primary_meter_warranty').removeClass('hidden')
      $('#secondary_meter_warranty').removeClass('hidden')

  @scan = (scanKey) ->
    $.ajax
      url: "/equipment/scan"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @scanKey = (id, scanKey) ->
    $.ajax
      url: "/equipment/" + id + "/edit"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @prepareUploads = ->
    $('.fuel-entry-import-template').fileupload
      dropZone: null
      uploadTemplateId: 'fuel-entry-import-template-upload'
      maxFileSize: 10000000
      autoUpload: true
      minFileSize: 1
      acceptFileTypes:  /(\.|\/)(csv)$/i
      dataType: 'xml'
      add: (e, data) ->
        if e.isDefaultPrevented()
          return false
        $this = $(this)
        that = $this.data('blueimp-fileupload') or $this.data('fileupload')
        options = that.options
        data.context = that._renderUpload(data.files).data('data', data).addClass('processing')
        options.filesContainer[if options.prependFiles then 'prepend' else 'append'] data.context
        that._forceReflow data.context
        that._transition data.context
        data.process(->
          $this.fileupload 'process', data
        ).always(->
          data.context.each((index) ->
            $(this).find('.size').text that._formatFileSize(data.files[index].size)
            return
          ).removeClass 'processing'
          that._renderPreviews data
          return
        ).done(->
          data.context.find('.start').prop 'disabled', false
          if that._trigger('added', e, data) != false and (options.autoUpload or data.autoUpload) and data.autoUpload != false
            $.jGrowl('Uploading...', {position: 'center', theme: 'success'})
            data.submit()
          return
        ).fail ->
          if data.files.error
            data.context.each (index) ->
              error = data.files[index].error
              if error
                $.jGrowl(error + '. Please select a .csv file and try again.', {position: 'center', theme: 'error'})
              return
          return
        return
      submit: (e, data) ->
        control = $(@)
        $.ajax
          dataType: 'json'
          url: "/fuel_entry_imports/new/"
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = control.fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        template_id = $(@).data('template-id')
        data.context.attr('data-key', key)
        $.ajax
          url: "/fuel_entry_imports/"
          type: 'POST'
          data: {fuel_entry_import: {document_key: key, name: name, fuel_entry_import_template_id: template_id}}
          cache: false
          dataType: 'script'

    $('.meter-update-import-template').fileupload
      dropZone: null
      uploadTemplateId: 'meter-update-import-template-upload'
      maxFileSize: 10000000
      autoUpload: true
      minFileSize: 1
      acceptFileTypes:  /(\.|\/)(csv)$/i
      dataType: 'xml'
      add: (e, data) ->
        if e.isDefaultPrevented()
          return false
        $this = $(this)
        that = $this.data('blueimp-fileupload') or $this.data('fileupload')
        options = that.options
        data.context = that._renderUpload(data.files).data('data', data).addClass('processing')
        options.filesContainer[if options.prependFiles then 'prepend' else 'append'] data.context
        that._forceReflow data.context
        that._transition data.context
        data.process(->
          $this.fileupload 'process', data
        ).always(->
          data.context.each((index) ->
            $(this).find('.size').text that._formatFileSize(data.files[index].size)
            return
          ).removeClass 'processing'
          that._renderPreviews data
          return
        ).done(->
          data.context.find('.start').prop 'disabled', false
          if that._trigger('added', e, data) != false and (options.autoUpload or data.autoUpload) and data.autoUpload != false
            $.jGrowl('Uploading...', {position: 'center', theme: 'success'})
            data.submit()
          return
        ).fail ->
          if data.files.error
            data.context.each (index) ->
              error = data.files[index].error
              if error
                $.jGrowl(error + '. Please select a .csv file and try again.', {position: 'center', theme: 'error'})
              return
          return
        return
      submit: (e, data) ->
        control = $(@)
        $.ajax
          dataType: 'json'
          url: "/meter_update_imports/new/"
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = control.fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        template_id = $(@).data('template-id')
        data.context.attr('data-key', key)
        $.ajax
          url: "/meter_update_imports/"
          type: 'POST'
          data: {meter_update_import: {document_key: key, name: name, meter_update_import_template_id: template_id}}
          cache: false
          dataType: 'script'

    $('.import').fileupload
      dropZone: null
      uploadTemplateId: 'import-upload'
      maxFileSize: 10000000
      autoUpload: true
      minFileSize: 1
      acceptFileTypes:  /(\.|\/)(csv)$/i
      dataType: 'xml'
      add: (e, data) ->
        if e.isDefaultPrevented()
          return false
        $this = $(this)
        that = $this.data('blueimp-fileupload') or $this.data('fileupload')
        options = that.options
        data.context = that._renderUpload(data.files).data('data', data).addClass('processing')
        options.filesContainer[if options.prependFiles then 'prepend' else 'append'] data.context
        that._forceReflow data.context
        that._transition data.context
        data.process(->
          $this.fileupload 'process', data
        ).always(->
          data.context.each((index) ->
            $(this).find('.size').text that._formatFileSize(data.files[index].size)
            return
          ).removeClass 'processing'
          that._renderPreviews data
          return
        ).done(->
          data.context.find('.start').prop 'disabled', false
          if that._trigger('added', e, data) != false and (options.autoUpload or data.autoUpload) and data.autoUpload != false
            $.jGrowl('Uploading...', {position: 'center', theme: 'success'})
            data.submit()
          return
        ).fail ->
          if data.files.error
            data.context.each (index) ->
              error = data.files[index].error
              if error
                $.jGrowl(error + '. Please select a .csv file and try again.', {position: 'center', theme: 'error'})
              return
          return
        return
      submit: (e, data) ->
        control = $(@)
        $.ajax
          dataType: 'json'
          url: "/" + control.data("import-type") + "_imports/new/"
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = control.fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        name = data.context.find("input.name").val()
        data.context.attr('data-key', key)
        $.ajax
          url: "/" + $(@).data('import-type') + "_imports/"
          type: 'POST'
          data: {import: {document_key: key, name: name}}
          cache: false
          dataType: 'script'
