window.App ||= {}
class App.TaskPartDefaultEquipments extends App.Base
  new: =>
    this.prepareTable()
    Utility.FlyInPanel.prepare()
    $("html, body").scrollTop(0)

  prepareTable: =>
    Utility.Datatables.taskPartDefaultEquipment()
    Utility.FormActions.prepareIndexTable()
    $("#check_all_additions_checkbox").click ->
      $("input[name='task_part_default_equipment[equipment_ids][]']").prop('checked', $(@).prop('checked'))
