window.Utility ||= {}

class Utility.AccordionTabLink
  TABS_SELECTOR = '.tabs'
  IS_OPEN = 'is-open'
  IS_ACTIVE = 'is-active'

  @prepare = ->
    showFirstTab = ->
      $(TABS_SELECTOR).each (index) ->
        firstTab = $(@).children("li").first()
        firstTabName = firstTab.data("tab-name")
        firstTab.children("a").addClass(IS_ACTIVE)

        tabContent = $(TABS_SELECTOR).children(".tab-content[data-tab-name='#{firstTabName}']").first()
        tabContent.addClass(IS_OPEN).show()

    showTab = (tab) ->
      accordionTabs = $(tab).closest(TABS_SELECTOR)
      accordionTabs.find(".#{IS_OPEN}").removeClass(IS_OPEN).hide()
      tabName = $(tab).parent().data('tab-name')

      tabContent = $(TABS_SELECTOR).children(".tab-content[data-tab-name='#{tabName}']").first()

      tabContent.toggleClass(IS_OPEN).toggle()
      accordionTabs.find(".#{IS_ACTIVE}").removeClass(IS_ACTIVE)
      $(tab).addClass(IS_ACTIVE)

    if $(TABS_SELECTOR).find(".#{IS_ACTIVE}").length > 0
      showTab($(TABS_SELECTOR).find(".#{IS_ACTIVE}"))
    else
      showFirstTab()

    $(TABS_SELECTOR).on "click", "> li > a", (event) ->
      event.preventDefault()
      if $(this).data("get") != undefined && $(this).data("get").length > 0
        $.getScript($(this).data("get"))
        showTab(this)
      else if !$(this).hasClass("is-active")
        showTab(this)
