$ ->
  METER_OPTIONS = '[data-role$=-meter-options] select'

  $(METER_OPTIONS).each ->
    changeMeterLabel($(@))

  $(METER_OPTIONS).change ->
    changeMeterLabel($(@))

changeMeterLabel = (input) ->
  word = input.val()
  label = word + $('#meter-options-group').attr('data-meter-label-text')

  if word.length > 0
    input.closest('.half').next('.half').find('label').html('Current ' + word)
  else
    input.closest('.half').next('.half').find('label').html('Current Miles')
