window.App ||= {}
class App.CompatibleParts extends App.Base

  index: =>

  show: =>

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()
