window.App ||= {}
class App.TaskParts extends App.Base

  @prepareWarrantyInputGroup: =>
    if $('#part-warranty-option').prop("checked")
      $('#warranty-input-group').show()
    else
      $('#warranty-input-group').hide()
      App.TaskParts.clearWarrantyInputs()

    $('#part-warranty-option').change ->
      if $(@).prop("checked")
        $('#warranty-input-group').show()
      else
        $('#warranty-input-group').hide()
        App.TaskParts.clearWarrantyInputs()

  @clearWarrantyInputs: =>
      $('#task_part_warranty_time_length').val('')
      $('#task_part_warranty_meter_length').val('')
