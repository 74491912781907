window.App ||= {}
class App.PurchaseOrderQuickAdds extends App.Base
  new: =>
    window.scanContext = 'Ignore'
    Utility.FormActions.newEditForm()
    this.prepareSelections()

  prepareSelections: =>
    $("#purchase_order_vendor_id").change ->
      if parseInt($(@).val()) > 0
        $.ajax
          url: '/vendors/' + $(@).val() + '/get_payment_terms'
          data: null
          dataType: 'script'

    $("#purchase_order_selected_purchase_order_id").change ->
      if parseInt($(@).val()) > 0
        $("#po-details").hide()
      else
        $("#po-details").show()

    $('#purchase_order_selected_purchase_order_id')[0].selectize.addOption({value: -1, text: 'Create New'});
    $('#purchase_order_selected_purchase_order_id')[0].selectize.setValue(-1)
