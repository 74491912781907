window.App ||= {}
class App.RecurringTaskUpdates extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    App.RecurringTaskUpdates.refreshIfProcessing()

  @refreshIfProcessing: =>
    if $('.progress-indicator').length
      setTimeout (->
        if $('.progress-indicator').data('equipment-id') > 0
          $.get '/equipment/' + $('.progress-indicator').data('equipment-id') + '/recurring_task_updates/new', null, null, 'script'
        return
      ), 5000
