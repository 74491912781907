window.Utility ||= {}

class Utility.Upload
  FILE_REGEXP = /\+|\-/g

  @sanitize_file_names = (files) ->
    i = 0
    count = files.length
    while i < count
      files[i].uploadName = files[i].name.replace(FILE_REGEXP, '_');
      i++;
