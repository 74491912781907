window.Utility ||= {}

class Imports
  constructor: ->
    @processing_timer_ids = []
    @deleting_timer_ids = []

  index: ->
    Utility.Datatables.imports()
    Utility.FormActions.prepareIndexTable()
    App.Equipments.prepareUploads()

  show: ->
    Utility.Imports.refreshIfProcessing()
    App.Equipments.prepareUploads()

  refreshIfProcessing: ->
    if $('i.import-pending').length
      # console.log("Setting timer for import refresh")
      timer_id = setTimeout (->
        if $('i.import-pending').data('import-id') > 0
          $.get '/imports/' + $('i.import-pending').data('import-id') + '/refresh', null, null, 'script'
        return
      ), 5000
      @processing_timer_ids.push timer_id
      # console.log("Set timer " + timer_id + " for import refresh")
    else
      # console.log("Deleting import timers")
      for timer_id in @processing_timer_ids
        clearTimeout timer_id
        # console.log("Deleted import timer " + timer_id)
      @processing_timer_ids = []

  refreshIfDeleting: ->
    if $('span.delete-pending').length
      # console.log("Setting timer for import delete refresh")
      timer_id = setTimeout (->
        $('#imports-table').DataTable().ajax.reload()
        return
      ), 5000
      @deleting_timer_ids.push timer_id
      # console.log("Set timer " + timer_id + " for import delete refresh")
    else
      # console.log("Deleting delete timers")
      for timer_id in @deleting_timer_ids
        clearTimeout timer_id
        # console.log("Deleted delete timer " + timer_id)
      @deleting_timer_ids = []

Utility.Imports ||= new Imports()