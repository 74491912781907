window.App ||= {}
class App.JobSites extends App.Base

  index: =>
    Utility.Datatables.jobSites()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  show: =>
    App.Attachments.fileUpload()
    Utility.Datatables.jobsiteAttachments()
    Utility.AccordionTab.prepare()
    Utility.Datatables.jobSiteEquipments()
    Utility.FormActions.prepareIndexTable()
