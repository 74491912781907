window.Utility ||= {}

class Utility.MeterValidation
  @preparePrimary: =>
    $('.primary-meter-value').on 'change', ->
      if parseFloat($(@).val()) < parseFloat($(@).data('current-primary-meter'))
        $('#force-primary-meter-rollback').show()
        if $("#primary-meter-error-list").length > 0
          $('#primary-meter-error-list').show()
      else
        $('input.force-primary-meter-rollback').prop('checked', false)
        $('#force-primary-meter-rollback').hide()
        if $("#primary-meter-error-list").length > 0
          $('#primary-meter-error-list').hide()
      if parseFloat($(@).val()) > parseFloat($(@).data('max-primary-meter'))
        $('#skip-primary-meter-threshold-check').show()
      else
        $('input.skip-primary-meter-threshold-check').prop('checked', false)
        $('#skip-primary-meter-threshold-check').hide()
    if parseFloat($('.primary-meter-value').val()) < parseFloat($('.primary-meter-value').data('current-primary-meter'))
      $('#force-primary-meter-rollback').show()
      if $("#primary-meter-error-list").length > 0
        $('#primary-meter-error-list').show()
    if parseFloat($('.primary-meter-value').val()) > parseFloat($('.primary-meter-value').data('max-primary-meter'))
      $('#skip-primary-meter-threshold-check').show()
  @prepareSecondary: =>
    $('.secondary-meter-value').on 'change', ->
      if parseFloat($(@).val()) < parseFloat($(@).data('current-secondary-meter'))
        $('#force-secondary-meter-rollback').show()
        if $("#secondary-meter-error-list").length > 0
          $('#secondary-meter-error-list').show()
      else
        $('input.force-secondary-meter-rollback').prop('checked', false)
        $('#force-secondary-meter-rollback').hide()
        if $("#secondary-meter-error-list").length > 0
          $('#secondary-meter-error-list').hide()
      if parseFloat($(@).val()) > parseFloat($(@).data('max-secondary-meter'))
        $('#skip-secondary-meter-threshold-check').show()
      else
        $('input.skip-secondary-meter-threshold-check').prop('checked', false)
        $('#skip-secondary-meter-threshold-check').hide()
    if parseFloat($('.secondary-meter-value').val()) < parseFloat($('.secondary-meter-value').data('current-secondary-meter'))
      $('#force-secondary-meter-rollback').show()
      if $("#secondary-meter-error-list").length > 0
        $('#secondary-meter-error-list').show()
    if parseFloat($('.secondary-meter-value').val()) > parseFloat($('.secondary-meter-value').data('max-secondary-meter'))
      $('#skip-secondary-meter-threshold-check').show()
