window.App ||= {}
class App.InspectionTemplates extends App.Base

  index: =>
    Utility.Datatables.inspectionTemplates()
    Utility.FormActions.prepareIndexTable()
    App.InspectionTemplates.refreshIfProcessing()

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FormActions.removeParentByClass()
    this.prepareForm()
    Utility.Collapsable.prepare()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.AccordionTab.prepare()
    Utility.FormActions.removeParentByClass()
    this.prepareForm()
    Utility.Datatables.workOrderAttachments()
    App.InspectionTemplates.prepareStepType()
    Utility.Collapsable.prepare()
    App.Attachments.fileUpload()
    Utility.FlyInPanel.prepare()


  prepareForm: =>
    Utility.Datatables.inspectionTemplateEquipment()
    Utility.FormActions.prepareIndexTable()
    $("#check_all_checkbox").click ->
      $("input[name='inspection_template[equipment_ids][]']").prop('checked', $(@).prop('checked'))

    $("input[name='step_json[][weight_factor]']").change ->
      rounded_score = 0
      if (parseFloat($(@).val()) >= 0)
        rounded_score = Math.round(parseFloat($(@).val()) * 1000) / 1000
        $(@).val(rounded_score)
      else
        $(@).val('')

    if $("#steps-array").length > 0
      el = $("#steps-array")[0]
      sortable = Sortable.create(el,
        {
          handle: ".handle"
          scroll: true
        }
      )

    App.InspectionTemplates.prepareAddStepLink()

  @prepareAddStepLink = ->
    $("a.add-step-note").off('click').on 'click', ->
      step_index = $(@).attr('id').replace("step-note-link_", "")
      $.ajax
        url: "/inspection_templates/add_step_note?step_index=" + step_index
        data: null
        dataType: 'script'

  @prepareStepType = ->
    $("select[name='step_json[][step_type]']").change ->
      step_value = $(@).nextAll('.step-value')
      if $(@).val() == 'task_and_data' || $(@).val() == 'score'
        step_value.show()
      else
        step_value.hide()

      if $(@).val() == 'task_and_data'
        step_value.children('.input.value-type').show()
      else
        step_value.children('.input.value-type').hide()

      if $(@).val() == 'score'
        step_value.children('.input.weight-factor').show()
        $('#inspection_template_allow_na').prop('checked', true)
      else
        step_value.children('.input.weight-factor').hide()

      if $(@).val() == 'group'
        $("input[name='step_json[][name]']").attr('placeholder', 'Type group name')
        $(@).nextAll('span.step-note').addClass('tw-hidden')
      else
        $("input[name='step_json[][name]']").attr('placeholder', 'Type task name')
        $(@).nextAll('span.step-note').removeClass('tw-hidden')

  @prepareSortable = ->
    if $("#steps-array").length > 0
      el = $("#steps-array")[0]
      sortable = Sortable.create(el,
        {
          handle: ".handle"
          scroll: true
        }
      )

  @refreshIfProcessing = ->
    if $('span.update-pending').length
      setTimeout (->
        $('#inspection-templates-table').DataTable().ajax.reload()
        return
      ), 5000
