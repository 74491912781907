window.App ||= {}
class App.FuelEntryImports extends App.Base

  show: =>
    App.FuelEntryImports.refreshIfProcessing()
    App.Equipments.prepareUploads()

  @refreshIfProcessing: =>
    if $('i.import-pending').length
      setTimeout (->
        if $('i.import-pending').data('import-id') > 0
          $.get '/fuel_entry_imports/' + $('i.import-pending').data('import-id') + '/refresh', null, null, 'script'
        return
      ), 5000
