window.App ||= {}
class App.Workflows extends App.Base

  index: =>
    Utility.Datatables.workflows()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    App.Workflows.prepareAttributeSelect()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    App.Workflows.prepareAttributeSelect()
    Utility.FlyInPanel.prepare()

  @prepareAttributeSelect = ->
    $(document).off('change', '[data-attribute-select]').on 'change', '[data-attribute-select]', ->
      if $(@).val().length > 0
        $.get '/workflows/select_attribute?workflow_type_name=' + $(@).data('workflow-type-name') + '&attribute=' + $(@).val() + '&and_index=' + $(@).data('and-index') + '&or_index=' + $(@).data('or-index'), null, null, 'script'
    $(document).off('change', '[data-action-select]').on 'change', '[data-action-select]', ->
      if $(@).val().length > 0
        $.get '/workflows/select_action?workflow_type_name=' + $(@).data('workflow-type-name') + '&action_name=' + $(@).val() + '&action_index=' + $(@).data('action-index'), null, null, 'script'
    $(document).off('click', 'a.delete-condition').on 'click', 'a.delete-condition', ->
      if $(@).closest('tbody').find('tr.condition').length > 1
        $(@).closest('tr').remove()
      else
        $(@).closest('tbody').remove()
    $(document).off('click', 'a.delete-action').on 'click', 'a.delete-action', ->
      $(@).closest('tr').remove()
    $(document).off('click', 'a.add-condition').on 'click', 'a.add-condition', ->
      $.get '/workflows/add_condition?workflow_type_name=' + $(@).data('workflow-type-name') + '&and_index=' + $(@).data('and-index') + '&or_index=' + $(@).data('or-index'), null, null, 'script'
    $(document).off('click', 'a.add-condition-group').on 'click', 'a.add-condition-group', ->
      $.get '/workflows/add_condition_group?workflow_type_name=' + $(@).data('workflow-type-name') + '&and_index=' + Date.now(), null, null, 'script'
    $(document).off('click', 'a.add-action').on 'click', 'a.add-action', ->
      $.get '/workflows/add_action?workflow_type_name=' + $(@).data('workflow-type-name') + '&action_index=' + Date.now(), null, null, 'script'
