window.App ||= {}
class App.Invoices extends App.Base

  index: =>
    window.scanContext = 'Ignore'
    Utility.Datatables.invoices()
    Utility.FormActions.prepareIndexTable()
    Utility.HelpPanel.prepare()
    App.Invoices.prepareBulkOperations()

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareCustomer()
    $('#invoice_customer_id')[0].selectize.focus()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    this.prepareCustomer()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'Ignore'
    Utility.NextPrevious.prepare('invoices')
    Utility.Collapsable.prepare()
    this.prepareInvoiceForm()
    $("html, body").scrollTop(0)

  prepareCustomer: =>
    $('#invoice_customer_id').on 'change', ->
      if parseInt($(@).val()) > 0
        $.ajax
          url: '/invoices/pay_terms?customer_id=' + $(@).val()
          data: null
          dataType: 'script'

  prepareInvoiceForm: =>
    $("#show-invoice-table").on 'click', "#cancel-add-line", ->
      $("#form-add-line").hide()
      $("#invoice-add-line").show()
      $("#add-line").show()

    $("#show-payment-table").on 'click', "#cancel-add-payment", ->
      $("#form-add-payment").hide()
      $("#invoice-add-payment").show()
      $("#add-payment").show()

    $(document).off('change', '#invoices_line_item_work_order_id').on 'change', "#invoices_line_item_work_order_id", ->
      id = $(@).val()
      invoice_id = $(@).attr('data-invoice-id')

      $.ajax
        url: "/invoices/"+invoice_id+"/invoices_line_items/get_line_item_unit_cost?work_order_id="+id
        data: null
        dataType: 'script'

    $(document).off('change', '#invoices_line_item_taxes').on 'change', "#invoices_line_item_taxes", ->
      id = $('#invoices_line_item_work_order_id')[0].selectize.getValue()
      invoice_id = $(@).attr('data-invoice-id')

      if parseFloat(id) > 0
        $.ajax
          url: "/invoices/"+invoice_id+"/invoices_line_items/get_line_item_unit_cost?work_order_id="+id
          data: null
          dataType: 'script'

    $(document).off('keyup', ".quantity, .unit-cost").on 'keyup', ".quantity, .unit-cost", ->
      App.Invoices.calculateTotalCost()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-print-invoices").click ->
      invoice_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      window.open("/invoices/bulk_print?invoice_ids=" + invoice_ids, '_blank')

  @calculateTotalCost = ->
    unitCost = parseFloat($("input.unit-cost").val().replace(/[^0-9.]/g, ''))
    quantity = 1
    unitCost = 0 unless unitCost > 0
    calculatedTotalCost = unitCost * quantity
    if calculatedTotalCost >= 0
      $("#calculated-total-cost").html(calculatedTotalCost.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}))
    else
      $("#calculated-total-cost").html("")
