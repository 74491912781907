window.Utility ||= {}

class Utility.SelectizeFields
  @prepare = ->
    $('[data-selectize="single"]').selectize
      create: true
      sortField: "text"
      openOnFocus: true

    $('[data-selectize="no-create"]').selectize
      create: false
      sortField: "text"
      openOnFocus: true

    $('[data-selectize="no-create no-sort"]').selectize
      create: false
      openOnFocus: true

    $('[data-selectize="public-submitter"]').selectize
      create: false
      sortField: "text"
      openOnFocus: true
      create: (input, callback) ->
        $.get "/submitters/" + $('[data-selectize="public-submitter"]').data('organization-key') + "/new", {name: input}, null, 'script'

    $('[data-selectize="json-load"]').selectize
      valueField: 'id',
      labelField: 'name',
      create: false
      sortField: "text"
      openOnFocus: true

    $('[data-selectize="no-create-or-order"]').selectize
      create: false
      openOnFocus: true

    $('[data-selectize="no-create-or-order-max-two"]').selectize
      create: false
      openOnFocus: true
      maxItems: 2
      closeAfterSelect: true

    $('[data-selectize="tags"]').selectize
      delimiter: ','
      persist: false
      create: true
      valueField: 'name'
      searchField: 'name'
      labelField: 'name'
      render: {
        option: (item, escape) ->
          return '<div>' + escape(item.name) + '</div>';
      },
      load: (query, callback) ->
        if (!query.length)
          return callback()
        $.ajax
          url: "/tags?tag_type=" + encodeURIComponent( (@).$input.data('tag-type') ) + "&query=" + encodeURIComponent(query)
          type: 'GET'
          dataType: 'json'
          error: ->
            callback()
          success: (res) ->
            callback(res)

    $('[data-selectize="remote-work-order"]').selectize
      create: false
      items: [
        $('[data-selectize="remote-work-order"]').data('work-order-id')
      ]
      valueField: 'id'
      labelField: 'prefix_title'
      searchField: ['prefix_title','name_description', 'year_color_make_model', 'serial_number', 'license', 'equipment_type']
      options: $('[data-selectize="remote-work-order"]').data('options')
      render: {
        option: (item, escape) ->
          work_order_number = '<span class="equipment-name">' + escape(item.prefix_title) + '</span>'
          name_description = year_color_make_model = ''
          line3 = []
          if !!item.name_description && item.name_description.length
            line3.push(escape(item.name_description))
          if !!item.year_color_make_model && item.year_color_make_model
            line3.push(escape(item.year_color_make_model))
          if !!item.serial_number && item.serial_number.length
            line3.push(escape(item.serial_number))
          if !!item.license && item.license.length
            line3.push(escape(item.license))
          if !!item.equipment_type && item.equipment_type.length
            line3.push(escape(item.equipment_type))

          return '<div class="equipment-lookup">' + work_order_number + '<span class="equipment-line3">'  + line3.join(' | ') + '</span></div>'
      },
      load: (query, callback) ->
        $.ajax
          url: "/work_orders/dropdown_search?query=" + encodeURIComponent(query) + "&customer_id=" + $('[data-selectize="remote-work-order"]').data('customer-id')
          type: 'GET'
          dataType: 'json'
          error: ->
            callback()
          success: (res) ->
            callback(res)

    $('[data-selectize="remote-equipment"]').selectize
      create: false
      items: [
        $('[data-selectize="remote-equipment"]').data('equipment-id')
      ]
      valueField: 'id'
      labelField: 'name_description'
      searchField: ['name_description', 'year_color_make_model', 'serial_number', 'license', 'equipment_type', 'tag_array']
      optgroupField: 'location_id'
      optgroups: $('[data-selectize="remote-equipment"]').data('locations')
      options: $('[data-selectize="remote-equipment"]').data('options')
      render: {
        option: (item, escape) ->
          name_description = year_color_make_model = ''
          line3 = []
          if !!item.name_description && item.name_description.length
            name_description = '<span class="equipment-name">' + escape(item.name_description) + '</span>'
          if !!item.year_color_make_model && item.year_color_make_model
            year_color_make_model = '<span class="equipment-description">' + escape(item.year_color_make_model) + '</span>'
          if !!item.serial_number && item.serial_number.length
            line3.push(escape(item.serial_number))
          if !!item.license && item.license.length
            line3.push(escape(item.license))
          if !!item.equipment_type && item.equipment_type.length
            line3.push(escape(item.equipment_type))

          return '<div class="equipment-lookup">' + name_description + year_color_make_model + '<span class="equipment-line3">'  + line3.join(' | ') + '</span></div>'
      },
      load: (query, callback) ->
        if (!query.length)
          return callback()

        if (@).$input.data('inspections')
          $.ajax
            url: "/equipment/dropdown_search?with_inspection_template=true&query=" + encodeURIComponent(query)
            type: 'GET'
            dataType: 'json'
            error: ->
              callback()
            success: (res) ->
              callback(res)
        else if (@).$input.data('meter-replacements')
          $.ajax
            url: "/equipment/dropdown_search?with_meters=true&query=" + encodeURIComponent(query)
            type: 'GET'
            dataType: 'json'
            error: ->
              callback()
            success: (res) ->
              callback(res)
        else if (@).$input.data('parent')
          $.ajax
            url: "/equipment/dropdown_search?parent=true&child_id=" + (@).$input.data('child-id') + "&query=" + encodeURIComponent(query)
            type: 'GET'
            dataType: 'json'
            error: ->
              callback()
            success: (res) ->
              callback(res)
        else
          $.ajax
            url: "/equipment/dropdown_search?query=" + encodeURIComponent(query)
            type: 'GET'
            dataType: 'json'
            error: ->
              callback()
            success: (res) ->
              callback(res)

    $('[data-selectize="remote-inventory"]').selectize
      items: [
        $('[data-selectize="remote-inventory"]').data('inventory-id')
      ]
      selectOnTab: true
      createOnBlur: true
      valueField: 'id'
      labelField: 'name_or_part_number'
      searchField: ['name', 'part_number', 'supplier_part_numbers', 'substitute_for_part_numbers']
      optgroupField: 'warehouse_id'
      optgroups: $('[data-selectize="remote-inventory"]').data('warehouses')
      options: $('[data-selectize="remote-inventory"]').data('options')
      sortField: [{field: 'equipment_id', direction: 'desc'}, {field: 'warehouse_name'}, {field: 'part_number'}, {field: 'name'}, {field: '$score'}]
      render: {
        option: (item, escape) ->
          quantity = ''
          part_number_name = []
          alternate_part_numbers = []

          if !!item.supplier_part_numbers && item.supplier_part_numbers.length
            alternate_part_numbers.push('V#: ' + escape(item.supplier_part_numbers))
          if !!item.quantity
            quantity = '<span class="quantity">' + escape(item.quantity) + ' avail</span>'
          if !!item.part_number && item.part_number.length
            part_number_name.push(escape(item.part_number))
          if !!item.name && item.name.length
            part_number_name.push(escape(item.name))
          if !!item.equipment_id
            part_number_name.push("<span data-title='Compatible Part' class='check-green'><i class='fas fa-check-circle'></i></span>")
          if !!item.substitute_for_part_numbers && item.substitute_for_part_numbers.length
            alternate_part_numbers.push('Sub: ' + escape(item.substitute_for_part_numbers))

          return '<div class="part-lookup"><div class="part-number-name">' + part_number_name.join(' ') + quantity + "</div>" + alternate_part_numbers.join(' | ') + '</div>'
      },
      load: (query, callback) ->
        if (!query.length)
          return callback()
        params = "query=" + encodeURIComponent(query)
        if $('[data-selectize="remote-inventory"]').data('equipment-id')
          params += '&equipment_id=' + $('[data-selectize="remote-inventory"]').data('equipment-id')
        $.ajax
          url: "/inventories/dropdown_search?" + params
          type: 'GET'
          dataType: 'json'
          error: ->
            callback()
          success: (res) ->
            callback(res)
      create: if $('[data-selectize="remote-inventory"]').data('allow-create')
                (input, callback) ->
                  if $("#popup").is(":hidden") && $('[data-selectize="remote-inventory"]').val() == '' && !window.pressed
                    if !!$("#task_part_inventory_id").data('assigned-task-id')
                      $.get "/assigned_tasks/" + $("#task_part_inventory_id").data('assigned-task-id') + "/inventory_quick_adds/new", {part_number: input, object_id: $("#task_part_inventory_id").data('object-id')}, null, 'script'
                    else if !!$("#expense_inventory_id").data('object-type')
                      $.get "/inventory_quick_adds/new", {part_number: input, object_type: $("#expense_inventory_id").data('object-type'), object_id: $("#expense_inventory_id").data('object-id') }, null, 'script'
                    else if !!$("#compatible_part_inventory_id").data('object-type')
                      $.get "/inventory_quick_adds/new", {part_number: input, object_type: $("#compatible_part_inventory_id").data('object-type'), object_id: $("#compatible_part_inventory_id").data('object-id') }, null, 'script'
                    else if !!$("#task_part_default_inventory_id").data('recurring-task-id')
                      $.get "/recurring_tasks/" + $("#task_part_default_inventory_id").data('recurring-task-id') + "/inventory_quick_adds/new", {part_number: input, object_id: $("#task_part_default_inventory_id").data('object-id')}, null, 'script'
                    else if !!$("#task_part_default_inventory_id").data('maintenance-template-task-id')
                      $.get "/maintenance_template_tasks/" + $("#task_part_default_inventory_id").data('maintenance-template-task-id') + "/inventory_quick_adds/new", {part_number: input, object_id: $("#task_part_default_inventory_id").data('object-id')}, null, 'script'
                    else if !!$("#part_kit_inventory_inventory_id").data('part-kit-id')
                      $.get "/part_kits/" + $("#part_kit_inventory_inventory_id").data('part-kit-id') + "/inventory_quick_adds/new", {part_number: input, object_id: $("#part_kit_inventory_inventory_id").data('object-id')}, null, 'script'
                    else if !!$("#fluid_consumption_inventory_id").data('object-type')
                      $.get "/inventory_quick_adds/new", {part_number: input, object_type: $("#fluid_consumption_inventory_id").data('object-type'), object_id: $("#fluid_consumption_inventory_id").data('object-id') }, null, 'script'
                  else
                    return false
              else
                false

    $('[data-selectize="vendor"]').selectize
      sortField: "text"
      openOnFocus: true
      create: if $('[data-selectize="vendor"]').data('allow-create')
                (input, callback) ->
                  if !window.pressed
                    $.get "/vendor_quick_adds/new", {name: input, object_type: $('[data-selectize="vendor"]').data('object-type'), object_id: $(this)[0].$input["0"].id}, null, 'script'
                  else
                    return false
              else
                false

    $('[data-selectize="employee"]').selectize
      sortField: "text"
      openOnFocus: true
      maxItems: $('[data-selectize="employee"]').data('max-items')
      create: if $('[data-selectize="employee"]').data('allow-create')
                (input, callback) ->
                  if !window.pressed
                    $.get "/employee_quick_adds/new", {name: input, object_type: $('[data-selectize="employee"]').data('object-type'), object_id: $(this)[0].$input["0"].id}, null, 'script'
                  else
                    return false
              else
                false

    $('[data-selectize="po-remote-inventory"]').selectize
      items: [
        $('[data-selectize="po-remote-inventory"]').data('inventory-id')
      ]
      valueField: 'id_supplier'
      labelField: 'name_or_part_number'
      searchField: ['name', 'part_number', 'supplier_part_numbers', 'substitute_for_part_numbers', 'manufacturer']
      optgroupField: 'warehouse_id'
      optgroups: $('[data-selectize="po-remote-inventory"]').data('warehouses')
      options: $('[data-selectize="po-remote-inventory"]').data('options')
      sortField: [{field: 'warehouse_name'}, {field: 'part_number'}, {field: 'name'}, {field: '$score'}]
      render: {
        option: (item, escape) ->
          quantity = ''
          manufacturer = ''
          part_number_name = []
          alternate_part_numbers = []

          if !!item.manufacturer && item.manufacturer.length
            manufacturer = '<div>Mfc: ' + item.manufacturer + '</div>'
          if !!item.supplier_part_number && item.supplier_part_number.length
            alternate_part_numbers.push('V#: ' + escape(item.supplier_part_number))
          else if item.vendor_name
            alternate_part_numbers.push(escape(item.vendor_name))
          if !!item.quantity
            quantity = '<span class="quantity">' + escape(item.quantity) + ' avail</span>'
          if !!item.part_number && item.part_number.length
            part_number_name.push(escape(item.part_number))
          if !!item.name && item.name.length
            part_number_name.push(escape(item.name))
          if !!item.substitute_for_part_numbers && item.substitute_for_part_numbers.length
            alternate_part_numbers.push('Sub: ' + escape(item.substitute_for_part_numbers))

          return '<div class="part-lookup"><div class="part-number-name">' + part_number_name.join(' ') + quantity + "</div>" + manufacturer + '<div>' + alternate_part_numbers.join(' | ') + '</div></div>'
      },
      load: (query, callback) ->
        if (!query.length)
          return callback()
        $.ajax
          url: "/inventories/dropdown_search?query=" + encodeURIComponent(query) + "&warehouse_id=" + $('[data-selectize="po-remote-inventory"]').data('warehouse-id')
          type: 'GET'
          dataType: 'json'
          error: ->
            callback()
          success: (res) ->
            callback(res)
      create: if $('[data-selectize="po-remote-inventory"]').data('allow-create')
                (input, callback) ->
                  if !window.pressed
                    if !!$("#purchase_orders_inventory_inventory_id").data('purchase-order-id')
                      $.get "/purchase_orders/" + $("#purchase_orders_inventory_inventory_id").data('purchase-order-id') + "/inventory_quick_adds/new", {part_number: input, object_id: $("#purchase_orders_inventory_inventory_id").data('object-id')}, null, 'script'
                  else
                    return false
              else
                false

    $('[data-selectize="multi"]').selectize
      delimiter: ','
      persist: false
      create: true
      openOnFocus: true

    $('[data-selectize="custom-field"]').selectize
      create: $('[data-selectize="custom-field"]').data('allow-create')
      createFilter: /^[^\.\[\]\(\)'"]+$/
      sortField: "text"
      openOnFocus: true
      onItemAdd: (value, $item) ->
        $("input[data-custom-id=" + (@).$input.data('custom-id') + "]").attr('name', (@).$input.data('object-name') + "[custom_fields][" + value + "]")
      onItemRemove: (value, $item) ->
