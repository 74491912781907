window.App ||= {}
class App.AdminOrganizationPruners extends App.Base

  index: =>
    Utility.Datatables.adminOrganizationPruners()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.Datepicker.prepare();
    $('#toggle-operations').click ->
      checkboxes = $('input.pruner-operation')
      checkboxes.prop('checked', !checkboxes.prop('checked'))
