window.App ||= {}
class App.Recalls extends App.Base

  index: =>
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.recalls()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.FormActions.prepareIndexTable()
    Utility.HelpPanel.prepare()
    App.Recalls.prepareBulkOperations()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()

    $("#bulk-mark-resolved").off('click').click ->
      equipment_recall_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/equipment_recalls/bulk_mark_resolved?equipment_recall_ids=' + equipment_recall_ids, null, null, 'script'

    $("#bulk-mark-all-resolved").off('click').click ->
      search = $('#text_search').val()
      $.get '/equipment_recalls/bulk_mark_resolved?mark_all=true&search=' + encodeURIComponent(search), null, null, 'script'

    $("#bulk-unmark-resolved").off('click').click ->
      equipment_recall_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/equipment_recalls/bulk_unmark_resolved?equipment_recall_ids=' + equipment_recall_ids, null, null, 'script'

    $("#bulk-unmark-all-resolved").off('click').click ->
      search = $('#text_search').val()
      $.get '/equipment_recalls/bulk_unmark_resolved?mark_all=true&search=' + encodeURIComponent(search), null, null, 'script'

