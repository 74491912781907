import ClipboardJS from "clipboard"
window.App ||= {}
class App.Organizations extends App.Base

  edit: =>
    Utility.AccordionTab.prepare()
    Utility.SelectizeFields.prepare()
    Utility.Collapsable.prepare()
    Utility.Datatables.customMeterUnits()
    Utility.Datatables.customWorkOrderStatuses()
    Utility.FormActions.prepareIndexTable()
    Utility.HelpPanel.prepare()
    App.CustomWorkOrderStatuses.prepareSortable()
    Utility.Toggle.prepare()
    App.Organizations.toggleUserActivationControls()

  @toggleUserActivationControls: =>
    FLEET_FIELDS = '.fleet-fields'
    FLEET_INPUTS = '.fleet-fields input'
    CHECKED_FLEET_INPUTS = '.fleet-fields input:checked'
    FLEET_MODE_FIELD = '#organization_fleet_mode'

    if $('.toggle-fleet-mode').data('toggles') && !$('.toggle-fleet-mode').data('toggles').active
      $(FLEET_FIELDS).hide()

    $('.toggle-fleet-mode').on 'toggle', (e, active) ->
      if (active)
        $(FLEET_MODE_FIELD).val('t');
        if ($(CHECKED_FLEET_INPUTS).length == 0)
          $(FLEET_INPUTS).prop('checked', true)
        $(FLEET_FIELDS).fadeIn();
      else
        $(FLEET_MODE_FIELD).val('f');
        $(FLEET_FIELDS).fadeOut()

    if $('#organization_sso_enabled').prop("checked")
      $('#sso-config-fields, #idp-config-fields').show()
    else
      $('#sso-config-fields, #idp-config-fields').hide()

    $('#organization_sso_enabled').change ->
      if $(@).prop("checked")
        $('#sso-config-fields, #idp-config-fields').slideDown()
      else
        $('#sso-config-fields, #idp-config-fields').slideUp()

    $('#idp-certificate').click (e) ->
      $('#idp-cert-submit').click()

    $('#idp-cert-submit').change (e) ->
      formData = new FormData();
      formData.append("idp_certificate", e.target.files[0]);

      $.ajax
        url: '/organizations/' + $('#sso_organization_id').val() + '/upload_idp_certificate'
        type: 'POST'
        beforeSend: (xhr, options) ->
          options.data = formData
          return true
        cache: false
        dataType: 'script'


    $('button.copy-value').click (e) ->
      e.preventDefault()

    clipboard = new ClipboardJS('.copy-value')
    clipboard.on('success', (e) ->
      oldText = e.trigger.textContent
      e.trigger.textContent = 'Copied!'
      setTimeout (->
        e.trigger.textContent = oldText
        e.clearSelection()
      ), 2000
    )
