window.Utility ||= {}

class Utility.Datepicker
  @prepare = ->
    $("#ui-datepicker-div").hide()
    if $("#datepicker-format").data('value') == "mm/dd/yy"
      $('[data-datepicker="true"]').mask("99/99/9999", {placeholder: "mm/dd/yyyy"})
    else if $("#datepicker-format").data('value') == "dd/mm/yy"
      $('[data-datepicker="true"]').mask("99/99/9999", {placeholder: "dd/mm/yyyy"})
    else
      $('[data-datepicker="true"]').mask("9999-99-99", {placeholder: "yyyy-mm-dd"})

    $("[data-datepicker='true']").datepicker(
      dateFormat: $('#datepicker-format').data('value')
      monthNamesShort: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      changeMonth: true
      changeYear: true
      yearRange: 'c-20:c+20'
    )

    if $("input.autofocus[data-datepicker='true']").length
      setTimeout (->
        $("input.autofocus[data-datepicker='true']").datepicker('show')
        return
      ), 500


    $("[data-datepicker-past='true']").datepicker(
      dateFormat: $('#datepicker-format').data('value')
      monthNamesShort: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      changeMonth: true
      changeYear: true
      yearRange: 'c-20:c+20'
      maxDate: 0
    )
    $("[data-timepicker='true']").timepicker(
      minTime: '12:30am'
      maxTime: '11:30pm'
      step: 15
      noneOption:
        label: 'None'
        value: '12:00am'
    )
