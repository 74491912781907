window.Utility ||= {}

class Utility.Collapsable

  @prepare = ->
    $('span.collapsable, h2.collapsable, h3.collapsable, dl.collapsable, i.collapsable').not('.disabled').off("click").on "click", ->
      if $(@).next('.collapsable').is(':visible')
        $(@).next('.collapsable').slideUp()
        $(@).find('i.fa-caret-down').removeClass('fa-rotate-180')
        $(@).find("input[type='checkbox']").prop('checked', false)
      else
        $(@).next('.collapsable').slideDown()
        $(@).find('i.fa-caret-down').addClass('fa-rotate-180')
        $(@).find("input[type='checkbox']").prop('checked', true)

    $('input.collapsable').not('disabled').off("click").on "click", ->
      collapsable = $("." + $(@).prop("id") + "-collapsable")
      if collapsable.is(':visible')
        collapsable.slideUp()
      else
        collapsable.slideDown()
