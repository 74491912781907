window.App ||= {}
class App.LaborLogs extends App.Base
  index: =>
    window.scanContext = 'Ignore'
    this.prepareIndexForm()
    Utility.Datatables.laborLogs();

  prepareIndexForm: =>
    $("a[data-click='refresh']").off('click').on 'click', ->
      $($(@).attr('data-hide-id')).hide()
      $.ajax
        url: '/labor_logs/refresh_assigned_task/' + $("#labor-logs-table").data('assigned-task-id')
        data: null
        dataType: 'script'
