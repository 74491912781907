window.Utility ||= {}
class Utility.Scanner
  @prepare = ->
    if $("#minimum-barcode-length").length > 0
      window.pressed = false
      chars = []

      $(window).keypress (e) ->
        if (e.which >= 0 && e.which <= 127 && String.fromCharCode(e.which) != "\u0000")
          chars.push(String.fromCharCode(e.which))
        console.log(e.which + ":" + chars.join("|"))
        if !window.pressed
          setTimeout (->
            if chars.length >= $("#minimum-barcode-length").data("value") && window.scanContext != 'Ignore' && !Utility.FlyInPanel.flyInActive
              barcode = chars.join("")

              if barcode.substring(0,5) == "fmpWO"
                $.jGrowl("Work Order Scan: " + barcode.substring(5), {position: 'center', theme: 'info'})
                App.WorkOrders.scan(barcode.substring(5))
              else if barcode.substring(0,5) == "fmpPO"
                $.jGrowl("Purchase Order Scan: " + barcode.substring(5), {position: 'center', theme: 'info'})
                App.PurchaseOrders.scan(barcode.substring(5))
              else
                $.jGrowl(window.scanContext + " Scan: " + barcode, {position: 'center', theme: 'info'})
                #console.log(window.scanContext)
                if window.scanContext == 'Equipment'
                  if $('.scan-key').length > 0 && $('#equipment-show').length > 0
                    App.Equipments.scanKey($('#equipment-show').data('equipment-id'), barcode)
                  else
                    App.Equipments.scan(barcode)
                else if window.scanContext == 'Inventory'
                  if $('.scan-key').length > 0 && $('#inventory-show').length > 0
                    App.Inventories.scanKey($('#inventory-show').data('inventory-master-id'), barcode)
                  else
                    App.Inventories.scan(barcode)
                else if window.scanContext == 'Inspection'
                  App.Inspections.scanKey(barcode)
                else if window.scanContext == 'Purchase'
                  App.PurchaseOrders.scanPurchaseOrderInventory(window.purchase_order_id, barcode)
                else if window.scanContext == 'Part'
                  App.WorkOrders.scanTaskPart(window.assigned_task_id, barcode)
            chars = []
            window.pressed = false
          ), $("#barcode-timer-ms").data("value")
        window.pressed = true

  @trimScanKey = (scanKey) ->
    return scanKey.replace(/^\s+|\s+$/g,'')
