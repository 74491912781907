window.App ||= {}
class App.FuelEntries extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    Utility.Datatables.fuelAttachmentsViewOnly()
    App.Attachments.attachmentUpload()
    this.prepareFuelEntryForm()
    this.prepareEquipmentIdSelect()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    this.prepareFuelEntryForm()
    App.CustomFields.showOrHideAddLink()
    Utility.Datatables.fuelAttachments()
    App.Attachments.attachmentUpload()
    Utility.FlyInPanel.prepare()

  prepareEquipmentIdSelect: =>
    $("#fuel_entry_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/fuel_entries/new', {
          equipment_id: $(@).val()
          save_type: $('#save-and-button').attr('name')
        }, null, 'script'
    if parseInt($("#fuel_entry_equipment_id").val()) > 0
      # In case this is rendered in fly-in, give it time to position
      setTimeout (->
        $('#fuel_entry_fueled_on').focus();
      ), 400
      Utility.EquipmentRequired.enable()
    else
      Utility.EquipmentRequired.disable()
      $('#fuel_entry_equipment_id')[0].selectize.focus();

  prepareFuelEntryForm: =>
    Utility.MeterValidation.preparePrimary()
    Utility.MeterValidation.prepareSecondary()
    $('#fuel_entry_fueled_on').on 'change', ->
      fueledOn = $(@).val()
      id = $(@).attr('data-id')
      equipmentId = $(@).attr('data-equipment-id')

      $.ajax
        url: "/equipment/"+equipmentId+"/fuel_entries/get_original_meter?id="+ id + "&fueled_on=" + fueledOn
        data: null
        dataType: 'script'
    $('#fuel_entry_inventory_id').on 'change', ->
      if parseInt($(@).val()) > 0
        $('#no-fuel-tank').hide()
      else
        $('#no-fuel-tank').show()
    if parseInt($('#fuel_entry_inventory_id').val()) > 0
      $('#no-fuel-tank').hide()
