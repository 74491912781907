window.App ||= {}

class App.DatatableCustomizations extends App.Base
  @handleModalClose = ->
    handler = (event) ->
      $popup = $("#popup")
      $popup.hide()

      $reactComponent = $popup.find('[data-react-class]:first')
      if $reactComponent.length > 0
        ReactRailsUJS.unmountComponents($reactComponent.parent()[0])

      $popup.html('')

      if event.detail.performUpdate
        $.ajax
          url: window.location.pathname
          type: 'GET'
          dataType: 'script'

      $(document).off 'customizationModalClosed', handler

    $(document).on 'customizationModalClosed', handler
