window.App ||= {}
class App.FluidConsumptions extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()
    this.prepareFluidEntryForm()
    this.prepareEquipmentIdSelect()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()
    this.prepareFluidEntryForm()

  prepareEquipmentIdSelect: =>
    $("#fluid_consumption_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/fluid_consumptions/new', {
          equipment_id: $(@).val()
          save_type: $('#save-and-button').attr('name')
        }, null, 'script'
    if parseInt($("#fluid_consumption_equipment_id").val()) > 0
      # In case this is rendered in fly-in, give it time to position
      setTimeout (->
        $('#fluid_consumption_filled_on').focus();
      ), 400
      Utility.EquipmentRequired.enable()      
    else
      Utility.EquipmentRequired.disable()
      $('#fluid_consumption_equipment_id')[0].selectize.focus();

  prepareFluidEntryForm: =>
    Utility.MeterValidation.preparePrimary()
    Utility.MeterValidation.prepareSecondary()

    $('#fluid_consumption_inventory_id').on 'change', ->
      if parseInt($(@).val()) > 0
        id = $(@).val()
        $('#no-inventory').hide()
        $.ajax
          url: "/fluid_consumptions/get_part_unit_type?part_id="+id
          data: null
          dataType: 'script'
      else
        $('#no-inventory').show()
    if parseInt($('#fluid_consumption_inventory_id').val()) > 0
      $('#no-inventory').hide()
