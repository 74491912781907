window.App ||= {}
class App.TemplateEquipmentRemovals extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareTable()
    Utility.FlyInPanel.prepare()

  prepareTable: =>
    Utility.Datatables.templateEquipmentRemovals()
    Utility.FormActions.prepareIndexTable()
    $("#check_all_removals_checkbox").click ->
      $("input[name='template_equipment_removal[equipment_ids][]']").prop('checked', $(@).prop('checked'))
