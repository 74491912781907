window.Utility ||= {}

class Utility.Toggle
  @prepare = ->
    $('.toggle-allow-login, .toggle-push-notifications, .toggle-email-notifications, .toggle-assigned-work-orders-only, .toggle-assigned-inspections-only, .toggle-assigned-equipment-only,.toggle-tech').toggles(
      text: {
        on: 'Yes',
        off: 'No'
      },
      width: 90,
      height: 25
    )
    $('.toggle-save-filter').toggles(
      text: {
        on: 'Yes',
        off: 'No'
      },
      width: 55,
      height: 20
    )
    $('.toggle-fleet-mode').toggles(
      text: {
        on: 'On',
        off: 'Off'
      },
      width: 90,
      height: 25
    )
    $('.toggle-due-every-due-on').toggles(
      type: 'select',
      text: {
        on: 'Every',
        off: 'On'
      },
      width: 100,
      height: 25
    )
    $('.toggle-due-every-due-at').toggles(
      type: 'select',
      text: {
        on: 'Every',
        off: 'At'
      },
      width: 100,
      height: 25
    )
    $('.toggle-report-format').toggles(
      type: 'select',
      text: {
        on: 'CSV',
        off: 'PDF'
      },
      width: 100,
      height: 25
    )
