window.App ||= {}
class App.Sessions extends App.Base

  new: =>
    $('#session_email').focus()
    $('#show-password').on 'click', ->
      if $('#session_password').attr('type') == 'text'
        $('#session_password').attr('type','password');
      else
        $('#session_password').attr('type','text');
