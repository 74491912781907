window.Utility ||= {}

class Utility.TrixUploads
  @prepare = ->
    createStorageKey = undefined
    uploadAttachment = undefined

    Trix.config.attachments.preview.caption =
      name: false
      size: false

    onTrixAttachmentAdd = (event) ->
      attachment = event.attachment
      if attachment.file
        $("input[type='submit']").prop('disabled', true)
        return uploadNoteAttachment(attachment, attachment.file.type != "application/pdf")

    onTrixAttachmentRemove = (event) ->
      attachment = event.attachment
      if attachment.attachment.attributes.values.attachmentId != undefined
        deleteNoteAttachment attachment.attachment.attributes.values.attachmentId

    removeEventListener 'trix-attachment-remove', onTrixAttachmentRemove
    removeEventListener 'trix-attachment-add', onTrixAttachmentAdd
    addEventListener 'trix-attachment-remove', onTrixAttachmentRemove
    addEventListener 'trix-attachment-add', onTrixAttachmentAdd

    imageExists = (url, callback) ->
      img = new Image
      img.onload = ->
        callback true
        return
      img.onerror = ->
        callback false
        return
      img.src = url
      return

    deleteNoteAttachment = (id) ->
      $.ajax
        type: 'DELETE'
        url: '/note_attachments/' + id
        cache: false
        contentType: false
        processData: false
        error: (response, status) ->
          console.log response
          alert("Attachment not found.")

    createStorageKey = (file) ->
      date = undefined
      day = undefined
      time = undefined
      date = new Date
      day = date.toISOString().slice(0, 10)
      time = date.getTime()
      'tmp/' + day + '/' + time + '-' + file.name

    uploadNoteAttachment = (attachment, isImage) ->
      file = undefined
      form = undefined
      key = undefined
      xhr = undefined

      file = attachment.file
      key = createStorageKey(file)

      $.ajax
        dataType: 'json'
        url: "/note_attachments/new/"
        cache: false
        success: (result) ->
          form = new FormData
          form.append 'key', result.key
          form.append 'acl', result.acl
          form.append 'success_action_status', result.success_action_status
          form.append 'policy', result.policy
          form.append 'x-amz-algorithm', result['x-amz-algorithm']
          form.append 'x-amz-credential', result['x-amz-credential']
          form.append 'x-amz-date', result['x-amz-date']
          form.append 'x-amz-signature', result['x-amz-signature']
          form.append 'file', file

          xhr = new XMLHttpRequest
          xhr.open 'POST', result.url, true

          xhr.upload.onprogress = (event) ->
            progress = undefined
            progress = event.loaded / event.total * 100
            attachment.setUploadProgress progress

          xhr.onload = ->
            if xhr.status == 201
              response = $(xhr.responseText)
              # console.log response
              location = response.find('Location').text()
              key = response.find('Key').text()

              url = href = location
              $("input[type='submit']").prop('disabled', false)

              created = $.ajax
                url: "/note_attachments/"
                type: 'POST'
                data: {picture_key: key}
                cache: false
                dataType: 'json'

              created.done (response) ->
                console.log response
                attachment_id = response.id
                if isImage
                  attachment.setAttributes
                    url: url
                    attachmentId: attachment_id
                else
                  attachment.setAttributes
                    url: url
                    href: href
                    attachmentId: attachment_id
            return

          xhr.send form

        error: (response, status) ->
          alert("Unable to upload.")
