window.App ||= {}
class App.AdminOrganizationSubscriptions extends App.Base

  edit: =>
    Utility.FormActions.newEditForm()
    $('#organization_subscription_subscription_id').change ->
      App.AdminOrganizationSubscriptions.calculatePrice()
    $('#organization_subscription_users').keyup ->
      App.AdminOrganizationSubscriptions.calculatePrice()

  @calculatePrice: =>
    unitPrice = parseFloat($('#subscription-price-' + $('#organization_subscription_subscription_id').val()).attr('data-price'))
    quantity = parseInt($('#organization_subscription_users').val())
    calculatedTotalCost = unitPrice * quantity
    $('#organization_subscription_total_price').val(parseFloat(Math.round(calculatedTotalCost * 100) / 100).toFixed(2))
