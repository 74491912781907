window.App ||= {}
class App.Logos extends App.Base

  index: =>
    App.Logos.fileUpload()

  @fileUpload = ->
    $('#logo_upload').fileupload
      uploadTemplateId: 'logo-template-upload',
      autoUpload: true,
      maxFileSize: 10000000,
      minFileSize: 1,
      acceptFileTypes:  /(\.|\/)(jpe?g|png)$/i,
      dataType: 'xml',
      disableImageResize: false,
      imageMaxWidth: 1200,
      imageMaxHeight: 1200,
      submit: (e, data) ->
        Utility.Upload.sanitize_file_names(data.files)
        $.ajax
          dataType: 'json'
          url: "/" + $(@).data('object-type') + "/" +  $(@).data('object-id') + "/logos/new/"
          cache: false
          success: (result) ->
            data.formData = result
            data.jqXHR = $('#logo_upload').fileupload('send', data)
          error: (response, status) ->
            alert("Unable to upload.")
        return false
      done: (event, data) ->
        response = $(data.jqXHR.responseText)
        key = response.find('Key').text()
        data.context.attr('data-key', key)
        $.ajax
          url: "/" + $(@).data('object-type') + "/" +  $(@).data('object-id') + "/logos"
          type: 'POST'
          data: {logo_key: key}
          cache: false
          dataType: 'script'
