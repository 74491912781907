window.Utility ||= {}

class Utility.ReactEvents
  ###
  Utility for situations in which we need to allow a Rails JS template to communicate with
  React components. Used in conjunction with the custom hook, useRailsEvent, the data
  becomes available within React.

  The default event name is 'RailsEvent', but custom names may be used to handle multiple events.

  Example Rails side:
  dataToSend = { fieldOne: 'Field One', fieldTwo: 'Field Two' }
  Utility.ReactEvents.trigger(dataToSend, 'CustomEventName')

  Example React side:
  const handleRailsEvent = (eventData) => {
    console.log('Received Rails data', eventData);
  };

  useRailsEvent(handleRailsEvent, 'CustomEventName');
  ###
  @trigger = (eventData = {}, eventName = 'RailsEvent') ->
    event = new CustomEvent(eventName, detail: eventData)
    document.dispatchEvent(event)

