window.Utility ||= {}

class Utility.MainMenu

  @setMenu = (path) ->
    root = path.split("/")[1]

    if root == "admin"
      root = "admin/" + path.split("/")[2]
    else if root == ""
      root = "dashboard"

    unless Utility.FlyInPanel.flyInActive
      $("#main-menu").find("li").removeClass('active');
      if $("#main-menu").find("li[data-path='" + root + "']").length > 0
        $("#main-menu").find("li[data-path='" + root + "']").addClass('active');

