window.Utility ||= {}

class Utility.Drawer
  ACTIVE_CLASS = 'is-active'

  @prepare = ->
    $(document).on 'click', "[data-drawer='toggle']", (event) ->
      $(@).toggleClass(ACTIVE_CLASS)
      _drawerContent(@).find("[data-drawer='slider']").slideToggle('fast')
      event.stopPropagation()
      event.preventDefault()

    $(document).on 'click', ".sidebar-title.toggle[data-drawer='top']", ->
      $(@).find("[data-drawer='toggle']").toggleClass(ACTIVE_CLASS)
      $(@).next("[data-drawer='content']").find("[data-drawer='slider']").slideToggle('fast')

  @toggleDrawers = ->
    $("[data-drawer='slider']").each ->
      drawer = $(@)
      if hasActiveLinks(drawer)
        drawer.show()

    $("[data-drawer='toggle']").each ->
      toggle = $(@)
      if toggle.hasClass(ACTIVE_CLASS)
        _drawerContent(@).find("[data-drawer='slider']").show()


  _drawerContent = (element) ->
    $(element).closest("[data-drawer='top']").next("[data-drawer='content']")

  activeLinks = (element) ->
    element.find("a.is-selected")

  hasActiveLinks = (element) ->
   activeLinks(element).length > 0
