window.App ||= {}
class App.LimitedDashboard extends App.Base

  index: =>
    window.scanContext = 'Equipment'
    $('.scan-equipment').click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanEquipment()
      else
        ScanInterface.scanEquipment()
