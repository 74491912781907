window.App ||= {}
class App.Calendars extends App.Base

  show: =>
    window.scanContext = 'Equipment'
    Utility.AccordionTab.prepare()
    this.prepareCalendar()
    this.prepare()


  prepareCalendar: =>
    options =
      allDaySlot: false
      eventSources: [
        {
          url: $('.tab-link.is-active').data('source')
        }
      ]
      viewRender: (view, element) ->
        try
          localStorage.setItem('fmp-a-calendar-view', view.name)
          localStorage.setItem('fmp-a-calendar-start-date', view.intervalStart.format('YYYY-MM-DD'))
          localStorage.setItem('fmp-a-calendar-end-date', view.intervalEnd.format('YYYY-MM-DD'))
        catch error
      eventRender: (event, element) ->
        if event.html_text
          element.html(event.html_text)

    initializeCalendar = ->
      if $('#calendar').length == 0
        $(window).off('resize', initializeCalendar)
        console.log "INITCAL DISABLED"
        return

      console.log "IN INITCAL"

      if $('#calendar').data('today') == true
        defaultDate = moment().format('YYYY-MM-DD')
      else if moment(localStorage.getItem('fmp-a-calendar-start-date'), 'YYYY-MM-DD').isValid()
        defaultDate = localStorage.getItem('fmp-a-calendar-start-date')
      else
        defaultDate = moment().format('YYYY-MM-DD')

      previousCenter = options.header && options.header.center
      if $(window).width() <= 640
        options.header =
          left: 'title'
          center: ''
          right: 'today prev,next'
        options.defaultDate = defaultDate
        options.defaultView = 'listWeek'
      else
        options.header =
          left: 'basicWeek,listWeek,month'
          center: 'title'
          right: 'today prev,next prevYear,nextYear'
        options.defaultDate = defaultDate
        if localStorage.getItem('fmp-a-calendar-view') in ['basicWeek', 'listWeek', 'month']
          options.defaultView = localStorage.getItem('fmp-a-calendar-view')
        else
          options.defaultView = 'month'

      if previousCenter != options.header.center
        $('#calendar').fullCalendar('destroy')
        $('#calendar').fullCalendar(options)
        console.log 'changed'

    $(window).off('resize', initializeCalendar).resize(initializeCalendar)
    initializeCalendar()

  prepare: =>
    $('.tabs').on "click", "> li > a", (event) ->
      $.ajax
        url: $('.tab-link.is-active').data('source')
        type: 'GET'
        data: null
        dataType: "script"

    $('a#print-calendar').on "click", (event) ->
      window.open("/calendars.pdf?calendar=" + $('.tab-link.is-active').data('type') + '&start=' + localStorage.getItem('fmp-a-calendar-start-date') + '&end=' + localStorage.getItem('fmp-a-calendar-end-date'), '_blank')
