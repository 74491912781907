window.App ||= {}
class App.GpsIntegrations extends App.Base

  index: =>
    Utility.Datatables.gpsIntegrations()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    this.toggleProviderControls()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    this.toggleProviderControls()
    Utility.FlyInPanel.prepare()

  toggleProviderControls: =>
    $('.provider-specific').hide()
    if $("#gps_integration_provider_name").val().length > 0
      $('.provider-specific.' + $("#gps_integration_provider_name").val()).show()
      $('.provider-excluded.' + $("#gps_integration_provider_name").val()).hide()

    $("#gps_integration_provider_name").on 'change', ->
      $('.provider-specific').hide()
      $('.provider-excluded').show()
      $('.provider-specific.' + $("#gps_integration_provider_name").val()).show()
      $('.provider-excluded.' + $("#gps_integration_provider_name").val()).hide()
