window.Utility ||= {}

class Utility.TrixCustomizations
  TRIX_CONTROLS = "trix-toolbar, .trix-actions"

  @prepare: ($trix) ->
    $trix.addClass('needsclick')
    $trix.siblings('trix-toolbar').find('button').addClass('needsclick')
    if $trix.hasClass('hide-controls')
      $trix.siblings(TRIX_CONTROLS).hide()
      $trix.on 'trix-focus', ->
        $(@).siblings(TRIX_CONTROLS).fadeIn()
        $("trix-toolbar").not( "[id='trix-toolbar-" + $trix.attr("trix-id") + "']" ).fadeOut()
