window.App ||= {}
class App.Customers extends App.Base
  index: =>
    window.scanContext = 'Ignore'
    Utility.Datatables.customers()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.FormActions.newEditForm()
    this.prepareShipping()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    this.prepareShipping()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'Ignore'
    Utility.AccordionTab.prepare()
    $("html, body").scrollTop(0)

  prepareShipping: =>
    $('#same-address').on "click", ->
      if $(@).prop('checked')
        $('#customer_shipping_address').val($('#customer_address').val())
        $('#customer_shipping_address2').val($('#customer_address2').val())
        $('#customer_shipping_city').val($('#customer_city').val())
        $('#customer_shipping_postal_code').val($('#customer_postal_code').val())
        $('#customer_shipping_state_prov').val($('#customer_state_prov').val())
        $('#customer_shipping_country')[0].selectize.setValue($('#customer_country').val())
