window.App ||= {}
class App.Downloads extends App.Base

  show: =>
    App.Downloads.refreshIfProcessing()

  @refreshIfProcessing = ->
    if $('.progress-indicator').length > 0
      setTimeout (->
        $.getScript('/download')
        return
      ), 5000
