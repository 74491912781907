import ClipboardJS from "clipboard"

window.Utility ||= {}

class Utility.Clipboards
  @prepare: =>
    clipboard = new ClipboardJS('.clipboard-link')
    clipboard.on 'success', (e) ->
      $.jGrowl('Data copied to clipboard!', {position: 'center', theme: 'success'})

