window.App ||= {}
class App.CustomWorkOrderStatuses extends App.Base

  new: =>
    Utility.FormActions.newEditForm()
    App.CustomWorkOrderStatuses.initializeSpectrum()

  edit: =>
    Utility.FormActions.newEditForm()
    App.CustomWorkOrderStatuses.initializeSpectrum()

  @initializeSpectrum: =>
    $('#custom_work_order_status_color').spectrum
      preferredFormat: "hex"
      showPaletteOnly: true
      togglePaletteOnly: true
      togglePaletteMoreText: 'more'
      togglePaletteLessText: 'less'
      palette: [
          ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
          ["#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#9fc5e8","#b4a7d6","#d5a6bd"],
          ["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
          ["#c00","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"],
          ["#900","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
          ["#600","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]
      ]

  @prepareSortable = ->
    if $("#custom_work_order_statuses-table > tbody").length > 0
      el = $("#custom_work_order_statuses-table > tbody")[0]
      sortable = Sortable.create(el,
        {
          handle: ".handle"
          scroll: true
          onEnd: (e) ->
            if e.oldIndex != e.newIndex
              console.log e
              $.ajax
                url: "/organizations/" + $("#custom_work_order_statuses-table").data("organization-id") + "/reorder_custom_work_order_status"
                type: 'put'
                data: {type: $(e.item).data('type'), old_index: e.oldIndex, new_index: e.newIndex}
                cache: false
                dataType: 'script'
        }
      )
