window.App ||= {}
class App.MeterReplacements extends App.Base

  index: =>
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.meterReplacements()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.FormActions.prepareIndexTable()

  new: =>
    Utility.Parsley.prepare()
    Utility.SelectizeFields.prepare()
    Utility.Datepicker.prepare()
    this.prepareEquipmentIdSelect()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.FormActions.newEditForm()
    Utility.FlyInPanel.prepare()

  prepareEquipmentIdSelect: =>
    $("#meter_replacement_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/meter_replacements/new', {
          equipment_id: $(@).val()
          save_type: $('#save-and-button').attr('name')
        }, null, 'script'
    if parseInt($("#meter_replacement_equipment_id").val()) > 0
      # In case this is rendered in fly-in, give it time to position
      setTimeout (->
        $('#meter_replacement_replaced_on')[0].focus()
      ), 400
    else
      $('#meter_replacement_equipment_id')[0].selectize.focus()
