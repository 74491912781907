window.Utility ||= {}

class Utility.FormActions

  @newEditForm = ->
    Utility.Datepicker.prepare()
    Utility.SelectizeFields.prepare()
    Utility.Parsley.prepare()
    Utility.HelpPanel.prepare()
    $('input[autofocus]').focus()
    # ANDROID APP ONLY
    if (typeof window.JsInterface != "undefined" && typeof JsInterface.disableSwipeRefresh == "function")
      JsInterface.disableSwipeRefresh()

  @prepareCreditQuantityEditor = ->
    $(document).off('keyup', ".enforce-positive").on 'keyup', ".enforce-positive", ->
      if parseInt($(@).val()) < 0
        $(@).val($(@).val() * -1)

    $(document).off('keyup', ".enforce-negative").on 'keyup', ".enforce-negative", ->
      if parseInt($(@).val()) > 0
        $(@).val($(@).val() * -1)

  @removeParentByClass = ->
    $(document).off('click', "a[data-click='remove-parent-by-class']").on 'click', "a[data-click='remove-parent-by-class']", ->
      $(@).parent("." + $(@).data('remove-class')).remove()

  @prepareLocationCategoryAjaxLinks: =>
    $('.location-link').click ->
      $.ajax
        url: "/locations/"+$(@).attr('data-id')+"/edit"
        data: null
        dataType: "script"

    $('.category-link').click ->
      $.ajax
        url: "/categories/"+$(@).attr('data-id')+"/edit"
        data: null
        dataType: "script"

  @prepareIndexTable: (agGrid = false) =>
    # ANDROID APP ONLY
    if (typeof window.JsInterface != "undefined" && typeof JsInterface.enableSwipeRefresh == "function")
      JsInterface.enableSwipeRefresh()

    DUE_INDICATOR_SELECTOR = '.due-indicator'
    ACTIVE_CLASS = 'is-active'

    $("#popup").html('').hide() unless Utility.FlyInPanel.flyInActive
    $('.im-index-table-container, .im-general-container').addClass('tw-opacity-100')

    searchEvent = "keyup"
    init = $('table.dataTable').DataTable().init()

    if init != null || agGrid
      searchEvent = "change"

    if agGrid
      table_id = $('input.text_search').data('table-id')
      rs = $('input.remember_search[data-table-id="' + table_id + '"]')

      if rs
        if localStorage[table_id + '-remember_search'] == 'true'
          rs.prop('checked', true)
          rs.siblings('i.fa-lock').show()
          rs.siblings('i.fa-unlock').hide()
          rs.parent().siblings('#clear-search').show()
          if localStorage[table_id+ '-search'] != ""
            $('input.text_search[data-table-id="'+table_id+'"]').val(localStorage[table_id + '-search'])
        else
          rs.siblings('i.fa-lock').hide()
          rs.siblings('i.fa-unlock').hide()
          rs.parent().siblings('#clear-search').hide()

    showRemember = (inputElem) =>
      rs = $('input.remember_search[data-table-id="' + inputElem.data('table-id') + '"]')

      if inputElem.val().length > 0
        if rs.siblings('i.fa-lock').is(":hidden")
          rs.siblings('i.fa-unlock').show()
        rs.parent().siblings('#clear-search').show()

    handleClear = (inputElem) =>
      id = inputElem.attr('id')
      rs = $('input.remember_search[data-table-id="' + inputElem.closest('.text_search').data('table-id') + '"]')

      handleSearch(inputElem)
      rs.siblings('i.fa-lock').prop( "checked", false );
      rs.siblings('i.fa-unlock').prop( "checked", true );
      rs.prop( "checked", false );
      rs.siblings('i.fa-lock').hide()
      rs.siblings('i.fa-unlock').hide()

      rs.parent().siblings('#clear-search').hide()
      localStorage[inputElem.closest('.text_search').data('table-id') + '-remember_search'] = false
      inputElem.blur()
      setTimeout (->
        $('#' + id).focus()
      ), 100

    handleSearch = (inputElem) =>
      if $('#new-meter-update-table td input.changed').length > 0
        form = $("form#meter-update-form")
      else if $('#reconciliation-table td input.changed').length > 0
        form = $("form#reconciliation-form")

      if form
        $(".text_search").addClass('search-pending')
        form.submit()
      else
        if agGrid
          payload = { searchText: inputElem.val() }
          Utility.ReactEvents.trigger(payload, 'AgGridSearch')
        else
          $('#' + inputElem.data('table-id')).DataTable().search(inputElem.val()).draw()

      localStorage[inputElem.data('table-id') + '-search'] = inputElem.val()

      if $('.report-table').length > 0 && $('.chart').data('chart-data')
        $.ajax
          url: '/reports/refresh_chart/' + $('.report-table').data('report-name') + '?search=' + inputElem.val()
          data: null
          dataType: 'script'

    $('.text_search').off(searchEvent).on searchEvent, ->
      handleSearch($(@))
      showRemember($(@))

    if searchEvent != 'keyup'
      $('.text_search').on 'keyup', ->
        showRemember($(@))

    # Auto submits the search if the user clears the box via either the escape key or in-box X icon
    $('.auto-submit-on-clear').off('input').on 'input', ->
      if !$(this).val()
        handleClear($(this))

    $('a#clear-search').off('click').on 'click', (event) ->
      event.preventDefault()
      elem = $(this).parent().prev()
      elem.val('')
      handleClear(elem)

    $('.remember_search').off('change').on 'change', ->
      localStorage[$(@).data('table-id') + '-remember_search'] = $(@).is(':checked')
      $(@).siblings('.fa-unlock').toggle()
      $(@).siblings('.fa-lock').toggle()

    $('table.dataTable').off('draw.dt').on 'draw.dt', ->
      tableId = $(@).attr('id')
      dtInstance = $("##{tableId}").DataTable()
      currentOrder = dtInstance.order();

      $('.DataTables_sort_icon').removeClass('ui-icon ui-icon-caret-2-n-s ui-icon-triangle-1-s ui-icon-triangle-1-n')
      $('th.sorting').removeClass('sorting_asc sorting_desc')

      if currentOrder.length
        sortingColumn = currentOrder[0][0]
        sortingDirection = currentOrder[0][1]

        header = $(dtInstance.column(sortingColumn).header())
        sortIcon = $(header.find('.DataTables_sort_icon'))
        if sortingDirection == 'asc'
          sortIcon.addClass('ui-icon ui-icon-triangle-1-n')
          header.addClass('sorting_asc')
        else
          sortIcon.addClass('ui-icon ui-icon-triangle-1-s')
          header.addClass('sorting_desc')

    $('table.dataTable.display-start').off('page.dt').on 'page.dt', ->
      localStorage[$(@).attr('id') + '-display-start'] = $(@).DataTable().page() * $(@).DataTable().page.len()

    $('table.dataTable').off('order.dt').on 'order.dt', ->
      tableId = $(@).attr('id')
      dtInstance = $("##{tableId}").DataTable()
      currentOrder = dtInstance.order();
      return if localStorage["#{tableId}-order"] == JSON.stringify(currentOrder)

      localStorage["#{tableId}-order"] = JSON.stringify(currentOrder)
      localStorage.removeItem("#{tableId}-display-start")

    selectedIds = ->
      $("table.dataTable").DataTable().column(0, {search: 'applied'}).data().join()

    checkedIds = ->
      $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()

    loadServerSideDownloadForm = (form) ->
      checked_ids = checkedIds()
      form.find("#draw").val(1)
      form.find("#search").val($('table.dataTable').DataTable().search())
      form.find("#order").val($('table.dataTable').DataTable().order())
      if checked_ids.length > 0
        form.find("#ids").val(checked_ids)
      else
        form.find("#ids").val(null)

    loadClientSideDownloadForm = (form) ->
      checked_ids = checkedIds()
      form.find("#draw").val(0)

      if checked_ids.length > 0
        form.find("#ids").val(checked_ids)
      else
        form.find("#ids").val(selectedIds())

    submitDownloadForm = (form) ->
      if agGrid
        payload = { form }
        # Trigger an event to get the download form info from AgGrid
        # That event will then trigger the AgGridDownloadInfoReady event with the info
        Utility.ReactEvents.trigger(payload, 'AgGridRequestDownloadInfo')
      else if $('table.dataTable').length > 0
        if $('table.dataTable').DataTable().init().serverSide
          loadServerSideDownloadForm(form)
        else
          loadClientSideDownloadForm(form)
        form.submit()

    # Event to handle download info requests from AgGrid-powered index tables
    $(document).off('AgGridDownloadInfoReady').on 'AgGridDownloadInfoReady', (event) ->
      { detail } = event
      { form, selectedIds, order, search, draw, filters } = detail

      form.find("#draw").val(draw)
      form.find("#ids").val(selectedIds) if selectedIds
      form.find("#order").val(order) if order
      form.find("#search").val(search) if search
      form.find("#filters").val(filters) if filters

      form.submit()

    $("a.download-csv").click ->
      form = $("form#download-csv[data-submenu-id='" + $(@).attr('data-submenu-id') + "']")
      submitDownloadForm(form)

    $("a.export-csv").click ->
      form = $("form#export-csv[data-submenu-id='" + $(@).attr('data-submenu-id') + "']")
      submitDownloadForm(form)

    $("a.download-pdf").click ->
      form = $("form#download-pdf[data-submenu-id='" + $(@).attr('data-submenu-id') + "']")
      submitDownloadForm(form)

    $('.dataTable.row-links').off('click', 'td').on 'click', 'td', (event) ->
      if !$(event.target).hasClass('inner-link') && ($(event.target).attr('data-method') != 'delete')
        return if $(@).closest('tr').data('url') == null || $(@).closest('tr').data('url').length == 0

        if $(@).closest('tr').data('remote') == true
          if $(@).closest('tr').data('show-fly-in') == true
            Utility.FlyInPanel.show(window.location.href)
          $.get $(@).closest('tr').data('url'), null, null, 'script'
        else if $(@).closest('tr').data('url') != undefined
          url = $(@).closest('tr').data('url')
          window.location = url

    $('.dataTable').off('click', 'td.link').on 'click', 'td.link', (event) ->
      return if $(event.target).closest('.delete-attachment').length > 0

      if !$(event.target).hasClass('inner-link') && ($(event.target).attr('data-method') != 'delete')
        return if $(@).closest('tr').data('url') == null || $(@).closest('tr').data('url').length == 0

        if $(@).closest('tr').data('remote') == true
          if $(@).closest('tr').data('show-fly-in') == true
            Utility.FlyInPanel.show(window.location.href)
          $.get $(@).closest('tr').data('url'), null, null, 'script'
        else
          url = $(@).closest('tr').data('url')
          window.location = url

    $('.dataTable').off('click', 'td.details-control, span.details-control, span.show-details').on 'click', 'td.details-control, span.details-control, span.show-details', (event) ->
      event.preventDefault()
      tr = $(@).closest('tr')
      Utility.BulkOperations.cancel()
      row = $('.dataTable').DataTable().row(tr)

      if row.child.isShown()
        row.child.hide()
        tr.find(DUE_INDICATOR_SELECTOR).removeClass(ACTIVE_CLASS)
      else
        $.get tr.data('details-url'), (data) ->
          row.child(data, 'drawer-wrapper').show()
          tr.find(DUE_INDICATOR_SELECTOR).addClass(ACTIVE_CLASS)
          if row.child().find(".handle").length > 0
            el = row.child().find("tbody")[0]
            sortable = Sortable.create(el,
              {
                handle: ".handle"
                scroll: true
                onEnd: (e) ->
                  if e.oldIndex != e.newIndex
                    $.ajax
                      url: $(e.item).data('url') + '_order'
                      type: 'patch'
                      data: {type: $(e.item).data('type'), old_index: e.oldIndex, new_index: e.newIndex}
                      cache: false
                      dataType: 'script'
              }
            )
        , 'html'
